import { Link } from "react-router-dom";

const SwitchAccountLink = props => {
  const { config, data, openGlobalPopup, linkProps } = props;
  const { secondary, label } = linkProps;

  const openPopup = (e) => {
    e.preventDefault();
    openGlobalPopup('SwitchAccount')
  }

  if(!data?.user?.groupData?.Contacts__r?.records?.length){
    return null;
  }

  return <Link
    className={'label ' + (secondary ? 'secondary' : '')}
    to={'/'}
    onClick={openPopup}
    config={config}
  >
    {label}
  </Link>
}

export default SwitchAccountLink;