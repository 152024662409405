import React from "react";

import CampaignsMyNumbersDetailsModal from "./CampaignsMyNumbersDetailsModal";

const CampaignsMyNumbersDetails = (props) => {
  const { config, setOpenModalDetail, itemModalDetail } = props;

  return (
    <>
      <div className="mainContent" style={{opacity: 0.6}}>
      </div>
      <CampaignsMyNumbersDetailsModal config={config} setOpenModalDetail={setOpenModalDetail} itemModalDetail={itemModalDetail} />
    </>
  )
}

export default CampaignsMyNumbersDetails;
