import styled from "@emotion/styled";
import { BREAKPOINTS } from "../../configs/consts";

export const StyledResultItem = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 50px 20px 50px;
  width: 100%;

  @media (min-width: ${BREAKPOINTS.PHONE_SMALL}px) and (max-width: ${BREAKPOINTS.PHONE_BIGGER}px) {
    width: 100%;
  }

  .title {
    color: #444444;
    font-weight: 700;
    font-size: 14px;
    padding-bottom: 7px;
    border-bottom: solid thin #DCDCDC;
  }

  .mainRow {
    display: flex;
    flex-direction: row;
    border-bottom: solid thin #DCDCDC;
    padding-bottom: 7px;

    @media (min-width: ${BREAKPOINTS.PHONE_SMALL}px) and (max-width: ${BREAKPOINTS.PHONE_BIGGER}px) {
      flex-direction: column;
      margin-bottom: 25px;
    }

    .img {
      max-width:114px;
      min-width:114px;
      max-height:85px;
      min-height:85px;
      padding-top: 10px;
    }

    .secondColumn {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 10px 0px 0px 20px;

      @media (min-width: ${BREAKPOINTS.PHONE_SMALL}px) and (max-width: ${BREAKPOINTS.PHONE_BIGGER}px) {
        padding: 10px 0px 0px 0px;
      }
      
      .firstRow {
        display: flex;
        flex-direction: column;
        border-bottom: solid thin #DCDCDC;
      }

      .infoTitle {
        color: #444444;
        font-weight: 500;
        font-size: 12px;
      }

      .infoName {
        color: #444444;
        font-weight: 700;
        font-size: 14px;
        line-height: 22px;
      }

      .infoOtherData {
        color: #444444;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
      }

      .lastRow {
        display: flex;
        flex-direction: row;
        padding-top: 8px;

        @media (min-width: ${BREAKPOINTS.PHONE_SMALL}px) and (max-width: ${BREAKPOINTS.PHONE_BIGGER}px) {
          flex-direction: column;
        }

        div {
          display: flex;
          flex-direction: column;
          padding-top: 10px;
          padding-right: 10px;
        }
      }
    }
  }
`

export const StyledBtnsContainer = styled.div`
  display: flex; 
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  .btn {
    width: 220px;
    max-width: 220px;
    font-weight: bold;
    font-size: 14px;
    align-items: center;
    height: 40px;
  }

  .btnDisabled {
    width: 280px;
    max-width: 280px;
    font-weight: bold;
    font-size: 16px;
    align-items: center;
    opacity: 0.2;
    pointer-events: none;
  }
`