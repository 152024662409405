import React from "react";

import SideTitle from "../Atoms/SideTitle";
import SectionTitle from "../Atoms/SectionTitle";
import ButtonSecondary from '../Atoms/ButtonSecondary';

import {StyledCampaignsHeaderSection} from './styled';

import { Filter } from "react-feather";

const CampaignsHeaderSection = (props) => {
  const { config, openFilter, setOpenFilter, currentCategory, changeCategory } = props;

  const handleOpenFilter = () => {
    setOpenFilter(true);
  }

  return (
    <StyledCampaignsHeaderSection>
      <SideTitle config={config}>Campanhas</SideTitle>
      <div className="row-subtitle">
        <SectionTitle config={config}>
          {currentCategory === 'Vigentes' ?
            'Vigentes' :
            <a data-testid='btn-vigentes' href='#' onClick={() => changeCategory("Vigentes")}>Vigentes</a>
          }
          <span className={'sep'}>|</span>
          {currentCategory === 'Encerradas' ?
            'Encerradas' :
            <a data-testid='btn-encerradas' href='#' onClick={() => changeCategory("Encerradas")}>Encerradas</a>
          }
        </SectionTitle>
        <div className='icon'>
          <ButtonSecondary config={config} onClick={handleOpenFilter} className={openFilter ? 'hide' : ''} data-testid='button-filter-campaigns'>
            <span className="button-label">
              Filtrar&nbsp; 
            </span>
            <Filter />  
          </ButtonSecondary>
        </div>
      </div>
    </StyledCampaignsHeaderSection>
  )
}

export default CampaignsHeaderSection;