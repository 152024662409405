import styled from "@emotion/styled";
import SectionLink from "../Atoms/SectionLink";
import { BREAKPOINTS } from "../configs/consts";
import ToolHelper from "../utils/ToolHelper";
import StyledLink from "./StyledLink";

const SectionContainer = (props) => {
  const { config, titleAlign, gutter, link, transparentBg } = props;

  let containerMargin = gutter !== undefined ? gutter : (props.config?.layout?.GUTTER || 20);
  let verticalMargin = props.verticalMargin !== undefined ? props.verticalMargin : containerMargin * 2;
  let containerWidth = props.width || props.config?.layout?.CONTAINER_SIZE;
  let containerHeight = props.height;

  containerMargin = ToolHelper.fixSizeUnit(containerMargin);
  verticalMargin = ToolHelper.fixSizeUnit(verticalMargin);
  containerWidth = ToolHelper.fixSizeUnit(containerWidth);
  containerHeight = ToolHelper.fixSizeUnit(containerHeight);
  
  let additionalProps = {containerMargin,verticalMargin,containerWidth,containerHeight};

  return <StyledSectionContainer transparentBg={transparentBg || false} {...props} {...additionalProps} className={'section'}>
    <div className={'section-container'}>
      {(props.title || link?.display) && <div className={'title-row'}>
        {props.title && <p className={'title ' + titleAlign}>{props.title}</p>}
        <div className={'aside-link'}>
          <SectionLink {...props} config={config} link={link} />
        </div>
      </div>}
      {props.children}

      {link?.display && <div className={'bottom-link'}>
        <SectionLink config={config} link={link} />
      </div>}
    </div>
  </StyledSectionContainer>
}

export default SectionContainer;

const StyledSectionContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: ${props => props.overflow || 'hidden'};
  width: 100%;
  justify-content: center;
  align-items: center;
  background: ${props => !props.transparentBg && props.config?.themeLayout?.background};
  color: ${props => props.config?.themeLayout?.title};
  flex: ${props => props.flex ? 1 : undefined};

  .section-container{
    // flex: 1;
    padding-top: ${props => props.verticalMargin};
    padding-bottom: ${props => props.verticalMargin};
    padding-left: ${props => props.containerMargin};
    padding-right: ${props => props.containerMargin};
    width: 100%;
    max-width: ${props => props.containerWidth};
    height: ${props => props.containerHeight};

    >.title-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 40px;

      @media (max-width: ${BREAKPOINTS.PHONE}px) {
        flex-direction: column !important;
        align-items: start;

        margin-bottom: 20px;
      }

      .title{
        flex: 1;
        font-weight: 900;
        font-size: 1.9em;
        color: ${props => props.config?.themeLayout?.title};

        &:not(.center){
          padding-right: 50px;
        }

        &.center{
          text-align: center;
        }
      }

      .aside-link{
        display: block;

        @media (max-width: ${BREAKPOINTS.TABLET_PORTRAIT}px) {
          display: none;
        }
      }
    }

    .bottom-link{
      display: flex;
      margin-top: 30px;
      /* justify-content: end; */
      div {
        width: 100%;
        a {
          width: 100%;
          display: block;
        }
      }


      @media (min-width: ${BREAKPOINTS.TABLET_PORTRAIT}px) {
        display: none;
      }
    }
  }
`