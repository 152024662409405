import styled from "@emotion/styled";

const TextSimple = (props) => {
  let {config} = props;
  return <StyledTextSimple {...props} config={config}>{props.children}</StyledTextSimple>
}

export default TextSimple;

const StyledTextSimple = styled.span`
  font-size: 1em;
  color: ${props => props.config?.themeLayout?.text}
`