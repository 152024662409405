import styled from '@emotion/styled'

const StyledFormInput = styled.div`
  position: relative;

  .eye {
    position: absolute;
    top: 8px;
    right: 5px;

    border: 0;
    background: none;

    &:hover {
      cursor: pointer;
    }
  }

  &.compressed{
    .eye{
      top: 6px;
    }
  }

  input,textarea,select{
    font-weight:bold;
    font-size:1em;
    padding:0.8em;
    display:block;
    height: ${props => props.height ? props.height : '2.5em'};
    width: ${props => props.size ? props.size : '100%'};
    border:none;
    border: 1px solid ${props => props.config?.themeLayout?.text};
    border-radius: ${props => props.config?.layout?.INPUT_RADIUS};
    background: ${props => props.config?.themeLayout?.background};
    color: ${props => props.config?.themeLayout?.text};
    font-family: ${props => props.config?.layout?.FONT_FAMILY};

    &:focus{
      outline:none;
      border-color: ${props => props.config?.themeLayout?.label};
    }

    &:focus ~ label,
    &:valid ~ label,
    &:disabled ~ label{
      top:-10px;
      font-size:0.9em;
      color: ${props => props.config?.themeLayout?.label};
      padding: 0px 2px;    

      .label-show{
      }
    }

    &:disabled{
      opacity: 0.5;
    }
  }

  textarea{
    height: 8em;
  }

  label{
    color: ${props => props.config?.themeLayout?.subtext};
    font-size:1em;
    font-weight:bold;
    position:absolute;
    pointer-events:none;
    left:0.8em;
    top: ${props => props.top ? props.top : '0.6em'};
    transition:0.2s ease all;
    -moz-transition:0.2s ease all;
    -webkit-transition:0.2s ease all;

    .label-bg{
      position: absolute;
      top: 9px;
      left: -2px;
      width: calc(100% + 4px);
      height: 3px;
      background: ${props => props.config?.themeLayout?.background};
    }

    .label-show{
      position: absolute;
      z-index: 2;
    }

    .label-placeholder{
      opacity: 0;
    }
  }

  p.error{
    position:relative;
    padding-top:2px;
    font-size:0.8em;
    color:red;
  }

  div.append{
    padding-top: 8px;
    text-align: end;
  }

  &.compressed{
    input,textarea,select{
      padding:0.5em;
      font-size:0.9em;
      /* padding-right:2.5em; */

      &:focus ~ label,
      &:valid ~ label,
      &:disabled ~ label{
        top:-8px;
        font-size:0.7em;
      }
    }

    select{
      padding-right:2.5em;
    }

    label{
      top: 0.6em;
      left: 0.55em;
      font-size: 0.9em;

      .label-bg{
        top: 7px;
      }
    }

    p.error{
      position:relative;
      font-size:0.7em;
    }
  }

  &.hidden{
    display:none;
  }
`

export default StyledFormInput;