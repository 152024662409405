import { Route, Redirect } from 'react-router-dom';

function PrivateRoute({ children, routesO, allowedTypes, notAllowedTypes, message, strings, ...rest }) {
  return (
    <Route
      {...rest}
      render={
        ({ location }) => {
          let result;
          // Se for logout path e estiver logado, só aceita
          if(routesO.logout.path === rest.path && global.isAuthenticated){
            result = children;
          }
          // Se precisa validar código de acesso e não está na tela de código de acesso, redireciona
          else if(routesO.accessCode.path !== rest.path && global.needsToValidateAccessCode){
            result = <Redirect
              to={{pathname: routesO.accessCode.path}}
            />
          }
          // Se precisa validar termos e não está na tela de termos, redireciona
          else if(routesO.termsAgreement.path !== rest.path && global.needsToValidateTermsAgreement){
            result = <Redirect
              to={{pathname: routesO.termsAgreement.path}}
            />
          }
          // Se estiver autenticado
          else if(global.isAuthenticated){
            // Se está com flag para redirecionar após login, redireciona e apaga o flag
            if(global.redirectAfterLogin){
              const newLocation = global.redirectAfterLogin;

              result = <Redirect
                to={newLocation}
              />

              delete global.redirectAfterLogin;
            }
            else{
              // Chegando aqui é necessário verificar se a categoria do usuário pode acessar esta página
              if(
                (allowedTypes && (!global.userTypes || !allowedTypes.filter(value => global.userTypes.includes(value)).length)) ||
                (notAllowedTypes && (!global.userTypes || notAllowedTypes.filter(value => global.userTypes.includes(value)).length))
              ){
                message.add({type:'error',title: strings.form.message.NO_PERMISSION_TO_ACCESS_PAGE,persist:true})
                result = <Redirect
                  to={{
                    pathname: routesO.home.path,
                    state: { from: location }
                  }}
                />
              }
              else{
                result = children;
              }
            }
          }
          // Se nenhum dos anteriores aplicou, vá para login
          else{
            global.redirectAfterLogin = location;

            result = <Redirect
              to={{
                pathname: '/login',
                state: { from: location }
              }}
            />
          }

          return result;
        }
      }
    />
  );
}

export default PrivateRoute;