import styled from "@emotion/styled";
import { useState, useEffect, useMemo, useRef } from "react";
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import { BREAKPOINTS } from "../configs/consts";
import ToggleSlider from "../Molecules/ToggleSlider";
import ToolHelper from "../utils/ToolHelper";
import useOutsideAction from "../utils/useOutsideAction";
import CustomLink from "../Molecules/CustomLink";
import CoreLink from "../Molecules/CoreLink";
import { Bell } from "react-feather";
// import Button from '../Atoms/Button'

const HeaderDropMenuItem = (props) => {
  const { config, linkProps, checkboxData, openGlobalPopup, targetPopup, hideOn = [], secondary, rightPanel, dataHelper } = props;
  const { customComponent, label } = linkProps;
  const className = useMemo(() => hideOn.join(" "), [hideOn])

  const openPopup = (e) => {
    e.preventDefault();
    openGlobalPopup(targetPopup)
  }

  const DropMenuLink = () => {
    if (checkboxData) {
      return <>
        <span className={'label'} config={config}>{label}</span>
        <ToggleSlider config={config} isChecked={checkboxData.isChecked} onToggle={checkboxData.onToggle} />
      </>
    }

    if (customComponent) {
      return <CustomLink {...props} componentName={customComponent} />
    }

    if (targetPopup) {
      return <Link
        className={'label ' + (secondary ? 'secondary' : '')}
        to={'/'}
        onClick={openPopup}
        config={config}
      >
        {label}
      </Link>
    }

    return <CoreLink
      {...linkProps}
      domain={config?.firebaseConfig?.authDomain}
      rightPanel={rightPanel}
      config={config}
      data={props.data}
      dataHelper={dataHelper}
      message={props.message}
      className={'label ' + (secondary ? 'secondary' : '')}
    />

  }

  return <StyledHeaderDropMenuItem className={className} config={config} data={props.data}>
    <DropMenuLink />
  </StyledHeaderDropMenuItem>;
}

const HeaderDropMenu = (props) => {
  const { parentRef, userHelper, notification, isOpen, toggleIsOpen, menuItems, mobileItems, openGlobalPopup, data, isFarmer, openNotifications, rightPanel, dataHelper, message, openCampaigns } = props;
  const [tokenFound, setTokenFound] = useState(false);
  const wrapperRef = useRef(null);

  const IS_GROWER =
    data?.user?.acg_GrowerAccount__r?.RecordType?.Name === "Grower"
    || !(data?.user?.acg_GrowerAccount__r?.RecordType?.Name);

  const closeMenu = () => {
    toggleIsOpen(false);
  }

  useOutsideAction(wrapperRef, closeMenu, parentRef)

  let config = useMemo(() => ToolHelper.getThemedConfig(props, 'HeaderDropMenu.preset'), [props]);

  const getToken = () => {
    setTokenFound(true);
    userHelper.getMessagingToken((result) => {
      if (result.err) {
        notification.add({
          title: config?.strings?.components?.notification?.NOTIFICATIONS_DENIED_TITLE,
          body: config?.strings?.components?.notification?.NOTIFICATIONS_DENIED_MESSAGE
        })
        setTokenFound(false);
      }
      else {
        setTokenFound(true);
      }
    })
  }

  const toggleNotification = (isChecked) => {
    if (isChecked) {
      getToken();
    }
  }

  return <StyledHeaderDropMenu config={config} isOpen={isOpen} ref={wrapperRef}>
    <ul>
      <div className={'main-items'}>
        {/* <HeaderDropMenuItem label={config?.strings?.components?.notification?.TITLE} config={config} checkboxData={{isChecked: tokenFound, onToggle: toggleNotification}}/> */}
        {menuItems?.map((menuItem) => {

          if (!IS_GROWER && menuItem.label === "Grupo de Pontos") {
            return;
          }

          if (menuItem.label === "Campanhas") {
            return (
              <StyledHeaderDropMenuItem onClick={openCampaigns} key={menuItem.label}>
                <a className="label">{menuItem.label}</a>
              </StyledHeaderDropMenuItem>
            )
          }

          return <HeaderDropMenuItem linkProps={menuItem} key={menuItem.label} config={config} data={data} openGlobalPopup={openGlobalPopup} rightPanel={rightPanel} dataHelper={dataHelper} message={message} />
        })}
        {isFarmer && (
          <Bell
            width={15}
            height={15}
            color={config.themeLayout.buttonBackground}
            fill={config.themeLayout.buttonBackground}
            style={{ marginTop: 10 }}
            onClick={openNotifications}
          />
        )}
      </div>
      <div className={'mobile-items'}>
        {mobileItems?.map(menuItem =>
          <HeaderDropMenuItem linkProps={menuItem} key={menuItem.label} config={config} data={data} openGlobalPopup={openGlobalPopup} rightPanel={rightPanel} message={message} dataHelper={dataHelper} />
        )}
      </div>
    </ul>
  </StyledHeaderDropMenu>
}

export default HeaderDropMenu;

/* style */

const StyledHeaderDropMenu = styled.div`
  position: absolute;
  // background: ${props => props.config?.themeLayout?.background || 'transparent'};
  background: #FFFFFF;
  right: 0;
  padding: 20px;
  // border-bottom-left-radius: ${props => (props.config?.layout?.BOX_BORDER_BOTTOM_LEFT_RADIUS || 0) * 2}px;
  // border-bottom-right-radius: ${props => (props.config?.layout?.BOX_BORDER_BOTTOM_RIGHT_RADIUS || 0) * 2}px;
  // border-bottom: 4px solid rgba(0,0,0,0.2);
  border: solid thin #707070;
  border-radius: 24px;
  z-index: 9999;
  overflow: hidden;
  transition: transform 200ms linear;
  transform-origin: top;
  transform: scaleY(${props => props.isOpen ? 1 : 0});

  @media (max-width: ${BREAKPOINTS.PHONE_BIGGER}px) {
    // width: 100%;
    left: 0;
    margin: 10px;
  }

  ul{
    display: flex;

    .mobile-items{
      display: none;

      @media (max-width: ${BREAKPOINTS.PHONE}px){
        display: block;
      }
    }
  }
`;

const StyledHeaderDropMenuItem = styled.li`
  width: 200px;
  padding-right: 20px;

  .label{
    display: inline-block;
    // padding: 0.7em 0;
    // font-size: 0.8em;
    // font-weight: bold;
    font-size: 16px;
    font-weight: 700;
    line-height: 35px;
    text-transform: uppercase;
    // color: ${props => props.config?.themeLayout?.title};
    color: #222222;
    cursor: initial;
  }

  a.label{
    width: 100%;
    // color: ${props => props.config?.themeLayout?.link};
    color: #222222;
    cursor: pointer;
    white-space: nowrap;
    text-overflow: ellipsis;

    &.secondary{
      color: #000;
    }

    &:hover {
      opacity: 0.7;
    }
  }

  @media (max-width: ${BREAKPOINTS.PHONE_SMALL}px){    
    width: 150px;
  }

  @media (max-width: ${BREAKPOINTS.PHONE}px){    
    &.mobile {
      display: none;
    }
  }
    
  @media (min-width:${BREAKPOINTS.PHONE + 1}px) and (max-width:${BREAKPOINTS.TABLET_LANDSCAPE}px) {
    &.tablet {
      display: none;
    }
  }

  @media (min-width:${BREAKPOINTS.TABLET_LANDSCAPE}px) {
    &.desktop {
      display: none;
    }
  } 
`

HeaderDropMenu.propTypes = {
  data: PropTypes.object,
};