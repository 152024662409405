import { useMemo } from "react";

const MoneyText = (props) => {
  let { value } = props;
  
  let formattedValue = useMemo(() => {
    let floatValue = parseFloat(value);

    if(!value && floatValue !== 0)
      return '-'
    
    return 'R$ ' + floatValue.toLocaleString('pt',{minimumFractionDigits: 2, maximumFractionDigits: 2})
  },[value])


  return formattedValue;
}

export default MoneyText;

