import React, { useRef } from 'react';

import HeaderDropMenu from './HeaderDropMenu';
import Avatar from '../Molecules/Avatar';
import styled from '@emotion/styled';
import { BREAKPOINTS } from '../configs/consts'
import Point from '../Atoms/Point';
import { ChevronDown } from 'react-feather';

function HeaderUserV2(props) {
  const { data, isOpen, toggleIsOpen, userHelper, notification, headerConfig, config, openGlobalPopup, rightPanel, dataHelper } = props;
  const thisRef = useRef(null);

  return (
    <StyledHeaderUserV2 ref={thisRef} config={config} isOpen={isOpen} onClick={() => props.toggleIsOpen()}>
      <div className={'header-user-block'}>
        <div className={'header-user-label-group'}>
          <div className={'header-user-name'}>{data?.user?.FirstName?.substring(0,15) || 'Visitante'}</div>
          <div className={'header-user-points'}>
            <Point config={config} color={'#333'} qty={data?.user?.TotalPoints__c} small />
          </div>
        </div>
        <div className={'header-user-icon'}>
          <Avatar data={data} config={config} userHelper={userHelper}/>
        </div>
        <div className={'header-user-arrow-container'}>
          <ChevronDown height={15} width={15} strokeWidth={3} color={props.config.themeLayout?.buttonText}/>
        </div>
      </div>
      <HeaderDropMenu parentRef={thisRef} menuItems={headerConfig?.headerUser?.menuItems} toggleIsOpen={toggleIsOpen} isOpen={isOpen} config={config} userHelper={userHelper} notification={notification} openGlobalPopup={openGlobalPopup} data={data} rightPanel={rightPanel} dataHelper={dataHelper} message={props.message} />
    </StyledHeaderUserV2>
  );
}

export default HeaderUserV2;

/* style */

const StyledHeaderUserV2 = styled.div`
  position: relative; 
  height: 100%;
  padding-left: 10px;
  color: ${props => props.config?.themeLayout?.link};
  cursor: pointer;
  user-select: none;
  // background: ${props => props.isOpen ? 'rgba(0,0,0,0.1)' : 'none'};
  padding-right: 20px;

  @media (min-width: ${BREAKPOINTS.TABLET_PORTRAIT}px) {
    position: relative
  }

  @media (max-width: ${BREAKPOINTS.PHONE}px) {
    display: none;
  }

  .header-user-block {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: center;
    height: 100%;

    .header-user-icon {
      width: 40px;
      height: 40px;
    }

    .header-user-label-group {
      flex: 1;
      margin-right: 10px;
      text-align: right;

      .header-user-name {
        font-size: 0.9em;
        // color: #21417c;
        color: #0759ea;
        font-weight: bold;
      }

      .header-user-points {
        font-weight: bold;
      }
    }

    .header-user-arrow-container{
      background: ${props => props.config.themeLayout?.buttonBackground};
      width: 25px;
      height: 25px;
      border-radius: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: -5px;
      z-index: 1;
    }
  }

  .header-grafismo-container{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;

    @media (min-width: ${props => props.config?.layout?.CONTAINER_SIZE + 20}px){
      overflow: initial;
    }

    @media (max-width: ${BREAKPOINTS.PHONE_BIGGER}px) {
      padding: 0px 10px 0px 10px;
    }

    @media (max-width: ${BREAKPOINTS.TABLET_PORTRAIT}px) {
      display: none;
    }

    .grafismo{
      width: 150px;
    }
  }
`