import styled from "@emotion/styled";

const SectionTitle = (props) => {
  var { config, children, small } = props;

  let extraClasses = '';

  if(small){
    extraClasses += ' small';
  }

  return <StyledSectionTitle config={config} className={extraClasses}>{children}</StyledSectionTitle>
}

export default SectionTitle;

const StyledSectionTitle = styled.h2`
  margin: 0;
  font-size: 1.5em;
  font-weight: bold;
  color: ${props => props.config?.themeLayout?.title};

  &.small{
    font-size: 1em;
  }
`