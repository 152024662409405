import styled from "@emotion/styled";

const SideTitle = (props) => {
  let { config, children, size, width } = props;

  return <StyledSideTitle size={size || null} config={config} width={width}>
    {props.subtitle && <div className={'st-subtitle'} dangerouslySetInnerHTML={{ __html: props.subtitle }} data-testid="st-subtitle" />}
    <div className={'st-title ' + (props.subtitle ? '' : 'no-subtitle')}>
      {children}
    </div>
  </StyledSideTitle>
}

export default SideTitle;

const StyledSideTitle = styled.div`

  .st-subtitle {
    color: ${props => props.config?.themeLayout?.subtext};
    font-size: 1.2em;
    font-weight: bold;
  }
  
    .st-title {
      font-size: 2em;
      font-weight: bold;
     
      color: ${props => props.config?.themeLayout?.title};
      margin-top: -0.2em;
      width: 450px;

      &.no-subtitle {
        font-size: ${props => props.size ? props.size : '2.5em'};
        margin-top: 0;
      }
    }
  `

