import React from "react";
import { useHistory } from "react-router-dom";
import { X, ArrowLeft } from 'react-feather';

import { StyledCampaignsHeaderModal } from './styled';

const CampaignsHeaderModal = (props) => {
  const history = useHistory()
  const { config, setCloseModal, setCloseSubModal, closeSubModal, title, showTitle = true, rightPanel } = props

  const handleBack = () => {
    if (closeSubModal) {
      setCloseSubModal(false)
    } else {
      setCloseModal(false)
    }
  }

  const handleClose = () => {
    rightPanel?.setIsOpen(false)
    setTimeout(() => {
      rightPanel?.setTopCloseButton('16px')
      rightPanel?.setRightCloseButton('42px')
      // history.push("/home")
      // history.go()
    }, 1000)
  }

  return (
    <StyledCampaignsHeaderModal config={config}>
      {showTitle &&
        <div data-testid='go-back' className="go-back" onClick={handleBack}>
          <ArrowLeft color={config?.themeLayout?.buttonBackground} strokeWidth={2} />
        </div>
      }
      {showTitle &&
        <div>
          <span className="customSideTitle">{title ?? 'Campanhas'}</span>
        </div>
      }
      <div data-testid='close' className='close-button' onClick={handleClose}>
        <X strokeWidth={3} height={55} width={35} />
      </div>
    </StyledCampaignsHeaderModal>
  )
}

export default CampaignsHeaderModal;