import styled from "@emotion/styled";

const NoResultsFound = (props) => {
  return <StyledNoResultsFound config={props.config}>
    <i className={`fa ${props.icon || 'fa-search'} img`} />
    <p className={'title'} style={{marginTop: props.icon ? '0px !important' : '0px'}}>{props.title || 'Nenhum resultado encontrado :('}</p>
    <p className={'message'}>{props.message || 'Por favor, tente novamente mais tarde'}</p>
  </StyledNoResultsFound>
}

export default NoResultsFound;

/* style */

const StyledNoResultsFound = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${props => props.config?.themeLayout?.text};
  user-select: none;

  >.fa{
    font-size: 5em;
  }

  .fa-trophy {
    font-size: 3em;
  }

  .title {
    margin-top: ${props => props?.children?.filter(t => t.type === 'i')[0]?.props?.className === 'fa fa-trophy img' ? '0px' : '1.5em'};
    /* text-transform: uppercase; */
    font-weight: bold;
    font-size: 1.2em;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
  }
`