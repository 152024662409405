import React from "react";

import achievementLeft from '../../../assets/achievementLeft.png';
import achievementRight from '../../../assets/achievementRight.png';

import { StyledAchievementsItem } from '../styled';

const Achievements = ({ item, setItemModalDetail, setOpenModalDetail }) => {
  const handleDetailClick = (item) => {
    setItemModalDetail(item)
    setTimeout(() => {
      setOpenModalDetail(true)
    }, 500);
  }

  return (
    <StyledAchievementsItem key={item.key}>
      <div className="mainDiv">
        <div style={{padding: '10px'}}>
          <span className="mainTitle">{item.item.name}</span>
        </div>
        {
          item.item.concluded ?
          <div className="lastBox">
            <div className="lastBoxConcluded">
              <img src={achievementLeft} alt="achiement" />
              <span className="missionDone">MISSÃO CUMPRIDA</span>
              <img src={achievementRight} alt="achiement" />
            </div>
            {
              item.item.generatedNumber 
              ? <span className="generatedNumber">Número gerado</span>
              : <span className="generatedNumber" style={{opacity: '50%'}}>Preparando número</span>
            }
          </div>
          :
          <div className="lastBox">
            <span className="missionToDone">Quero ganhar!</span>
            <div className="detailsBox" onClick={()=> handleDetailClick(item.item)}>
              <span className="detailsText">Detalhes</span>
            </div>
          </div>
        }
      </div>
    </StyledAchievementsItem>
  )
}

export default Achievements;
