import React, {useMemo, useState, useEffect} from 'react';

import ToolHelper from "../../utils/ToolHelper";

import LoadingIcon from "../../Atoms/LoadingIcon";
import SliderAtom from '../../Atoms/SliderAtom';

import CampaignsHeaderModal from '../CampaignsHeaderModal';
import CampaignsImageBackground from "../CampaignsImageBackground";
import CampaignsMyNumbersDetails from './CampaignsMyNumbersDetails';

import { StyledAchievementsSliderSection } from './styled';

import MyNumbers from './components/MyNumbers';
import Achievements from './components/Achievements';

const CampaignsMyNumbers = (props) => {
  const { config, setCloseModal, campaign, rightPanel } = props

  const configBox = useMemo(() => ToolHelper.getThemedConfig(props,'Campaigns.preset'),[config])

  const [ loading, setLoading ] = useState(true)
  const [ numbers, setNumbers ] = useState([])
  const [ pendingNumbers, setPendingNumbers ] = useState(0)
  const [ achievements, setAchievements ] = useState({})
  const [ groupAchievements, setGroupAchievements ] = useState([])

  const [ openModalDetail, setOpenModalDetail ] = useState(false)
  const [ itemModalDetail, setItemModalDetail ] = useState({})

  const groupByAchievement = (array, key) => {
    return array.reduce((acc, item) => {
        if (!acc[item[key]]) acc[item[key]] = []
          acc[item[key]].push(item)
          return acc
      }, {})
  }

  const getMyNumbers = async () => {
    const result = await props.dataHelper.getCampaignsMyNumbers(campaign.campaignId, props?.data?.user?.FirebaseId__c)

    if (result) {
      setNumbers(result?.numbers)
      const groups = groupByAchievement(result?.achievements, 'groupAchievement')
      setGroupAchievements(Object.keys(groups).sort())
      setAchievements(groups)
      setPendingNumbers(result?.pendingNumbers)
      setLoading(false)
    } else {
      setLoading(false)
    }
  }

  useEffect(() => {
    getMyNumbers()
  }, [])

  const sliderOptions = {
    slidesToShow: 3,
    lazyLoad: true,
    speed: 500,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 768,
        settings: { slidesToShow: 1, slidesToScroll: 1, infinite: false }
      },
      {
        breakpoint: 900,
        settings: { slidesToShow: 3, slidesToScroll: 1, infinite: false }
      },
      {
        breakpoint: 1024,
        settings: { slidesToShow: 3, slidesToScroll: 1, infinite: false }
      }
    ],
  }

  return loading ? <LoadingIcon config={configBox} /> :
    <div className="mainContent">
      <CampaignsHeaderModal {...props} setCloseModal={setCloseModal} rightPanel={rightPanel} />
      <div className="mainSection">
        <CampaignsImageBackground config={configBox} name={campaign.name} link={campaign.link} img={campaign.image} />
        <div className="modalTitle">
          <span>Seus números da sorte</span>
        </div>
        <div className="myNumbersContainer" style={{paddingTop: '10px'}}>
          <div className="firstDiv">
            <div className="box">
              <div className="boxInfo">
                <span className="infoText">Números da Sorte gerados:</span>
                <span className="infoNumber">{numbers.length}</span>
              </div>
              <div className="boxInfoData">
                {/* Data chumbada temporariamente */}
                {/* <span className="infoData">Emissão em 17/06/2024</span> */}
                <span className="infoData"></span>
              </div>
            </div>
            <div className="box">
              <div className="boxInfo">
                <span className="infoText">Números da Sorte parciais:</span>
                <span className="infoNumber">{pendingNumbers}</span>
              </div>
              {/* {
                pendingNumbers > 0 &&
                <div className="boxInfoData">
                  <span className="infoData">De {moment(campaign.startDate).format("DD/MM/YYYY")} a {moment(campaign.endDate).format("DD/MM/YYYY")}</span>
                </div>
              } */}
            </div>
          </div>
          {
            (numbers && numbers.length > 0) &&
            <>
              <div className="divTitle" style={{textAlign: 'center'}}>
                <span className="titleSection">Estes são os Números da Sorte já gerados para você concorrer aos Superprêmios. Boa sorte!</span>
              </div>
              {
                numbers?.length > 0 &&
                <div style={{display: 'flex', justifyContent: 'center', gap: '10px', flexWrap: 'wrap', marginTop: 20}}>
                  {
                    numbers?.map((item, idx) => (
                      <MyNumbers number={item} key={idx} idx={idx}/>
                    ))
                  }
                </div>
                // numbers?.length > 6
                // ? <div style={{display: 'flex', justifyContent: 'center', gap: '10px', flexWrap: 'wrap', marginTop: 20}}>
                //     {
                //       numbers?.map((item, idx) => (
                //         idx < 6 && <MyNumbers number={item} key={idx} idx={idx}/>
                //       ))
                //     }
                //   </div>
                // : <StyledAchievementsSliderSection key={'myNumbersSlide'}>
                //     <SliderAtom config={configBox } customOptions={sliderOptions}>
                //       {numbers?.map((item, idx) => (
                //         <MyNumbers number={item} key={idx} idx={idx}/>
                //       ))}
                //     </SliderAtom>
                //   </StyledAchievementsSliderSection>
              }
              
            </>
          }
          {(achievements) &&
            <>
              <div className="divTitle">
                <span className="titleSection">Cumpra os desafios e ganhe mais números</span>
              </div>
              {                
                groupAchievements?.map((item, idx) => (
                  achievements[item]?.length > 0 &&
                    <>
                      <span className="groupTitleSection">{item}</span>
                      <StyledAchievementsSliderSection key={'achievementsSlide'}>
                        <SliderAtom config={configBox } customOptions={sliderOptions}>
                          {achievements[item]?.map((item, idx) => (
                            <Achievements item={{item, key: idx}} key={idx} idx={idx} setItemModalDetail={setItemModalDetail} setOpenModalDetail={setOpenModalDetail} />
                          ))}
                        </SliderAtom>
                      </StyledAchievementsSliderSection>
                    </>
                ))
              }
              
            </>
          }
        </div>
      </div>
      {
        openModalDetail && <CampaignsMyNumbersDetails config={config} setOpenModalDetail={setOpenModalDetail} itemModalDetail={itemModalDetail} />
      }
    </div>  
}

export default CampaignsMyNumbers;