import React, { useMemo, useEffect, useState } from "react";
import moment from "moment";

import Button from "../../Atoms/Button";

import { StyledCategorySection } from '../styled';
import CampaignsImageBackground from "../CampaignsImageBackground";

import Description from "./Description";

import ToolHelper from "../../utils/ToolHelper";

const Card = (props) => {
  const [showKnowMore, setShowKnowMore] = useState('')
  const config = useMemo(() => ToolHelper.getThemedConfig(props, 'Campaigns.preset'), [props.config]);

  const { item, currentCategory, handleButtonClick, disabled, campaignKnowMore } = props
  const now = moment()
  
  const handleKnowMore = () => {
    window.open(showKnowMore, "_blank")
  }

  useEffect(() => {
    for(let i in campaignKnowMore) {
      if (campaignKnowMore[i].includes(item.campaignId)) {
        let spl = campaignKnowMore[i].split(',')
        let url = spl[1]?.replace(` url: '`, '').replace(`'}`, '').replace(`]`, '')
        setShowKnowMore(url)
      }
    }
  }, [])

  return (
    <StyledCategorySection key={item.campaignId}  data-testid={item.campaignId}>
      <CampaignsImageBackground {...props} name={item.name} link={item.link} img={item.image} />

      <div className="info">
        <Description {...item} />
        <span className="validity">{`Vigência de: ${moment(item.startDate).format("DD/MM/YYYY")} a ${moment(item.endDate).format("DD/MM/YYYY")}`}</span>
        {
          (currentCategory === 'Vigentes' && item.subscriptionEnd && !item.raffleParticipant && item.type?.toString().toLowerCase() === 'raffle') &&
          <span className="validity">{`Data limite para inscrição: ${moment(item.subscriptionEnd).format("DD/MM/YYYY")}`}</span>
        }
      </div>
      {
        (item.type?.toString().toLowerCase() === 'raffle' || showKnowMore) &&
        <div className="buttons">
          {
            item.type?.toString().toLowerCase() === 'raffle'
              ? (currentCategory === 'Encerradas' || (!item.subscriptionEnd || moment(item.subscriptionEnd) < now))
                ? <Button testId='btn-regulation' config={config} small className="btn" dataValue={JSON.stringify(item)} onClick={(e) => handleButtonClick(e, `${item.campaignId}_REGULATION`)}>Regulamento</Button>
                : <Button testId='btn-regulation' config={config} small className="btn" dataValue={JSON.stringify(item)} onClick={(e) => handleButtonClick(e, `${item.campaignId}_REGULATION`)}>{item.raffleParticipant ? 'Regulamento' : 'Participar'}</Button>
              : (item.rules) && <Button testId='btn-regulation' config={config} small className="btn" dataValue={JSON.stringify(item)} onClick={(e) => handleButtonClick(e, `${item.campaignId}_REGULATION`)}>Regulamento</Button>
          }

          {(item.type?.toString().toLowerCase() === 'raffle' && item.raffleParticipant) && <Button config={config} small className="btn" disabled={disabled === `${item.campaignId}_NUMBERS`} dataValue={JSON.stringify(item)} onClick={(e) => handleButtonClick(e, `${item.campaignId}_NUMBERS`)}>Meus números</Button>}
          {item.type?.toString().toLowerCase() === 'raffle' && <Button testId='btn-ganhadores' config={config} small className="btn" disabled={disabled === `${item.campaignId}_RESULTS`} dataValue={JSON.stringify(item)} onClick={(e) => handleButtonClick(e, `${item.campaignId}_RESULTS`)}>Ganhadores</Button>}
          {showKnowMore && <Button testId='btn-know-more' config={config} small className="btn" dataValue={JSON.stringify(item)} onClick={handleKnowMore}>Saiba mais</Button>}
        </div>
      }
      {item.footer &&
        <div style={{ padding: '10px' }}>
          <p style={{ fontSize: '10px', textAlign: 'center', color: '#888' }}>
            {item.footer}
            {/* Promoção válida para Produtores Rurais cadastrados no Acessa Agro e que cumpram os critérios estabelecidos em regulamento, no período de 01/05/2024 a 31/05/2024. Consulte demais condições e produtos participantes no regulamento. Certificado de autorização SPA/ME N°04.033157/2024. */}
          </p>
        </div>
      }
    </StyledCategorySection>
  )
}

export default Card;