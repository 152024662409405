import SwitchAccountLink from "../CustomLinks/SwitchAccountLink";

const CustomLink = props => {
  const { componentName } = props;

  switch(componentName){
    case 'SwitchAccountLink':
      return <SwitchAccountLink {...props} />
    break;
  }

  return <></>
}

export default CustomLink;