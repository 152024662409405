import styled from "@emotion/styled";
import { BREAKPOINTS } from "../configs/consts";

export const StyledCampaigns = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 15px 36px;
  color: ${props => props.config?.themeLayout?.text};
  background-color: #ffffff !important;
  width: calc(100% - 576px);
  overflow: auto;

  @media (max-width: ${BREAKPOINTS.PHONE_SMALL}px) {
    width: 100%;
  }

  .mainContent {
    display: flex;
    flex: 1;
    flex-direction: column;
    position: fixed;
    background-color: #ffffff !important;
    bottom:0;
    right:0;
    border-radius: 6px;
    width: 576px;
    height:100vh;
    z-index:9999;
    overflow-y:auto; 
    -webkit-box-shadow:0 0 20px rgba(0,0,0,0.8);
    -moz-box-shadow:0 0 20px rgba(0,0,0,0.8);
    box-shadow:0 0 20px rgba(0,0,0,0.8);

    @media (max-width: ${BREAKPOINTS.PHONE}px) {
      width: 100%;
    }
  }

  .mainSection {
    border-radius: 12px;
    border: solid thin #CCCCCC;
    margin: 15px 45px 10px 45px;
  }

  .info {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px;

    .descriptionContainer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-top: 10px;
    }

    .description {
      color: ${props => props.config?.themeLayout?.text};
      font-weight: normal;
      font-size: 14px;
      padding-left: 30px;
      padding-right: 30px;
      text-align: center;
    }

    .descriptionHighlighted {
      color: ${props => props.config?.themeLayout?.text};
      font-weight: bold;
      font-size: 14px;
    }

    .validity {
      padding-top: 10px;
      font-weight: medium;
      font-size: 14px;
      color: #888;
    }
  }

  .buttons {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    gap: 10px;

    @media (max-width: ${BREAKPOINTS.PHONE}px) {
      flex-direction: column;
      align-items: center;
    }

    .btn {
      align-items: center;
      width: 140px;
      height: 40px;
      font-weight: bold;
      font-size: 15px;

      @media (min-width: ${BREAKPOINTS.PHONE_SMALL}px) and (max-width: ${BREAKPOINTS.PHONE_BIGGER}px) {
        margin-bottom: 10px;
      }
    }
  }
  
  .buttons:last-child {
    margin-bottom: 10px;

    @media (min-width: ${BREAKPOINTS.PHONE_SMALL}px) and (max-width: ${BREAKPOINTS.PHONE_BIGGER}px) {
      margin-bottom: 0px;
    }
  }

  .modalTitle {
    margin: 15px 0px 15px 0px;
    text-align: center;

    span {
      color: #0759ea;
      font-weight: 700;
      font-size: 24px;
    }
  }

  .detailsTitle {
    display: flex;
    justify-content: center;
    margin-top: 15px;

    span {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #222222
      text-align: center;
    }
  }

  .lineSeparator {
    margin: 0px 30px 30px 30px;
    border-bottom: solid thin #CCCCCC;
  }

  .myNumbersContainer {
    display: flex;
    flex-direction: column;
    padding: 0px 30px 30px 30px;

    p {
      font-weight: 700;
      font-size: 14px;
      line-height: 22px;
      color: #444444;
    }

    .imgLuck {
      width: 31px;
      height: 31px;
    }
    
    .imgLuckFirstColumn {
      width: 31px;
      height: 31px;
      margin-left: -5px;
    }

    .imgCoin {
      width: 21px;
      height: 21px;
    }

    .luckNumbersContainer {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
  
    }

    .divTitle {
      padding: 20px 0px 0px 0px;
      display: flex;
      justify-content: center;

      .titleSection {
        font-size: 16px;
        font-weight: 600;
        color: #0759ea;
        line-height: 18px;

        @media (max-width: ${BREAKPOINTS.PHONE_BIGGER}px) {
          text-align: center;
        }
      }

    }
    
    .groupTitleSection {
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0.6px;
      color: #222222;
      line-height: 17.22px;
      padding: 10px 0px 0px 20px;

      @media (max-width:${BREAKPOINTS.PHONE_BIGGER}px) {
        padding: 10px 0px 0px 0px;
        text-align: center;
      }
    }

    .firstDiv {
      display: flex;
      flex-direction: row;
      gap: 10px;
      justify-content: center;

      .box {
        width: 191px;
        // height: 78px;
        background-color: #085FF7;
        border-radius: 12px;
        border: 1px solid #86D60A;
        padding: 2px, 17px, 11px, 17px;

        .boxInfoData {
          width: 100%;
          text-align: center;
          line-height: 0.9;
          margin-bottom: 5px;

          .infoData {
            color: #FFFFFF;
            opacity: 0.6;
            font-weight: 500;
            font-size: 11px;
          }
        }

        .boxInfo {
          display: flex;
          flex-direction: row;
          gap: 5px;
          align-items: center;
          justify-content: center;
          padding-left: 10px;
          padding-right: 10px;
        
          .infoText {
            font-size: 12px;
            line-height: 16px;
            color: #FFFFFF;
            font-weight: 700;
          }

          .infoNumber {
            font-size: 42px;
            line-height: 51.66px;
            color: #FFFFFF;
            font-weight: 700;
          }
        }


      }
    }

    .myNumbersContainer {
      display: flex;
      justify-content: center;
      gap: 10px;
      flex-wrap: wrap;
      margin-top: 20;
    }

    .myNumberBox {
      background-color: #86D60A;
      padding: 12px, 23px, 12px, 23px;
      border-radius: 12px;
      min-width: 118px;
      height: 46px;
      display: flex;
      align-items: center;
      justify-content: center;

      .myNumber {
        font-size: 18px;
        font-weight: 900;
        line-height: 22.14px;
        color: #FFFFFF;
        display: flex;
        align-items: center;
      }
    }
  }

  .btnContainer {
    padding-bottom: 20px;
  }

  .pdfBtn {
    background-color: #88c929;
    color: #FFFFFF;
    cursor: pointer;
    justify-content: center;
    width: 150px;
    height: 50px;
  }

  .acceptContainer {
    padding: 15px 0px 15px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: ${BREAKPOINTS.PHONE_SMALL}px) and (max-width: ${BREAKPOINTS.PHONE_BIGGER}px) {
      padding: 15px 0px 15px 10px;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .acceptCheckbox {
    display: flex;
    flex-direction: row;

    @media (min-width: ${BREAKPOINTS.PHONE_SMALL}px) and (max-width: ${BREAKPOINTS.PHONE_BIGGER}px) {
      flex-direction: column;
    }
  }

  .acceptDescription {
    display: flex;
    flex-direction: row;
    margin-top: 3px;
    align-items: center;

    span {
      font-weight: normal;
      font-size: 12px;
    }

    .spanHigh {
      // color: #ABC432;
      color: ${props => props.config?.themeLayout?.link};
      font-weight: bold;
      cursor: pointer;
      letter-spacing: 1px;
    }
  }

  .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 15px;

    .campaignName {
      font-weight: bold;
      font-size: 18px;
      color: #0759ea;
      text-align: center;
    }
  }
`;

export const StyledCategorySection = styled.div`
  border-radius: 12px;
  border: solid thin #CCCCCC;
  margin: 10px 0px 10px 0px;

  .loading-container {
    height: 350px;
  }
`;

export const StyledCampaignsImageBackground = styled.div`
  .subContent {
    width: 100%;
    min-height: 170px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: transparent;
    border-radius: 12px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center center;
    object-fit: contain;
  }

  .divHeader {
    top: 0;
    display: flex;
    justify-content: flex-end;
    padding: 15px 20px;
  }

  .divFooter {
    display: flex;
    justify-content: flex-end;
    padding: 15px 20px;
  }
  
  .action-btn {
    background-color: rgba(34, 34, 34, 0.7);
    font-weight: bold;
    font-size: 14;
    width: 95x;
    height: 32px;
    color: #FFFFFF;
  }
`;

export const StyledCampaignsHeaderSection = styled.div`
  .sep {
    margin: 0 8px;
    color: ${props => props.config?.themeLayout?.title};
  }

  .row-subtitle {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .st-title {
    padding-bottom: 20px !important;
  }

  .icon {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;

    .hide {
      display: none;
    }

    a.secondary.open{
      border-top-left-radius: 0;
      border-bottom-right-radius: 0;
      border-top-right-radius: 25px;
      border-bottom-left-radius: 25px;
    }

    .button-label{
      display: flex;
      align-items: center;

      @media (max-width: ${BREAKPOINTS.PHONE}px) {
        display: none;
      }
    }
  }
`;

export const StyledCampaignsHeaderModal = styled.div`
display: flex;
flex-direction: row;
align-items: center;
height:60px;
margin-left: 30px;
margin-top: 10px;

.customSideTitle {
  font-size: 2.5em;
  font-weight: bold;
  color: #0759ea;
  margin-left: 10px;
  
  @media (max-width: ${BREAKPOINTS.PHONE_BIGGER}px) {
    font-size: 2.2em;
    margin-left: 20px;
  }
}

.go-back {
  background-color: #f0f6e0;
  border-radius: 50%;
  display: flex;
  cursor: pointer;
  height: 45px;
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #88c929;

  @media (max-width: ${BREAKPOINTS.PHONE_BIGGER}px) {
    width: 40px;
    height: 40px;
  }
}
`;

export const StyledCampaignsFilterSection = styled.div`
display: none;
border-radius: 30px;
background-color: #f6f6f6;
position: relative;

&.visible {
  display: block !important;
}

.input-container {
  .error {
    display: none;
  }
}

.icon {
  position: absolute;
  top: 0;
  right: 0;

  a.secondary {
    border-top-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-right-radius: 25px;
    border-bottom-left-radius: 25px;
  }

  .button-label{
    display: flex;
    align-items: center;
  }
}

> div:first-of-type {
  display: flex;
  flex: 1;
  align-items: center;

  .content-find {
    display: flex;
    flex-direction: row;
    padding: 30px;

    @media (max-width:${BREAKPOINTS.PHONE}px) {
      margin-top: 55px;
      flex-direction: column;
      align-items: end;
      width: 100%;
    }

    .input-ciclo {
      padding-top: 2px;
      // padding-left: 40px;
      width: 300px;
    }
  }


  @media (max-width:${BREAKPOINTS.PHONE}px) {
    display: block;
  }
}

.filter-bits{
  padding: 0px 0px 20px 40px;

  .filter-bit{
    display: inline-block;

    &:not(:last-of-type){
      margin-right: 10px;
    }

    .filter-bit-content{
      display: flex;
      align-items: center;
      justify-content: center;
      background: #f3f8e0;
      padding: 10px 10px 10px 15px;
      border-radius: 10px;
      cursor: pointer;
      font-size: 0.8em;

      &:hover{
        opacity: 0.5;
      }

      .filter-bit-label{
        font-weight: bold;
        margin-right: 5px;
      }

      .filter-bit-value{
        margin-right: 10px;
        flex: 1;
      }
    }
  }
}
`;