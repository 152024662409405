import { useState } from "react";

class NotificationTools {
  parseNotification(params) {

  }
}

const MessageHelper = {
  // Controlador de notificações
  useNotification: () => {
    const [ list, setList ] = useState([]);

    const add = (notification,data) => {
      var params = {
        id: Math.floor(Math.random() * 10000000),
        title: notification.title,
        message: notification.body,
        data
      }

      console.log('added notification!',params);
      setList(e => e?.concat([params]))

      // setTimeout(() => close(notification.id),10000);

      return params.id;
    }
    
    const close = (id) => {
      console.log('closing notification',id);
      setList(e => e.filter((n) => n.id !== id))
    }

    return {
      list,
      setList,
      add,
      close
    }
  },

  // Controlador de mensagens de aviso
  useMessage: () => {
    const [ list, setList ] = useState([]);
    const [ update, setUpdate ] = useState();

    const types = [
      'success',
      'warning',
      'error',
      'info',
      'loading',
    ]

    const isValidType = (type) => {
      return types.includes(type);
    }

    /* params
      type: Tipo types
      title: Texto destaque
      body: Texto normal
      link: onClick link
      isUnique: true (default) = Não repetir a mesma mensagem. Caso entrar uma mensagem igual, a existente irá "vibrar"
      isLone: true (default) = Ao entrar esta mensagem, todas as outras são removidas
      persist: false (default) = Ao trocar de tela, remover a mensagem
    */
    const add = (params) => {
      const defaultParams = {
        id: Math.floor(Math.random() * 10000000),
        type: 'success',
        isUnique: true,
        isLone: true,
        persist: false,
      }

      params = {
        ...defaultParams,
        ...params,
      }

      if(params.message && !params.body){
        params.body = params.message;
      }
      
      setList(list => {
        let addMessage = true;

        if(params.isUnique){
  
          list.map((listMessage) => {
            if(
              listMessage.title === params.title &&
              listMessage.body === params.body &&
              listMessage.type === params.type
            ){
              let animationClass = (listMessage.type === 'error') ? 'shake' : 'glow';
              listMessage[animationClass] = false;
              addMessage = false;

              if(listMessage.toIn){
                clearTimeout(listMessage.toIn);
              }

              if(listMessage.toOut){
                clearTimeout(listMessage.toOut);
              }

              listMessage.toIn = setTimeout(() => {
                listMessage[animationClass] = true;
                setUpdate(Math.random() * 10000000);
              },100)

              listMessage.toOut = setTimeout(() => {
                listMessage[animationClass] = false;
                setUpdate(Math.random() * 10000000);
              },1000)
            }
          })
        }
  
        if(addMessage){
          if(params.isLone){
            list = [params];
          }
          else{
            list = list.concat([params])
          }
        }

        return list;
      })

      setUpdate(Math.random() * 10000000);

      return params.id;
    }

    const change = (id,params) => {
      setList(list => 
        list?.map(message => {
          if(message.id === id){
            if(params.message)
              params.body = params.message
            
            message = {...message,...params}
          }

          return message;
        })
      )
    }
    
    const close = (id) => {
      console.log('closing message',id);
      setList(e => e.filter((n) => n.id !== id))
    }

    const closeAllPageMessages = () => {
      setList(list => {
        list = list.filter((obj) => obj.persist)
        return list;
      })
    }

    return {
      list,
      setList,
      add,
      change,
      close,
      closeAllPageMessages,
      update,
      isValidType,
    }
  },
}

export default MessageHelper;