import React, { useMemo, useState, useEffect } from "react";
import moment from "moment";

import CampaignsHeaderModal from '../CampaignsHeaderModal';
import CampaignsImageBackground from "../CampaignsImageBackground";
import CampaignsResultsDetail from "./CampaignsResultsDetail";
import ResultItem from "./components/ResultItem";

import Button from "../../Atoms/Button";
import LoadingIcon from "../../Atoms/LoadingIcon";

import ToolHelper from "../../utils/ToolHelper";

import {StyledBtnsContainer} from './styled';
import NoResultsFound from "../../Molecules/NoResultsFound";

const CampaignsResults = (props) => {
  const { config, setCloseModal, campaign, closeSubModal, setCloseSubModal, rightPanel, user } = props
  const configBox = useMemo(() => ToolHelper.getThemedConfig(props,'Campaigns.preset'),[config])

  const [ loading, setLoading ] = useState(true)
  const [ disabled, setDisabled ] = useState(false)
  const [ results, setResults ] = useState([])

  const [ actualResults, setActualResults ] = useState({})

  const [ title, setTitle ] = useState('')

  const getResults = async () => {
    const result = await props.dataHelper.getCampaignsResults(campaign.campaignId, props?.data?.user?.FirebaseId__c)
    if (result) {
      setResults(result)
      setLoading(false)
    } else {
      setLoading(false)
    }
  }

  const Raffle = (item) => {
    return (
      <StyledBtnsContainer>
        <Button config={configBox} medium className={disabled ? 'btnDisabled' : 'btn'} dataValue={JSON.stringify(item)} onClick={(e) => handleButtonClick(e, `Sorteio de ${moment(item.raffleDate).format('DD/MM/YYYY')}`)}>{`Sorteio de ${moment(item.raffleDate).format('DD/MM/YYYY')}`}</Button>
      </StyledBtnsContainer>
    )
  }

  const handleButtonClick = (e, title) => {
    setDisabled(true)
    let v = e.target.attributes.getNamedItem('data-value')
    if (v) {
      setTitle(title)
      setActualResults(JSON.parse(v.value))
      setCloseSubModal(true)
    }
    setDisabled(false)
  }

  useEffect(() => {
    getResults()
  }, [])

  return (
    loading ? <LoadingIcon config={configBox} /> :
    <div className="mainContent">
      <CampaignsHeaderModal {...props} setCloseModal={setCloseModal} rightPanel={rightPanel} />
      <div className="mainSection">
        <CampaignsImageBackground config={configBox} name={campaign.name} link={campaign.link} img={campaign.image} />
        <div className="modalTitle">
          <span>Ganhadores</span>
        </div>
        <div className="btnContainer">
          {results.length === 1 ?
            <div className="resultItemContainer">
              {
                results[0].winners?.length === 0 ?
                <div className="no-results" style={{marginTop: '10px'}}>
                    <NoResultsFound
                        config={config}
                        icon='fa-trophy'
                        title="Os ganhadores serão divulgados na data 02/07/2024"
                        message=" "
                    />
                </div> :
                results[0].winners?.map((item, index) => (
                  <ResultItem {...item} key={index} user={props.data.user}/>
                )) 
              }
            </div> :  
            results?.map((item, index) => (
              <Raffle {...item} key={index} />
            ))
          }


        </div>
      </div>
      {
        closeSubModal && <CampaignsResultsDetail results={actualResults} campaign={campaign} setCloseModal={setCloseModal} config={config} closeSubModal={closeSubModal} setCloseSubModal={setCloseSubModal} user={props.data.user} rightPanel={rightPanel} title={title} />
      }
    </div>
  )
}

export default CampaignsResults;