import styled from "@emotion/styled";
import { BREAKPOINTS } from "../../configs/consts";

export const StyledAchievementsSliderSection = styled.div`
  .slick-slider{
    text-align: center !important;
    width: 100%;
    padding-right: 0 !important;
    overflow: hidden;
  }

  .slick-track{
    display: flex !important;
  }

  .left{
    width: 30px !important;
    height: 30px !important;
  }

  .right{
    width: 30px !important;
    height: 30px !important;
  }

  .slick-slide{
    // width: 103px !important;
    height: inherit !important;
    display: flex;
    justify-content: center;

    >div{
      height: 100%;
      transform: scale(.9);
    }
  }

`

export const StyledAchievementsItem = styled.div`
  position: relative;
  width: 122px !important;
  height: 130px !important;
  border: 1px solid #86D60A;
  border-radius: 12px;
  display: flex;
  justify-content: center;

  .mainDiv {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    background-color: #085FF7;
    border: 1px solid #86D60A;  
    width: 122px;
    height: 60px;
  }

  .mainTitle {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    font-weight: 700;
    // line-height: 17.22px;
    text-align: center;
    // text-transform: uppercase;
    color: #FFFFFF;
  }
  
  .lastBoxConcluded {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin-top: 15px;
    
    img {
      height: 16px;
      width: 16px;
    }
  }

  .lastBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  .missionDone {
    width: 67px;
    font-size: 13px;
    font-weight: 700;
    // line-height: 12px;
    text-align: center;
    text-transform: uppercase;
    color: #86D60A;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .missionToDone {
    margin-top: 10px;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.6px;
    text-align: center;
    color: #222222;
  }

  .generatedNumber {
    font-size: 11px;
    font-weight: 600;
    line-height: 13.53px;
    text-align: center;
    color: #222222;
    margin-top: 6px;
  }

  .detailsBox {
    margin-top: 5px;
    background-color: #86D60A;
    padding: 2px, 10px, 2px, 10px;
    border-radius: 6px;
    width: 80px;
    min-height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    cursor: pointer;

    .detailsText {
      font-size: 12px;
      font-weight: 700;
      line-height: 18px;
      letter-spacing: 1.3px;
      color: #FFFFFF;
      display: flex;
      align-items: center;
    }
  }
`

export const StyledWebPopup = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  background-color: #FFF;
  border-radius: 18px;
  top: 45%;
  margin-left: 90px;
  width: 396px;
  min-height: 119px;
  border-radius: 18px;
  border: 1px solid #222;
  z-index: 9999;

  .mainSection {
    opacity: 50% !important;
  }

  @media (max-width: ${BREAKPOINTS.PHONE}px) {
    margin-left: 0px;
    width: 96%;
    margin: 0 6px;
  }
 
  .white-box {

    @media (max-width: ${BREAKPOINTS.PHONE}px) {
      padding: 0px;
      background-color: #FFF;
      margin: 0 8px;
    }
   
    .internal-container {
      margin: 10px 20px;
      display: flex;
      flex-direction: column;
      gap: 12px;

      @media (max-width: ${BREAKPOINTS.PHONE}px) {
      }

      .text-content {
        display: flex;
        align-items: center;
        gap: 8;

        p {
          font-weight: 500;
          font-size: 14px;
          // line-height: 24px;
          color: #222222;
        }

        span {
          font-weight: 700;
        }
      }
    }

    .close-btn-container {
      display: flex;
      justify-content: flex-end;
    }
  
    .close-btn {
      display: flex;
      height: 30px;
      width: 30px;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      cursor: pointer;
      user-select: none;
      background-color: #f0f6e0;
      color: #86D60A;
    }
  }
`;