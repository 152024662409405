import React, {useMemo} from "react";
import { ExternalLink } from "react-feather";

import Button from "../Atoms/Button";

import ToolHelper from "../utils/ToolHelper";

import placeholder from '../assets/placeholder.png';

import {StyledCampaignsImageBackground} from './styled'

const CampaignsImageBackground = (props) => {
  const { name, link, img } = props;
  const config = useMemo(() => ToolHelper.getThemedConfig(props, 'Campaigns.preset'), [props.config]);

  const handleClickRedirect = () => {
    window.open(link, "_blank")
  }

  return (
    <StyledCampaignsImageBackground>
      <div className="subContent"  style={{backgroundImage:`url('${img ?? placeholder}')`}}>
        <div className="divHeader">
        </div>
        {
         link &&
          <div className="divFooter">
            <Button
              className="action-btn"
              config={config}
              onClick={handleClickRedirect}
            >
              <span>
                Site&nbsp;&nbsp;
              </span>
              <ExternalLink height={22} width={22}/>  
            </Button>
          </div>
        }
      </div>
      <div className="title">
        <span className="campaignName">{name}</span>
      </div>
    </StyledCampaignsImageBackground>
  )
}


export default CampaignsImageBackground;
