import styled from "@emotion/styled";
import { useState, useEffect } from "react";

const ToggleSlider = (props) => {
  let { config, onToggle, isChecked } = props;
  let [ isCheckedState, setIsCheckedState ] = useState(false);

  useEffect(() => {
    setIsCheckedState(!!isChecked)
  },[isChecked])

  const onChangeF = (e) => {
    setIsCheckedState(c => !c)
    onToggle(e?.target?.checked);
  }

  return <StyledToggleSlider config={config}>
    <label className={'switch'}>
      <input type={'checkbox'} checked={isCheckedState} onChange={onChangeF}/>
      <span className={'slider'}></span>
    </label>
  </StyledToggleSlider>
}

export default ToggleSlider;

/* style */

const StyledToggleSlider = styled.span`
  .switch{
    margin-left: 10px;
    position: relative;
    display: inline-block;
    width: 2.2em;
    height: 1.1em;
    transform: translateY(0.16em);

    input{
      opacity: 0;
      width: 0;
      height: 0;

      &:checked + .slider{
        background-color: ${props => props.config?.themeLayout?.link};

        &:before{
          transform: translateX(1.1em);
        }
      }

      &:focus + .slider{
        box-shadow: 0 0 1px ${props => props.config?.themeLayout?.link};
      }
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      -webkit-transition: .4s;
      transition: .4s;
      border-radius: 1.1em;

      &:before{
        position: absolute;
        content: "";
        height: 0.68em;
        width: 0.68em;
        left: 0.2em;
        bottom: 0.22em;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
        border-radius: 50%;
      }
    }
  }
`