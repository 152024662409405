import { Route, Redirect } from 'react-router-dom';

function RedirectRoute(config) {
  return (
    <Route
      {...config}
      render={
        ({ location }) => (
          <Redirect
            status={config.status || 301}
            to={{
              pathname: config.to,
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

export default RedirectRoute;
