import styled from "@emotion/styled";

const LoadingIcon = (props) => {
  const { config, size = 20 } = props;
  return <StyledLoadingIcon data-testid='loading' config={config} size={size}>
    <div className={'dot dot1'} />
    <div className={'dot dot2'} />
    <div className={'dot dot3'} />
    <div className={'dot dot4'} />
  </StyledLoadingIcon>
}

export default LoadingIcon;

const StyledLoadingIcon = styled.div`
  flex: 1;
  position: relative;

  .dot{
    position: absolute;
    top: calc(50% - 10px);
    left: calc(50% - 10px);
    width: 20px;
    height: 20px;
    background: ${props => props.config?.themeLayout?.text || 'black'};
    border-radius: 100%;
    animation: movingdot 2s infinite linear;
    transform: scale(0);

    @keyframes movingdot {
      0% { transform: scale(0) translateX(-75px); }
      50% { transform: scale(1) translateX(0px); }
      99% { transform: scale(0) translateX(75px); }
      100% { transform: scale(0) translateX(-75px); }
    }
  }

  .dot2{
    animation-delay: 0.5s;
  }

  .dot3{
    animation-delay: 1s;
  }

  .dot4{
    animation-delay: 1.5s;
  }
`