import styled from "@emotion/styled";
import StyledLink from "../Molecules/StyledLink";
import Button from "./Button";


const SectionLink = (props) => {
  let {config, link} = props;

  const handleGTM = () => {
    /* Google tag manager */
    if (config.firebaseConfig.projectId !== 'syngenta-dev-c4409' && link?.title === 'Acessar o catálogo completo') {
      // slide 14
      window.dataLayer.push({
        'event': 'click',
        'event_name': 'click',
        'web': {
          'url': document.URL,
          'hostname': document.location.hostname,
          'title': document.title
          },
        'debug': {
          'dl_version': 1,
          'site': 'sign_up'
          },
        'ep': {
          'journey': 'spend_virtual_currency',
          'button_name': 'browse_to_full_ecommerce',
          'step': '4'
        }
      });
    }
  }

  return link?.display ?
    <StyledSectionLink config={config} onClick={handleGTM}>
      {link.type === 'button' ?
        <Button config={config} href={link.url} onClick={() => {
          if(link.onClick) {
            link.onClick();
          }
        }} target={link.target} small>
          {link.title}
        </Button>
        :
        <StyledLink config={config} to={link.url} onClick={link.onClick} target={link.target}>
          {link.title} <i className={'fa fa-chevron-right'}/>
        </StyledLink>
      }
    </StyledSectionLink>
    :
    <></>
}

export default SectionLink;

/* style */

const StyledSectionLink = styled.div`
  font-size: 1em;
  color: ${props => props.config.themeLayout?.link};

  .fa{
    margin-left: 10px;
    font-size: 1em;
  }
`