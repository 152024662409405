import styled from "@emotion/styled";
import { useMemo } from "react";
import SectionContainer from "../Molecules/SectionContainer";
import ToolHelper from "../utils/ToolHelper";
import appleStoreIcon from "../assets/apple-store.png";
import playStoreIcon from "../assets/play-store.png";
import { BREAKPOINTS } from "../configs/consts";
import grafismo from "../assets/grafismo_laranja.png";
import imageFooter from "../assets/footer_rodape_new.webp";
import { Link } from "react-router-dom";

const AppAdSectionV2 = (props) => {
  let { spaced } = props;

  const config = useMemo(() => ToolHelper.getThemedConfig(props,'AppAdSectionV2.preset'),[props.config]);

  const LinkBox = (props) => {
    return <StyledLinkBox>
      <span>Baixe o app</span>
      <a href={config.strings?.urls?.GOOGLE_PLAY_LINK} target={'_blank'} rel="noreferrer">
        <img src={playStoreIcon} />
      </a>
      <a href={config.strings?.urls?.APP_STORE_LINK} target={'_blank'} rel="noreferrer">
        <img src={appleStoreIcon} />
      </a>
    </StyledLinkBox>
  }

  return <StyledAppAdSectionV2 config={config} spaced={spaced}>
      <div className={'colored-bg'}>
        {
          props?.showBannerImage && (
          <div data-testid='img-banner-deslogada' className="containerImageFooter">
            <div>
              <span>
                Do plantio à colheita, <br />
                <strong>Acessa!</strong>
              </span>
              <button>
                <Link to={props?.routes.login.path}>
                  <span>Comece agora a acumular pontos!</span>
                </Link>
              </button>

            </div>
          </div>
          )
        }
        <div className={'contents-container'}>
          {/* <div className={'grafismo'}>
            <img src={grafismo} />
          </div> */}
          <div className={'logo-column'}>
            <img src={config.strings?.images?.ACESSA_WHITE_LOGO} />
            <span> Uma Iniciativa </span>
            <img src={config.strings?.images?.SYNGENTA_WHITE_LOGO} />
          </div>
          <div className={'text-column'}>
            <p className={'description'} dangerouslySetInnerHTML={{__html: config.strings?.components?.appAd?.DESCRIPTION}}/>
          </div>
          <div className={'link-box-column'}>
            <div>
              <LinkBox/>
            </div>
          </div>
          <div className={'phone-column'}>
            <div className={'phone-floater'}>
              {/* <img src={config.strings?.images?.PHONE_WITH_APP}/> */}
              <div className={'link-box-container'}>
                <LinkBox/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </StyledAppAdSectionV2>
}

export default AppAdSectionV2;

/* style */

const StyledAppAdSectionV2 = styled.div`
  margin-top: ${props => props.spaced ? '50px' : 'initial'};
  width: 100%;

  .colored-bg{
    background: ${props => props.config.themeLayout.background};
    color: ${props => props.config.themeLayout.text};
    width: 100%;
    /* margin-bottom: 50px; */

    .containerImageFooter {
      width: 100%;
      height: 300px;
      background-image: url(${imageFooter});
      /* background-position: center; */

      background-repeat: no-repeat;
      background-size: 100%;
      background-position: center;
      
      /* display: block; */
      /* margin-left: auto;
      margin-right: auto; */

      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 110px;

      div {
        padding-bottom: 26px;
        span {
          text-align: center;
          font-size: 2rem;
          font-weight: 900;
          display: block;
  
          strong {
            font-size: 5rem;
            height: 111px;
            line-height: 56px;
            letter-spacing: 1px;
          }
        }
  
        button {
          margin-top:12px ;
          padding: 20px;
          width: 308px;
          background-color: #83D104 ;
          border: 0;
          border-radius: 30px;

          &:hover {
            cursor: pointer;
          }
          
          span {
            font-family: Branding Semibold;
            font-size: 1.75rem;
            text-shadow: 0px 2px 4px #0000004D;
            color: #FFFFFF;
          }
        }
      }
    }

    .contents-container{
      position: relative;
      padding: 50px;
      display: flex;
      flex-direction: row;
      max-width: ${props => props.config?.layout?.CONTAINER_SIZE}px;
      margin: auto;
      // padding-left: ${props => props.config.layout.GUTTER}px;
      // padding-right: ${props => props.config.layout.GUTTER}px;

      @media (max-width: ${BREAKPOINTS.TABLET_PORTRAIT}px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
      }

      @media (max-width: ${BREAKPOINTS.PHONE}px) {
        grid-template-columns: repeat(1, 1fr);
        text-align: center;
        grid-gap: 60px;
      }

      /* @media (max-width: ${BREAKPOINTS.PHONE}px) {
        grid-template-columns: repeat(1, 1fr);
        grid-row-gap: 50px;
      } */

      .grafismo{
        position: absolute;
        top: 10%;
        right: 0;

        img{
          width: 100%;
        }

        @media (max-width: ${BREAKPOINTS.TABLET_PORTRAIT}px) {
          width: calc(100% - 40px);
          top: 50%;
          bottom: 0px;
          left: 50%;
          right: initial;
          transform: translateX(-50%);
        }

        /* @media (max-width: ${BREAKPOINTS.PHONE}px) {
          bottom: 80px;
        } */
      }

      .logo-column{
        display: flex;
        flex-direction: column;
        padding-right: 30px;

        @media (max-width: ${BREAKPOINTS.PHONE}px) {
          align-items: center;
        }

        span {
          font-weight: 100;
          color: #C1C1C1;
          
        }

        img{
          // padding: 10px 0;

          &:last-child {
            padding: 0;
          }
        }

        @media (max-width: ${BREAKPOINTS.TABLET_PORTRAIT}px) {
          padding-right: 0;
          /* align-items: center; */

          img{
            max-width: 150px;
          }

        }
  
      }
    
      .text-column{
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    
        .description{
          /* font-size: 1.2em; */
          font-weight: bold;
          line-height: 1.5em;
          text-align: center;
        }
    
        .app-links-section{
          margin-top: 2em;
    
          .links-description{
            font-weight: bold;
            font-size: 1.2em;
          }
        }
      }

      .link-box-column{
        display: none;

        @media (max-width: ${BREAKPOINTS.TABLET_PORTRAIT}px) {
          display: flex;
          align-items: center;
          justify-content: left;
          z-index: 2;
        }

        @media (max-width: ${BREAKPOINTS.PHONE}px) {
          justify-content: center;
        }
      }
    
      .phone-column{
        // margin: 0 0 70px 20px;
        margin: 0 0 20px 20px;
        width: 265px;
        position: relative;
        // display: flex;
        // align-items: center;
    
        @media (max-width: ${BREAKPOINTS.TABLET_PORTRAIT}px) {
          width: initial;
          margin: 0;
          overflow: hidden;
        }

        .phone-floater{
          position: absolute;
          top: 80px;
          z-index: 2;

          @media (max-width: ${BREAKPOINTS.TABLET_PORTRAIT}px) {
            position: relative;
            top: 0;
            text-align: center;
          }
    
          > img{
            width: 320px;
          
            @media (max-width: ${BREAKPOINTS.TABLET_PORTRAIT}px) {
              margin-left: 90px;
              width: 250px;
            }
          }
  
          .link-box-container{
            position: absolute;
            top: 50%;
            // left: 26%;
            // transform: translate(-50%,-50%);
            
            @media (max-width: ${BREAKPOINTS.TABLET_PORTRAIT}px) {
              display: none;
            }

            /* @media (max-width: ${BREAKPOINTS.PHONE}px) {
              display: block;
            } */
          }
        }
      }
    }
  }



  @media (max-width: ${BREAKPOINTS.TABLET_PORTRAIT}px) {
    .colored-bg {
      
      .containerImageFooter {
        display: none;
      }
    }
  }
`

const StyledLinkBox = styled.div`
  background: rgba(0,0,0,0.5);
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  border: 2px solid rgba(255,255,255,0.6);

  span{
    white-space: nowrap;
    font-weight: bold;
  }

  a{
    margin-left: 20px;
    height: 30px;

    img{
      height: 100%;
    }

    
  }
  @media (max-width: ${BREAKPOINTS.TABLET_PORTRAIT}px) {
    /* display: none; */
    padding: 10px 10px;

    a {
      margin-left: 13px;
    }
  }
`