import React, { useEffect, useMemo } from 'react'
import { X } from 'react-feather';

import {StyledCampaignsFilterSection} from './styled';

import ButtonSecondary from '../Atoms/ButtonSecondary';
import FormSelect from '../Molecules/FormSelect';
import ToolHelper from '../utils/ToolHelper';
import FormHelper from '../utils/FormHelper';

const labels = {
  ciclo: 'Ciclo'
}

const CampaignsFilterSection = (props) => {
  const { openFilter, setOpenFilter, filters, setFilters, filterValues } = props

  const configBox = useMemo(() => ToolHelper.getThemedConfig(props,'Campaigns.preset'),[props.config])

  const removeFilterBit = (key) => {
    switch(key){
      case 'ciclo':
        cicloSelect.setValue('')
      break;
      default:
        break;
    }

    updateFilters()
  }

  const getFilterLabel = (key) => {
    return labels[key];
  }

  const getFilterValue = (key,value) => {
    return value;
  }

  const cicloSelect = FormHelper.useFormInput({
    label: getFilterLabel('ciclo'),
    type: 'select',
    options: filterValues,
    startValue: filterValues.length ? filterValues[0] : '',
    onChange: (value) => {
      updateFilters()
    }
  })

  const updateFilters = () => {
    setFilters({
      ciclo: cicloSelect.getValue()
    })
  }

  useEffect(() => {
    cicloSelect.setOptions(filterValues)
}, [filterValues])

  return (
    <StyledCampaignsFilterSection className={openFilter ? 'visible' : ''}>
      <div>
        <div className='content-find'>
          <div className='input-ciclo'>
            <FormSelect 
              config={configBox} 
              layout={'compressed'}
              input={cicloSelect}
            />
          </div>
        </div>
      </div>

      <div className='icon'>
        <ButtonSecondary
          config={configBox}
          onClick={() => setOpenFilter(false)}
          type='button'
        >
          <X strokeWidth={3}/>
        </ButtonSecondary>
      </div>

      <div className='filter-bits'>
        {Object.entries(filters).map(([filterKey,filterValue],index) => {
          if (filterValue.length) {
            return (
              <a key={index} className='filter-bit' onClick={() => removeFilterBit(filterKey)}>
                <div className='filter-bit-content'>
                  <span className='filter-bit-label'>{getFilterLabel(filterKey)}</span>
                  <span className='filter-bit-value'>{getFilterValue(filterKey,filterValue)}</span>
                  <X size={16} strokeWidth={3}/>
                </div>
              </a>
            )
          }
        })}
      </div>
    </StyledCampaignsFilterSection>
  )
}

export default CampaignsFilterSection;