import styled from '@emotion/styled'

const Button = (props) => {
  const { isLoading, display, backgroundColor, textColor, textAlign, disabled } = props;
  let isDisabled = props.className?.includes('disabled');
  const buttonType = props.href?.length || props.onClick ? 'a' : 'div';

  const StyledButton = styled[buttonType]`
    width: ${props => props?.size};
    display: ${props => props.display || 'flex'};
    position: relative;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    background: ${props => props.disabled ? props.config?.themeLayout?.buttonInactiveBackground : backgroundColor || props.config?.themeLayout?.buttonBackground};
    color: ${props => props.disabled ? props.config?.themeLayout?.buttonInactiveText : textColor || props.config?.themeLayout?.buttonText};
    cursor: pointer;
    user-select: none;
    padding: ${props => props.small ? '8px 20px' : '15px 20px'};
    font-weight: bold;
    border-radius: ${props => props.config?.layout?.BUTTON_RADIUS};
    text-align: ${props => textAlign || 'center'};
    font-size: 1.1em;

    &.secondary{
      background: ${props => props.config?.themeLayout?.buttonSecondaryBackground};
      // color: ${props => props.config?.themeLayout?.buttonSecondaryText};
      color: #88c929;
    }

    &.disabled{
      background: ${props => props.config?.themeLayout?.buttonInactiveBackground};
      color: ${props => props.config?.themeLayout?.buttonInactiveText};
    }


    .loading{
      margin-left: 0.5em;
    }

    .loading-bar{
      position: absolute;
      bottom: 0;
      left: 0;
      height: 4px;
      width: 100%;
      background: repeating-linear-gradient(to right, ${props => props.config?.themeLayout?.title} 0%, ${props => props.config?.themeLayout?.link} 50%, ${props => props.config?.themeLayout?.title} 100%);
      background-size: 200% auto;
      background-position: 0 100%;
      animation: gradient 1s infinite;
      animation-fill-mode: forwards;
      animation-timing-function: linear;

      @keyframes gradient { 
        0%   { background-position: 0 0; }
        100% { background-position: -200% 0; }
      }
    }
  `;

  return <StyledButton
    display={display}
    buttonType={buttonType}
    href={!isDisabled ? props.href : undefined}
    onClick={!isDisabled ? props.onClick : undefined}
    config={props.config}
    className={(props.className || '') + (isLoading ? ' disabled' : '')}
    small={props.small}
    target={props.target}
    size={props.size}
    style={{ ...props.style }}
    data-value={props.dataValue ?? null}
    disabled={disabled}
    data-testid={props.testId ?? null}
  >
    {props.children}
    {isLoading ? <span className={'loading-bar'} data-testid="loading-bar" /> : ''}
  </StyledButton>
}

/* style */



export default Button;