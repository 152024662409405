import React, { useMemo } from "react";
import { X } from "react-feather";

import ToolHelper from "../../utils/ToolHelper";

import {StyledCampaignsRegulation, StyledCategorySection} from './styled';

const CampaignsRegulationPdf = (props) => {
  const { config, campaign, setModalRegulation } = props

  const configBox = useMemo(() => ToolHelper.getThemedConfig(props,'Campaigns.preset'),[config])

  const handleClose = () => {
    setModalRegulation(false)
  }
  
  return (
    <StyledCampaignsRegulation config={configBox}>
      <div className="close-btn-container">
        <div className="close-btn" onClick={handleClose}>
          <X strokeWidth={3} height={55} width={35} />
        </div>
      </div>
      <StyledCategorySection>
        <div className="content">
          {
            <iframe
              src={`${campaign.rules}#view=fitH&toolbar=0`}
              width="100%"
              height="100%"
              title="PDF Embed"
              loading="eager"
            ></iframe>
          }
        </div>
      </StyledCategorySection>
    </StyledCampaignsRegulation>
  )
}



export default CampaignsRegulationPdf;
