import styled from '@emotion/styled'
import ToolHelper from '../utils/ToolHelper';
import { useEffect, useState, useMemo } from 'react'

const NotificationBox = (props) => {
  const [extraClasses,setExtraClasses] = useState('');

  useEffect(() => {
    setTimeout(() => {
      setExtraClasses('active');
    },100)

    setTimeout(() => {
      closeNotification(null,false);
    },props.data?.timeout || 10000)
  },[])

  const closeNotification = (e,preventDefault = true) => {
    if(preventDefault)
      e.preventDefault();

    props.control.close(props.data.id);
  }

  return <StyledNotificationBox config={props.config} className={extraClasses} index={props.index} href={props.data?.data?.link} target={'_blank'} onClick={(e) => {closeNotification(e,false)}}>
    <div className={'close-button'} onClick={closeNotification}>
      &times;
    </div>
    <div className={'icon-holder'}>
      <img src={props.data?.icon || props.config?.logo} className={'icon'} />
    </div>
    <div className={'text-holder'}>
      <p className={'title'}>{props.data?.title}</p>
      <p className={'description'}>{props.data?.message}</p>
    </div>
  </StyledNotificationBox>
}

const NotificationContainer = (props) => {
  const config = useMemo(() => ToolHelper.getThemedConfig(props,'NotificationContainer.preset'),[props.config]);

  return <StyledNotificationContainer config={config}>
    <div className={'notification-holder'}>
      {props.control?.list?.map((notification,index) => 
        <NotificationBox key={notification.id} index={index} data={notification} config={config} control={props.control}/>
      )}
    </div>
  </StyledNotificationContainer>
}

/* styles */

const StyledNotificationContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9999;
  max-width: 400px;
  transition: all 1s;
`

const StyledNotificationBox = styled.a`
  display: block;
  position: relative;
  top: -100vh;
  right: 15px;
  width: 400px;
  background-color: white;
  display: flex;
  flex-direction: row;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.2);
  transition: all 1s;
  opacity: 0;
  cursor: pointer;
  background: ${props => props.config?.themeLayout?.background};

  &.active{
    top: ${props => ((props.index || 0) + 1) * 15}px;
    opacity: 1;
  }

  .close-button{
    position: absolute;
    top: 5px;
    right: 5px;
    width: 1em;
    height: 1em;
    text-align: center;
    line-height: 1em;
    border-radius: 1em;
    font-size: 20px;
    font-weight: bold;
    // color: ${props => props.config?.themeLayout?.link};
    color: #88c929;
    cursor: pointer;
    user-select: none;
  }

  .icon-holder{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    background: rgba(255,255,255,0.1);

    .icon{
      flex-grow: 0;
      display: inline-block;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      max-width: 60px;
      max-height: 60px;
    }
  }

  .text-holder{
    padding: 12px;
    overflow: hidden;
    word-break: break-word;
    
    .title{
      padding-right: 10px;
      font-size: 0.9em;
      font-weight: bold;
      overflow: hidden;
      color: ${props => props.config?.themeLayout?.title};
    }

    .description{
      font-size: 0.8em;
      color: ${props => props.config?.themeLayout?.text};
    }
  }
`

export default NotificationContainer;