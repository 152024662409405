const THEME_DEFAULT = {
  AppAdSection: {
    preset: 'colorful',
  },
  AppAdSectionV2: {
    preset: 'greenBg',
  },
  ArticleBox: {
    preset: 'box',
  },
  ArticleBoxBig: {
    preset: 'default',
  },
  ArticleTextSection: {
    preset: 'default',
  },
  BannerSection: {
    preset: 'default',
  },
  BannerSliderSection: {
    preset: 'box',
  },
  BusinessCycle: {
    preset: 'default',
    presetPopup: 'box'
  },
  Campaigns: {
    preset: 'default',
  },
  MemberGetMember: {
    preset: 'default',
  },
  CaseBox: {
    preset: 'box',
  },
  CaseDetailSection: {
    preset: 'funkyDefault',
    presetBox: 'box',
  },
  CaseList: {
    preset: 'funkyDefault',
    presetHeader: 'listHeader',
  },
  CaseNew: {
    preset: 'default',
  },
  ChallengeItem: {
    preset: 'box',
  },
  ChallengeSection: {
    preset: 'default',
  },
  Distributors: {
    preset: 'default',
  },
  Easy123Section: {
    preset: 'default',
    presetV2: 'colorfulV2',
  },
  Filter: {
    preset: 'colorful',
  },
  FooterLinks: {
    // preset: 'default',
    preset: 'defaultV2'
  },
  GameSection: {
    preset: 'default',
    presetBox: 'box',
    presetRewardPopup: 'default',
  },
  Header: {
    preset: 'header',
  },
  HeaderDropMenu: {
    preset: 'box',
  },
  HowToEarnItem: {
    preset: 'box',
    presetV2: 'boxV2',
  },
  HowToEarnSection: {
    preset: 'default',
    presetV2: 'colorfulV2',
  },
  InvoiceHomeSection: {
    preset: 'default',
  },
  Invoices: {
    preset: 'default',
    presetBox: 'box',
    presetPopup: 'box'
  },
  ListBox: {
    preset: 'default',
  },
  LoginBox: {
    preset: 'default',
  },
  Results: {
    preset: 'default',
  },
  NPS: {
    preset: 'default',
  },
  RewardListSection: {
    preset: 'default',
  },
  RewardItem: {
    preset: 'box',
    presetPopup: 'box',
  },
  NewsSection: {
    preset: 'default',
  },
  NewsListSection: {
    presetTabs: 'box',
    preset: 'default',
  },
  NotificationContainer: {
    preset: 'funkyDefault',
  },
  PicBlocks: {
    preset: 'default',
  },
  PointLine: {
    preset: 'default',
  },
  PointListSection: {
    preset: 'funkyDefault',
  },
  ProductList: {
    preset: 'default',
    presetHeader: 'listHeader',
    presetBox: 'box',
    presetBanner: 'listBanner',
  },
  ProductPopup: {
    preset: 'default',
  },
  SurveyListSection: {
    preset: 'default',
    presetTabs: 'box',
    presetBox: 'box',
  },
  SurveyRespondSection: {
    preset: 'funkyDefault',
    presetBox: 'box',
  },
  PremmiarVitrineSliderSection: {
    preset: 'colorfulV2',
    presetBox: 'boxV2',
  },
  BasicTemplate: {
    preset: 'default',
  },
  Climatempo: {
    preset: 'default',
  }
}

export default THEME_DEFAULT;