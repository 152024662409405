import styled from "@emotion/styled";
import { BREAKPOINTS } from "../../configs/consts";

export const StyledCampaignsRegulation = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  background-color: #ffffff !important;
  left:0;
  // top: 20;
  border-radius: 24px;
  width:80%;
  height:90vh;
  z-index:9999; 
  -webkit-box-shadow:0 0 20px rgba(0,0,0,0.8);
  -moz-box-shadow:0 0 20px rgba(0,0,0,0.8);
  box-shadow:0 0 20px rgba(0,0,0,0.8);
  margin-left: 10%;
  margin-top: 2%;
  
  @media (min-width: ${BREAKPOINTS.PHONE_SMALL}px) and (max-width: ${BREAKPOINTS.TABLET_PORTRAIT}px) {
    width: 100%;
    margin-left: 0%;
    height:100%;
    top: 0;
  }

  .close-btn-container {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    width: 100% !important;
    top: -25px;
    right: -10px;
  }

  .close-btn {
    display: flex;
    height: 50px;
    width: 50px;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    cursor: pointer;
    user-select: none;
    background-color: #f0f6e0;
    color: #ABC432;
  }
`;

export const StyledCategorySection = styled.div`
  margin-top: 30px !important;
  border-radius: 12px;
  border: solid thin #ccc;
  margin: 25px 35px 25px 25px;
  height: 100%;
  
  .content {
    color: #000;
    width: 100%;
    height: 100%;
  }
`;