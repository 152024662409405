import React from "react";

const Description = (item) => {
  if (!item.description) {
    return <></>
  }

  let spl, highlighted
  if (item.description.indexOf("###") > -1) {
    highlighted = item.description.substring(
      item.description.indexOf("###") + 3, 
      item.description.lastIndexOf("###")
    )
    spl = item.description.split('###')
  }

  if (spl) {
    return (
    <div className="descriptionContainer" id={item.campaignId} key={`${item.name}_${item.campaignId}`}>
      {
      spl.map((x, index) => (
        <span className={x === highlighted ? 'descriptionHighlighted' : 'description'} key={`${item.campaignId}_${index}`}>{x.toString()}&nbsp;</span>    
      ))
      }
    </div>
    )
  } else {
    return (
    <div className="descriptionContainer" id={item.campaignId} key={`${item.name}_${item.campaignId}`}>
      <span className="description">{item.description}</span>
    </div>
    )
  }
}

export default Description;
