import styled from "@emotion/styled";
import { BREAKPOINTS } from "../configs/consts";

export const StyledNotifications = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 40px;
  color: ${props => props.config?.themeLayout?.text};
  background-color: #ffffff !important;
  width: calc(100% - 420px);

  @media (min-width: ${BREAKPOINTS.PHONE}px){
    width: 100%;
  }

  .st-title {
    font-size: 24px !important;
    font-weight: 700 !important;
    margin-top: -15px !important;
  }

  .mainSection {
    margin: 50px 00px 30px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 335px;

    @media (max-width: ${BREAKPOINTS.PHONE_SMALL}px){
      width: 250px;
    }

    @media (max-width: ${BREAKPOINTS.PHONE}px){
      width: 300px;
    }

    .divImage {
      width: 100%;
      display: flex;
      justify-content: center;
      text-align: center;
    }

    p {
      max-width: 300px;
      margin: 30px 0px 30px 0px;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: #444444;

      strong {
        font-weight: 700;
      }
    }
    
    .card {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border-radius: 12px;
      border: solid thin #BBBBBB;
      padding: 10px 20px 10px 20px;

      &:last-child {
        margin: 20px 0px 0px 0px;
      }

      .number {
        font-size: 32px;
        font-weight: 500;
        line-height: 39.36px;
        color: #444444;
      }

      .action {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        padding: 0px 10px 0px 10px;
        cursor: pointer;

        span {
          max-width: 72px;
          font-size: 12px;
          font-weight: 700;
          line-height: 14px;
          color: ${props => props.config?.themeLayout?.link};
          text-align: right;
        }
      }
    }
  }

`;