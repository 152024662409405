import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { BREAKPOINTS } from "../configs/consts";
import Cookies from "js-cookie";
import TextSimple from "../Atoms/TextSimple";
import ButtonSecondary from "../Atoms/ButtonSecondary";

const PrivacyCookiesPolicy = (props) => {
  const { config } = props;
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const cookieConsent = Cookies.get("cookieConsent");
    if (!cookieConsent) {
      setIsVisible(true);
    }
    if (cookieConsent === "declined") {
      clearCookies();
    }
  }, []);

  useEffect(() => {
    if(props?.history?.location?.pathname === '/politica-de-cookies') {
      setIsVisible(false);
    }
  }, [props?.history?.location?.pathname]);

  const handleAccept = () => {
    Cookies.set("cookieConsent", "accepted", { expires: 365 });
    setIsVisible(false);
  };

  const handleDecline = () => {
    Cookies.set("cookieConsent", "declined", { expires: 365 });
    clearCookies();
    setIsVisible(false);
  };

  const clearCookies = () => {
    setTimeout(() => {
      const allCookies = Cookies.get();
      Object.keys(allCookies).forEach((cookie) => {
        if (cookie !== "cookieConsent") {
          Cookies.remove(cookie);
        }
      });
    }, 300);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <StyledContainer config={config}>
      <section className="left-section">
        <TextSimple typo="subtitle-xl" align="left" className="left-section-Title">
          Preferências de Cookies
        </TextSimple>
        <p className="left-section" style={{marginTop: 6}}>
          Usamos cookies para garantir que você tenha a melhor experiência no
          nosso site. Acesse nossa{" "}
          <a
            data-testid='link'
            href='/politica-de-cookies'
            target="_blank"
            rel="noopener noreferrer"
            style={{fontWeight: 'bold', color: '#0759ea',textDecoration: 'underline'}}
          >
            Política de Cookies
          </a>{" "}
          para mais informações.
        </p>
      </section>
      <section className="right-section">
        <ButtonSecondary
          config={config}
          className="left-button"
          onClick={handleAccept}
          small
          testId='not'
        >
          ACEITO
        </ButtonSecondary>
     
        <ButtonSecondary
          config={config}
          onClick={handleDecline}
          className="right-button"
          small
          size='150px'
          testId='yes'
        >
          NÃO ACEITO
        </ButtonSecondary>
      </section>
    </StyledContainer>
  );
};

export default PrivacyCookiesPolicy;

// styles

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 870px;
  padding: 16px;
  margin-bottom: 25px;
  border-radius: 16px;
  z-index: 99999;
  background-color: ${(props) => props.config.colors.LIGHT.A};
  box-shadow: 2px 2px 2px #4b4b4d;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  

  @media (max-width: ${BREAKPOINTS.PHONE}px) {
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding: 16px 16px 0px 16px;
    margin-bottom: 0px;
    border-radius: 0;
    width: 100%;
  }

  .left-section {
    padding-right: 24px;
    color: #000;

    @media (max-width: ${BREAKPOINTS.PHONE}px) {
      padding-right: 0;
    }

    .left-section-Title {
      font-weight: 700;
      font-size: 22px;
      color: #0759ea;
    }

    .left-section-message {
      font-family: Sarabun;
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;

      margin-top: 8px;
      color: #0759ea;

      a {
        color: #0759ea;
        font-weight: 700;
        text-decoration: underline;
      }

      @media (max-width: ${BREAKPOINTS.PHONE}px) {
        font-size: 12px;
        line-height: 18px;
      }
    }
  }

  .right-section {
    display: flex;
    align-items: center;
    gap: 16px;
    
    @media (max-width: ${BREAKPOINTS.PHONE}px) {
      width: 100%;
      margin: 24px 0;
      justify-content: center;
    }

    .interact-button {
      background-color: ${(props) => props.config.colors.LIGHT.B};
      color: #0759ea;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 48px;
    }

    .right-button {
      background-color: #f3f7e0;
      border: 1px solid #88c929;
      color: #88c929;
    }

    .left-button {
      background-color: #88c929;
      border: 1px solid #88c929;
      color: ${(props) => props.config.colors.LIGHT.A};
    }

    .interact-button-text {
      padding: 16px;
      white-space: nowrap;
    }
  }
`;
