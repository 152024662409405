import styled from "@emotion/styled";

const SwitchAccount = (props) => {
  let { popup, data, userHelper, config } = props;

  const doSwitchAccount = async (e, firebaseId) => {
    e.preventDefault();

    popup.setIsLoading(true);

    const { acg_Status__c } = await props.dataHelper.getUserInfoBeforeLogin(firebaseId) || {};

    if (["Inativo", "Blocked"].includes(acg_Status__c)) {
      props.message.add({ type: 'error', message: config.strings.form.message.USER_IS_INACTIVE_OR_BLOCKED })
      popup.setIsLoading(false);
      return;
    }

    await userHelper.switchUserGroupLogin({
      mainFirebaseId: data.user.groupData.acg_MainUser__r.FirebaseId__c,
      fromFirebaseId: data.user.FirebaseId__c,
      toFirebaseId: firebaseId,
    })
  }

  if (data?.user?.groupData?.Contacts__r?.records?.length) {
    popup.setContent(<StyledSwitchAccount>
      <h3>Trocar Conta</h3>
      {data.user.groupData.Contacts__r.records.map(contact =>
        contact.FirebaseId__c === data.user.FirebaseId__c ?
          <div key={contact.Id} className={'profile-holder active'}>
            <p>{contact.LoyaltyCategory__c}</p>
            <p className={'sub'}>Ativo</p>
          </div>
          :
          <div key={contact.Id} className={'profile-holder link'} onClick={(e) => doSwitchAccount(e, contact.FirebaseId__c)}>
            <p>{contact.LoyaltyCategory__c}</p>
          </div>
      )}
    </StyledSwitchAccount>)

    popup.setIsOpen(true)
  }
}

export default SwitchAccount;

const StyledSwitchAccount = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: ${props => props.config?.themeLayout?.text};
  background: ${props => props.config?.themeLayout?.background};
  max-width: 500px;
  padding-bottom: 10px;
  
  h3{
    width: 100%;
    margin: 0;
    padding: 15px 20px;
    border-bottom: 1px solid #eee;
    margin-bottom: 15px;
    text-align: left;
    color: rgb(33, 65, 124);
  }

  .profile-holder{
    width: 100%;
    padding: 10px 50px;

    &:not(.active):hover{
      background-color: #EEE;
    }

    &.link{
      cursor: pointer;

      p{
        display: flex;
        width: 100%;
        color: #333;
        font-weight: bold;
      }
    }

    p{
      color: #888;
      text-align: left;

      &.sub{
        text-align: left;
        font-size: 0.8em;
        margin-top: -2px;
      }
    }
  }
`