import React from "react";

import CampaignsHeaderModal from '../CampaignsHeaderModal';
import CampaignsImageBackground from "../CampaignsImageBackground";

import ResultItem from "./components/ResultItem";

import NoResultsFound from "../../Molecules/NoResultsFound";

const CampaignsResultsDetail = (props) => {
  const { config, setCloseModal, campaign, results, setCloseSubModal, user, rightPanel, title } = props;

  return (
    <div className="mainContent">
      <CampaignsHeaderModal {...props} setCloseModal={setCloseModal} setCloseSubModal={setCloseSubModal} rightPanel={rightPanel} title={'Ganhadores'}/>
      <div className="mainSection">
        <CampaignsImageBackground config={config} name={campaign.name} link={campaign.link} img={campaign.image} />
        <div className="modalTitle">
        </div>
        <div className="resultItemContainer">
          {
            results?.winners?.lenght === 0 ? 
            results?.winners?.map((item, index) => (
              <ResultItem {...item} key={index} user={user}/>
            )) : <div className="no-results" style={{marginTop: '10px', marginBottom: '10px'}}>
            <NoResultsFound
                config={config}
                icon='fa-trophy'
                title="Os ganhadores serão divulgados na data 02/07/2024"
                message=" "
            />
        </div>
          }
        </div>
      </div>
    </div>
  )
}



export default CampaignsResultsDetail;
