import styled from "@emotion/styled";
import LoadingIcon from "../Atoms/LoadingIcon";

const Loader = (props) => {
  return <StyledLoader>
    <LoadingIcon />
  </StyledLoader>;
}

export default Loader;

/* style */

const StyledLoader = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  background: #def;
`