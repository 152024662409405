import { collection, doc, addDoc, setDoc, getDoc, serverTimestamp } from 'firebase/firestore';
import { POINT_ACTIONS, COLLECTIONS_DOCS_ACTIONS, } from './consts'
import { HEROKU_REDEEM_URL } from './URLconsts';

const GameficationHelper = {
  getQueue: function(action) {
    switch (action) {
      case POINT_ACTIONS.EVENT_CLICK_NEWS:
      case POINT_ACTIONS.EVENT_STAR_NEWS:
      case POINT_ACTIONS.EVENT_SHARE_NEWS:

      case POINT_ACTIONS.EVENT_CLICK_GALLERY:
      case POINT_ACTIONS.EVENT_STAR_GALLERY:
      case POINT_ACTIONS.EVENT_SHARE_GALLERY:

      case POINT_ACTIONS.EVENT_SHARE_WARCRY:
      case POINT_ACTIONS.EVENT_STAR_WARCRY:

      case POINT_ACTIONS.EVENT_SEE_ADS:
      case POINT_ACTIONS.EVENT_CLICK_ADS:
        return COLLECTIONS_DOCS_ACTIONS.NEWS;

      case POINT_ACTIONS.DAILY_LOGIN:
      case POINT_ACTIONS.ACTION_UPDATE_PROFILE:
      case POINT_ACTIONS.WELCOME_ACTION:
        return COLLECTIONS_DOCS_ACTIONS.LOGIN;

      case POINT_ACTIONS.ACTION_VOTE_MAN_OF_THE_MATCH:
      case POINT_ACTIONS.ACTION_VOTE_RATE:
      case POINT_ACTIONS.ACTION_VOTE_SUBSTITUTION_SUGGESTION:
        return COLLECTIONS_DOCS_ACTIONS.GAME_DAY;


      case POINT_ACTIONS.ACTION_VOTE_SURVEY_ANSWER:
      case POINT_ACTIONS.ACTION_VOTE_SURVEY_COMPLETE:
        return COLLECTIONS_DOCS_ACTIONS.SURVEY;

      default:
        return this.checkSurveyQueue(action);
    }
  },

  checkSurveyQueue: function(action) {
    if (action.startsWith(POINT_ACTIONS.ACTION_VOTE_SURVEY_ANSWER)
    || action.startsWith(POINT_ACTIONS.ACTION_VOTE_SURVEY_COMPLETE))
      return COLLECTIONS_DOCS_ACTIONS.SURVEY;

      return 'Other'
  },

  addRow: async function (
    userDataHelper,
    cgny2__Action__c = null,
    cgny2__GenericId__c = null,
    cgny2__Event__c = null,
    cgny2__Value__c = null,
    cgny2__AdvertisingItem__c = null,
    cgny2__Gallery__c = null,
    cgny2__News__c = null,
    cgny2__Survey__c = null,
    cgny2__SurveyQuestionOption__c = null,
    cgny2__SurveyQuestion__c = null,
    cgny2__Voucher__c = null,
    appUser,
    recordType = null,
    cgny2__NumberValue__c = null,
    cgny2__StartUpMessage__c = null,
  ) {
    let obj = {}
    let actionDoc = '';
    
    let userData = await userDataHelper.getUser();

    if (!userData) {
      console.warn('!userData');
      return;
    }

    let firebaseId = null;
    if (appUser) {
      firebaseId = appUser["FirebaseId__c"]
    } else {
      firebaseId = await userDataHelper.getUserFirebaseId();
    }

    try {
      obj = {
        ContactAction: {
          cgny2__Action__c,
          cgny2__GenericId__c,
          'cgny2__Contact__r.cgny2__FirebaseId__c': firebaseId,
          cgny2__ActionDate__c: new Date().toISOString(),
          cgny2__Value__c,
          cgny2__AdvertisingItem__c,
          cgny2__News__c,
          cgny2__Survey__c,
          cgny2__SurveyQuestionOption__c,
          cgny2__SurveyQuestion__c,
          cgny2__Voucher__c,
          cgny2__NumberValue__c, 
          RecordTypeId: recordType,
          cgny2__StartUpMessage__c
        },
        ActionDate: serverTimestamp(),
      };

      actionDoc = this.getQueue(cgny2__Action__c);
      // let ref = collection(doc(collection(userDataHelper.db,'ContactActions'),actionDoc),'Pending');
      // check if doc exists
      const actionDocRef = doc(userDataHelper.db,'ContactActions',actionDoc);
      const actionDocData = await getDoc(actionDocRef);

      if(!actionDocData.exists()){
        await setDoc(actionDocRef,{});
      }

      let ref = collection(userDataHelper.db,'ContactActions',actionDoc,'Pending');
      return await addDoc(ref,obj);
    }
    catch (e) {
      console?.log('gameficationHelper.addRow ' + actionDoc, { obj, e })
    }
    return null;
  },

  rewardRedeem: async function(
    userHelper,
    productItemId, 
    quantity,
    milestoneProductId
  ){
    
    try {
      const userData = await userHelper.getUser();

      let redeemData = {
				contactId: userData.CurrentContactSeason__r.Contact__c,
				productItemId: productItemId,
				quantity: quantity,
				milestoneProductId: milestoneProductId
			}
      
      try {
        return await userHelper?.dataHelper?.api.post(userHelper?.dataHelper?.getHerokuUrl(HEROKU_REDEEM_URL), redeemData)
      } catch (error) {
        console.log('rewardRedeemException1', error);
      }
		}
		catch (error) {
			console?.log('rewardRedeemException2', error);
		}

    return false;
  },
}

export default GameficationHelper;