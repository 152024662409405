
const COLORS = {
  LIGHT: {
    A: '#FFF',
    B: '#EEE',
    C: '#DDD',
    D: '#CCC',
    BG: {
      A: '#FFF',
      B: '#FD0',
      C: '#EEE',
      D: 'url("https://setecnet.com.br/home/wp-content/uploads/2018/02/betfair-pattern.png") bottom #FFF',
      E: '#f3f7e0',
    }
  },
  DARK: {
    // A: '#21417C',
    A: '#0759ea',
    B: '#8BA412',
    C: '#4d80af',
    D: '#77b9a3',
    E: '#888',
    F: '#ABC432',
    G: '#444',
    H: '#70593F',
    SUCCESS: '#54BF33',
    WARNING: '#FAB416',
    ERROR: '#E63B3B',
    INFO: '#17A2B8',
    BG: {
      A: '#555',
      // B: '#ABC432',
      B: '#88c929',
      C: '#777',
      // D: '#1a4299',
      D: '#0759ea',
      E: '#000',
      F: 'rgba(0,0,0,0.8)',
      G: '#122444',
      H: '#21417C',
      I: '#5e6732',
      J: '#333'
    }
  },
  MISC: {
    TRANSPARENT: 'transparent',
    IMAGE_BG: 'url("data:image/webp;base64,UklGRmwAAABXRUJQVlA4WAoAAAAQAAAABAAABAAAQUxQSBYAAAABDzAhERFCadtIbAwea/+oRfQ/GvwBVlA4IDAAAACQAQCdASoFAAUAAgA0JaQAApplHeAA/tPpPS2F/PjfDX7Mr9vZ2Xy98v/pAAAAAAA=")',

  }
}

export default COLORS;