import React, { useMemo, useState, useEffect } from "react";
import moment from "moment";

import ToolHelper from "../utils/ToolHelper";

import CampaignsHeaderSection from "./CampaignsHeaderSection";
import CampaignsFilterSection from "./CampaignsFilterSection";
import CampaignsResults from "./Results/CampaignsResults";
import CampaignsRegulation from "./Regulation/CampaignsRegulation";
import CampaignsRegulationPdf from "./Regulation/CampaignsRegulationPdf";
import CampaignsMyNumbers from "./MyNumbers/CampaignsMyNumbers";
import NoResultsFound from '../Molecules/NoResultsFound';

import Card from "./components/Card";

import LoadingIcon from "../Atoms/LoadingIcon";

import {StyledCampaigns} from './styled';

const labels = {
  'Ganhadores': 'RESULTS', 
  'Ganhadores': 'WINNERS',
  'Regulamento': 'REGULATION', 
  'Meus números': 'MY NUMBERS', 
  'Meus números': 'NUMBERS',
  'Participar': 'ENROLLMENT'
}

const Campaigns = (props) => {
  const { openTab = '' } = props;

  const config = useMemo(() => ToolHelper.getThemedConfig(props, 'Campaigns.preset'), [props.config]);

  const [ loading, setLoading ] = useState(false);
  const [ campaigns, setCampaigns] = useState([]);
  const [ currentCampaigns, setCurrentCampaigns] = useState([]);
  const [ closedCampaigns, setClosedCampaigns] = useState([]);
  const [ actualCampaign, setActualCampaign ] = useState({});

  const [ currentCategory, setCurrentCategory ] = useState('Vigentes');
  const [ filters, setFilters ] = useState({});
  const [ filterValues, setFilterValues ] = useState([]);

  const [ openCampaignsResults, setOpenCampaignsResults ] = useState(false);
  const [ openCampaignsRegulation, setOpenCampaignsRegulation ] = useState(false);
  const [ enrolled, setEnrolled ] = useState('');
  const [ openCampaignsMyNumbers, setOpenCampaignsMyNumbers ] = useState(false);
  
  const [ actualModal, setActualModal ] = useState("");
  const [ closeSubModal, setCloseSubModal ] = useState(false);
  const [ modalRegulation, setModalRegulation ] = useState(false);

  const [ openFilter, setOpenFilter ] = useState(false);
  const [ disabled, setDisabled ] = useState("");

  const [ birthdayCampaign, setBirthdayCampaign ] = useState({});

  const [ knowMore, setKnowMore ] = useState([]);

  const changeCategory = (category) => {
    if(category !== currentCategory){
      setCurrentCategory(category);
    }
  }    
  
  const handleButtonClick = (e, nameBtn) => {
    setDisabled(nameBtn)
    let v = e.target.attributes.getNamedItem('data-value')
    if (v) {
      setActualCampaign(JSON.parse(v.value))
    }
    let cp = JSON.parse(v.value)
    setActualModal(labels[e.target.innerText])

    if (cp.raffleParticipant && e.target.innerText === 'Regulamento') {
      setModalRegulation(true)
    } else if (!cp.raffleParticipant && e.target.innerText === 'Regulamento') {
      const now = moment();
      if (moment(cp.endDate) < now || !cp.type) {
        setModalRegulation(true)
      } else {
        openModal(labels[e.target.innerText])
      }
    } else {
      openModal(labels[e.target.innerText])
    }

    setTimeout(() => {
      setDisabled("")
    }, 2000);
  }

  const setCloseModal = (e) => {
    switch (actualModal) {
      case "RESULTS":
      case "WINNERS":
        setOpenCampaignsResults(false)
        break;
      case "REGULATION":
      case "ENROLLMENT":
        setOpenCampaignsRegulation(false)
        break;
      case "MY NUMBERS":
      case "NUMBERS":
        setOpenCampaignsMyNumbers(false)
        break;
      default:
        break;
    }
  }

  const openModal = (key) => {
    switch (key) {
      case "RESULTS":
      case "WINNERS":
        setOpenCampaignsResults(true)
        break;
      case "REGULATION":
      case "ENROLLMENT":
        setOpenCampaignsRegulation(true)
        break;
      case "MY NUMBERS":
      case "MYNUMBER":
      case "NUMBERS":
        setOpenCampaignsMyNumbers(true)
        break;
      default:
        break;
    }
  }

  const getCampaignKnowMore = async () => {
    const arrayObj = []
    try {
      const textValue = await props.dataHelper.userHelper.getCampaignKnowMore()
      const aux = textValue.split(';')
      if (aux.length) {
        for(let i in aux) {
          try {
            arrayObj.push(JSON.parse(JSON.stringify(aux[i])))
          } catch (error) {
            console.log('convert in array error', error)
          }
        }
        setKnowMore(arrayObj)
      }
    } catch (error) {
      
    }
  }
  const getCampaigns = async () => {
    const now = moment();
    const result = await props.dataHelper.getCampaigns(props?.data?.user?.FirebaseId__c)

    if (result) {

      let arr = []
      const firstYear = 2022
      let year = moment(new Date()).format("YYYY")
      while (year >= firstYear) { 
        arr.push(year.toString())
        year = year - 1
      }

      arr = [
        ...arr, 
        ...result
          .map((r) => moment(r.endDate).format("YYYY"))
          .reduce((a, b) => {
            if (a.indexOf(b) < 0) a.push(b)
            return a
          }, [])
      ]

      arr = [...new Set(arr)]
      setFilterValues([
        ...arr.sort((a,b) => a - b).map((item) => {
          return {
            value: item, label: item
          }
        })
      ])
     
      setCampaigns(result.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate)))
      setCurrentCampaigns(result?.filter((c) => moment(c.endDate) >= now).sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate)))
      setClosedCampaigns(result?.filter((c) => moment(c.endDate) < now).sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate)))
    }

    setLoading(false)
    if (openTab !== '') {
      let campaignBirthday = result?.filter((c) => c.name === 'Aniversário Acessa Agro - 5 anos')[0]
      setBirthdayCampaign(campaignBirthday)
      setActualModal(openTab)
      setTimeout(() => {
        openTab === 'REGULATION' && campaignBirthday.raffleParticipant ? setModalRegulation(true) : openModal(openTab)
      }, 1000)      
    }
  }

  useEffect(() => {
    setLoading(true)
    getCampaigns()
    getCampaignKnowMore()
    setLoading(false)
  }, [enrolled])

  useEffect(() => {
    if(Object.keys(filters).length > 0 && filters.ciclo !== '' && currentCampaigns?.length > 0) {
      setCurrentCampaigns(currentCampaigns?.filter((c) => moment(c.startDate).format("YYYY") === filters.ciclo || moment(c.endDate).format("YYYY") === filters.ciclo).sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate)))
      setClosedCampaigns(closedCampaigns?.filter((c) => moment(c.startDate).format("YYYY") === filters.ciclo || moment(c.endDate).format("YYYY") === filters.ciclo).sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate)))
    } else {
      if(campaigns?.length > 0) {
        const now = moment()
        setCampaigns(campaigns)
        setCurrentCampaigns(campaigns?.filter((c) => moment(c.endDate) >= now).sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate)))
        setClosedCampaigns(campaigns?.filter((c) => moment(c.endDate) < now).sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate)))
      }
    }
  }, [filters])

  return (
    loading ? <LoadingIcon config={config} /> :
    <StyledCampaigns data-testid='drawer-campanha' config={config}>
      <CampaignsHeaderSection config={config} openFilter={openFilter} setOpenFilter={setOpenFilter} currentCategory={currentCategory} changeCategory={changeCategory} />
      <CampaignsFilterSection 
        {...props} 
        openFilter={openFilter} 
        setOpenFilter={setOpenFilter}
        filters={filters}
        setFilters={setFilters}
        currentCampaigns={currentCampaigns}
        closedCampaigns={closedCampaigns}
        filterValues={filterValues}
      />
      {currentCategory === 'Vigentes' ? (
        currentCampaigns.length > 0 ? currentCampaigns?.map((item, index) => (
          <Card {... props} handleButtonClick={handleButtonClick} disabled={disabled} item={item} key={`${index}_${item.campaignId}`} campaignKnowMore={knowMore} />
        )) : <div style={{ marginTop: "30px"}} ><NoResultsFound config={config} message={'Não há campanha(s) vigente(s) para este perfil.'}/></div>
      ) : (
        closedCampaigns.length > 0 ? closedCampaigns.map((item, index) => (
          <Card {... props} handleButtonClick={handleButtonClick} disabled={disabled} item={item} key={`${index}_${item.campaignId}`} campaignKnowMore={knowMore} />
        )) : <div style={{ marginTop: "30px"}} ><NoResultsFound config={config} message={'Não há informações de campanha(s) encerrada(s) para este perfil.'}/></div>
      )}

      <div><p>&nbsp;</p></div>

      { openCampaignsResults &&
        <CampaignsResults {...props} setCloseModal={setCloseModal} campaign={openTab ? birthdayCampaign : actualCampaign} setCloseSubModal={setCloseSubModal} closeSubModal={closeSubModal} rightPanel={props.rightPanel} />
     }

      { openCampaignsRegulation && 
        <CampaignsRegulation {...props} setCloseModal={setCloseModal} campaign={openTab ? birthdayCampaign : actualCampaign} modalRegulation={modalRegulation} setModalRegulation={setModalRegulation} setEnrolled={setEnrolled} currentCategory={currentCategory} rightPanel={props.rightPanel} />
      }

      { openCampaignsMyNumbers && 
        <CampaignsMyNumbers {...props} setCloseModal={setCloseModal} campaign={openTab ? birthdayCampaign : actualCampaign} setCloseSubModal={setCloseSubModal} closeSubModal={closeSubModal} rightPanel={props.rightPanel} />
      }

      { modalRegulation &&
        <CampaignsRegulationPdf config={config} setModalRegulation={setModalRegulation} campaign={openTab ? birthdayCampaign : actualCampaign} />
      }
    </StyledCampaigns>
  )
}

export default Campaigns;
