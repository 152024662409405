import { collection, getDoc, getDocs, doc, query, where, onSnapshot, addDoc, setDoc, serverTimestamp, orderBy, limit, startAfter, writeBatch, updateDoc } from 'firebase/firestore';
import { getAuth, GoogleAuthProvider, FacebookAuthProvider, EmailAuthProvider, signInWithPopup, signOut, sendPasswordResetEmail, fetchSignInMethodsForEmail, reauthenticateWithCredential, updatePassword, deleteUser, signInWithCustomToken } from 'firebase/auth';
import { getMessaging, getToken } from 'firebase/messaging';
import moment from 'moment';

import GameficationHelper from './GameficationHelper';
import { POINT_ACTIONS, FAKE_DOMAIN } from './consts';
import { HEROKU_LOYALTY_URL, HEROKU_CASE_URL, HEROKU_INVOICE_URL, HEROKU_INVOICE_REANALYSIS_REQUEST_URL, HEROKU_INVOICE_DELETE_URL, HEROKU_ACCEPT_AGREEMENT, HEROKU_GET_VERIFY_CONTACTS_BY_ACCOUNT } from './URLconsts';
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import FormHelper from './FormHelper';
import ToolHelper from './ToolHelper';

export default class UserHelper {
  constructor(app, db, getConfigFile) {
    this.app = app;
    this.db = db;

    getConfigFile(`utils/UserHelper/config.js`).then((importedFile) => {
      let customCfgClass = importedFile?.default;

      if (customCfgClass && typeof customCfgClass === 'function') {
        this.customCfg = new customCfgClass();
      }
    });
    global.dbForCEP = this.db;
  }

  setDataHelper(dataHelper) {
    this.dataHelper = dataHelper;
  }

  async getHerokuUsernameDoc(username, include_userfbid = 1) {
    if (!username) {
      return { success: false };
    }

    let data = {
      username,
      include_userfbid,
    }

    try {
      return await this.dataHelper?.api.get(`/getUsername?${new URLSearchParams(data)}`, false)
    }
    catch (e) {
      console.log('getUsername', e)
    }

    return { success: false };
  }

  // Busca no heroku o userfirebasedoc pelo próprio ID ou por e-mail
  async getHerokuUserfirebaseDoc({ userFirebaseId, email }) {
    const userToken = await getAuth().currentUser?.getIdToken()
    if (!userFirebaseId && !email) return { success: false };

    const data = userFirebaseId ? { userFirebaseId } : { email };
    try {
      const response = await this.dataHelper?.api.get(`/getUserFirebaseId?${new URLSearchParams(data)}`, true, { Authorization: userToken })

      if (response?.success) {
        return response;
      }
    }
    catch (e) {
      console.log('getHerokuUserfirebaseDoc', e)
    }

    return { success: false };
  }

  async findUserFirebaseDoc(userData) {
    if (!userData?.username && !userData?.userFirebaseId) return false;

    let { username, userFirebaseId } = userData;

    let foundUserFirebaseId = userFirebaseId;
    const loginType = FormHelper.detectStringContent(username);

    if (loginType === 'cpf' || loginType === 'cnpj') {
      username = username.replace(/[^0-9a-zA-Z]/g, '');
    }
    let oktaData = false;
    if (!userFirebaseId) {
      let usernameDoc = await this.getHerokuUsernameDoc(username)

      usernameDoc = JSON.parse(usernameDoc)
      if (usernameDoc?.oktaData)
        oktaData = usernameDoc.oktaData;

      if (usernameDoc?.username?.UserFirebaseId) {
        foundUserFirebaseId = usernameDoc.username.UserFirebaseId;
      } else if (usernameDoc?.username?.salesTeamFirebaseId) {
        foundUserFirebaseId = usernameDoc.username.salesTeamFirebaseId;
      }
    }

    if (foundUserFirebaseId) {
      let result = await this.getHerokuUserfirebaseDoc({ userFirebaseId: foundUserFirebaseId })
      if (result.success) {
        return result.userfbid;
      }
    }
    else {
      return { oktaData: oktaData }
    }

    return false;
  }

  async postSignInProcess(userCredential) {

    if (userCredential?.acg_ProgramOptOut__c) {
      await this.logoutAsync();

      return {
        success: false,
        error: {
          code: 'auth/user-disabled',
        }
      }
    }

    return {
      success: true,
      user: userCredential
    };
  }

  async getUserGroup(userData) {
    if (!userData?.FirebaseId__c) {
      return {};
    }

    let userGroupQ = query(collection(this.db, 'acg_UserGroup'), where('acg_MainUser__r.FirebaseId__c', '==', userData.FirebaseId__c));
    let userGroupDocs = await getDocs(userGroupQ);
    let firebaseData = {};

    if (userGroupDocs.size > 0) {
      userGroupDocs.forEach((doc) => {
        firebaseData = doc.data();
      })
    }

    return firebaseData;
  }

  // Dado um grupo de usuário, retorna qual conta deve ser logada
  // Isso pode variar pois o último usuário logado pode estar desabilitado, neste caso é necessário escolher outro para fazer o login e só quem pode fazer essa decisão é o heroku.
  async switchToDefaultUser(params) {
    let { mainFirebaseId } = params;
    let accessCode = this.generateRandomAccessCode(10);

    const userRef = await this.getUserRef();

    await setDoc(userRef, {
      switchUserGroupAccessCode: accessCode,
    }, { merge: true });

    const herokuData = {
      mainFirebaseId,
      accessCode,
    }

    let result = {
      success: false,
      error: 'unknown-error',
    }

    try {
      result = await this.dataHelper?.api.post('/switchToDefaultUser', herokuData)

      if (result.success && result.url) {
        document.location.href = result.url;
      }
    }
    catch (e) {
      console.log('switchToDefaultUserUrl', e);
    }

    return result;
  }
  async revokeRefreshToken(userFirebaseId) {
    try {
      let result = await this.getHerokuUserfirebaseDoc({ userFirebaseId: userFirebaseId })
      if (result?.success) {
        await this.revokeTokenUser({ uid: result?.userfbid?.UID, userFirebaseId: result?.userfbid?.UserFirebaseId })
        return true;
      }

      return false;

    }
    catch (e) {
      return await this.deniedFirestoreRequest(e);
    }

  }

  async loginUserGroup(userGroup, userData) {
    // É necessário trocar o login se o MainUser não foi o último logado, ou se o MainUser está desabilitado
    if (
      (userGroup?.lastLoggedFirebaseId && userGroup?.lastLoggedFirebaseId !== userData.FirebaseId__c) ||
      userData?.acg_ProgramOptOut__c
    ) {
      return await this.switchToDefaultUser({
        mainFirebaseId: userGroup.acg_MainUser__r.FirebaseId__c,
      })
    }

    return {
      success: true,
    };
  }

  async switchUserGroupLogin(params) {
    let { mainFirebaseId, fromFirebaseId, toFirebaseId } = params;

    let accessCode = this.generateRandomAccessCode(10);

    const userRef = await this.getUserRef();

    await setDoc(userRef, {
      switchUserGroupAccessCode: accessCode,
    }, { merge: true });

    const herokuData = {
      mainFirebaseId,
      fromFirebaseId,
      toFirebaseId,
      accessCode,
    }

    let result = {
      success: false,
      error: 'unknown-error',
    }

    try {
      result = await this.dataHelper?.api.post('/switchUserGroupLogin', herokuData)
      if (result.success && result.url) {
        document.location.href = result.url;
      }
    }
    catch (e) {
      console.log('sendSwitchUserGroup', e);
    }

    return result;
  }

  async userLogin(username, password, isFakeDomainMigrationEnabled = false) {
    const auth = getAuth();
    try {
      // Busca token no heroku
      let tokenResponse = { success: false, error: 'unknown-error' };

      try {
        tokenResponse = await this.dataHelper?.api.post('/userLogin', { username, password, locale: "acessaAgro" })
      } catch (error) {
        return {
          success: false,
          error: error,
        }
      }
      if (!tokenResponse?.success || !tokenResponse?.token?.length || tokenResponse?.redirect) {
        return tokenResponse;
      }

      global.needsUpdatePassword  =  "user-needs-password-definition";

      await signInWithCustomToken(auth, tokenResponse?.token);
      const response = await this.postSignInProcess(tokenResponse?.user);
      global.user = tokenResponse?.user;
      return {
        ...response,
        userFirebaseId: tokenResponse?.UserFirebaseId,
        code: tokenResponse.code
      };
    } catch (error) {
      console.log('signInWithCustomToken -->', error)
      return {
        success: false,
        error: error
      }
    }

  }

  async userLoginWithToken(token) {
    await this.logoutAsync();
    const auth = getAuth();

    try {
      let userCredential = await signInWithCustomToken(auth, token);
      return await this.postSignInProcess(userCredential);
    }
    catch (error) {
      return {
        success: false,
        error,
      }
    }
  }

  async userLoginByFirebaseId(userFirebaseId, password) {
    let result = await this.getHerokuUserfirebaseDoc({ userFirebaseId });

    if (result.success && result.userfbid.Email) {
      return this.userLogin(result.userfbid.Email, password);
    }

    return {
      success: false,
      error: {
        code: 'auth/user-not-found'
      }
    }
  }

  getAuthUser() {
    const auth = getAuth();
    return auth?.currentUser;
  }

  async getUserFirebaseId() {
    if (!this.dataHelper.api) return

    let user = this.getAuthUser();

    if (!user)
      return null;

    if (!global.userFirebaseId) {
      const userEmail = user?.email || user?.providerData[0]?.email || null;

      if (!userEmail) {
        return null;
      }

      let userData = await this.getUserDataByEmail(userEmail);

      if (userData?.FirebaseId__c) {
        global.userFirebaseId = userData.FirebaseId__c;
        return global.userFirebaseId;
      }

      return null;
    } else {
      return global.userFirebaseId;
    }
  }

  async getUserDataByEmail(email) {
    if (email?.length) {
      email = email.toLowerCase();
    }

    let userFirebaseId;
    let userData;

    if (global.userFirebaseId) {
      userFirebaseId = global.userFirebaseId;
    }
    else {
      let ufbresult = await this.getHerokuUserfirebaseDoc({ email });

      if (ufbresult?.userfbid) {
        if (ufbresult.userfbid.UserFirebaseId) {
          global.userFirebaseId = userFirebaseId = ufbresult.userfbid.UserFirebaseId
        }
      }
    }

    if (userFirebaseId) {
      try {
        let userRef = doc(this.db, 'Users', userFirebaseId);
        userData = (await getDoc(userRef)).data();

      }
      catch (e) {
        return await this.deniedFirestoreRequest(e);
      }
    }

    return userData;
  }

  async deniedFirestoreRequest(e) {
    if (e?.code === 'permission-denied') {
      await this.logoutAsync()
      return false;
    }

    return false;
  }

  async getUserRef() {
    let userFirebaseId = await this.getUserFirebaseId();
    if (!userFirebaseId) return null;

    return doc(this.db, 'Users', userFirebaseId);
  }

  async getUserFirebaseIdRef() {
    let userFirebaseId = await this.getUserFirebaseId();
    if (!userFirebaseId) return null;

    return doc(this.db, 'UserFirebaseId', userFirebaseId);
  }

  async getUser() {
    let userRef = await this.getUserRef();

    if (userRef) {
      try {
        let userDoc = await getDoc(userRef);
        // validação parcial
        if (userDoc.exists()) {
          const userDocData = userDoc.data();
          const userData = {
            ...userDocData,
            CNPJ_CPF__c: userDocData?.CNPJ_CPF__c ? userDocData?.CNPJ_CPF__c : userDocData['CNPJ_CPF__c '],
            EmailIsReal: !!(userDocData.Email?.length) || false
          }
          return userData;
        }
      }
      catch (e) {
        return await this.deniedFirestoreRequest(e);
      }
    }

    return null;
  }

  async snapUser(callback) {
    let userRef = await this.getUserRef();

    if (userRef) {
      let unsub = onSnapshot(userRef, async (snapshot) => {
        if (snapshot?.id) {
          let userData = snapshot.data()
          let groupData;

          // Buscando grupo
          if (userData?.acg_UserGroup__c) {
            let groupId = userData.acg_UserGroup__c.slice(0, 15);
            let groupDoc = await getDoc(doc(this.db, 'acg_UserGroup', groupId));

            if (groupDoc.exists()) {
              groupData = groupDoc.data();
            }
          }

          callback({
            ...userData,
            UserFirebaseId: snapshot.id,
            groupData,
          });
        }
        else {
          callback({})
        }
      })

      let t = {};
      t['user' + new Date().getTime()] = unsub;

      return t;
    }

    return null;
  }

  // userType é o que possivelmente redefine as configurações e layouts do usuário. 
  // Chama customCfg pois isso pode variar de projeto para projeto.
  async setUserType(user) {
    let hasSetUserType = false;

    if (this.customCfg?.setUserType) {
      let result = await this.customCfg.setUserType(user, { userHelper: this });

      if (result.userType) {
        global.userType = result.userType;
        hasSetUserType = true;
      }

      if (result.userTypes) {
        global.userTypes = result.userTypes;
      }

      if (result.user) {
        user = result.user;
      }
    }

    if (!hasSetUserType) {
      global.userType = user.MilestoneCategory__c;
      global.userTypes = [user.MilestoneCategory__c];
    }
  }

  getMessaging() {
    if (!this.messaging)
      this.messaging = getMessaging(this.app);

    return this.messaging;
  }

  async getNotificationTopics() {
    let topicsQ = query(collection(this.db, 'NotificationTopic'), orderBy('Order__c'));
    let topicsDoc = await getDocs(topicsQ);

    let data = [];

    if (topicsDoc.docs.length) {
      data = topicsDoc.docs.every((doc) => {
        let docData = doc.data();

        if (docData.FirebaseId__c !== null && docData.FirebaseId__c !== '') {
          data.push(docData);
        }

        return true;
      })
    }

    return data;
  }

  getMessagingToken(callback) {
    console.log('getMessagingToken')

    this.getMessaging();

    getToken(this.messaging, { vapidKey: 'BKAR7vdFOwdG7QEzni0nFTAwFg2W03FfPYqyRhGGX1gVOhandpZ6gbrupH5ZOF0kmMSD9ExI3LledLgPmFQsPUE' }).then(async (currentToken) => {
      const userFirebaseId = await this.getUserFirebaseId();

      let ref = collection(this.db, 'Users', userFirebaseId, 'NotificationTokens');

      await addDoc(ref, {
        messagingToken: currentToken,
        messagingTokenTimestamp: serverTimestamp()
      });

      // let allTopics = await this.getNotificationTopics();
      // TODO - O registro de token em um tópico não pode ser feito pelo firebase web. É necessário criarmos um acesso no Heroku para encaminhar esta requisição.

      callback({ currentToken });
    }).catch((err) => {
      callback({ err });
    });
  }

  async authEmailExists(email) {
    const auth = getAuth();
    const result = await fetchSignInMethodsForEmail(auth, email)

    return result.length > 0;
  }

  async firestoreEmailExists(email) {
    return !!(await this.getUserDataByEmail(email))
  }

  async cpfExists(cpf) {
    let cleanCpf = cpf.replace(/\D/g, '');

    let user = await this.getUser();
    if (user?.CNPJ_CPF__c && user.CNPJ_CPF__c === cleanCpf) {
      return false;
    }

    let cpfQ = doc(this.db, 'CPF', cleanCpf);
    let cpfDoc = await getDoc(cpfQ);

    return cpfDoc.exists();
  }

  handlePromoCode = async (promoCode) => {
    let promoCodeQ = query(collection(this.db, 'Users'), where('ReferralCode__c', '==', promoCode));
    let promoCodeDocs = await getDocs(promoCodeQ)

    if (promoCodeDocs.size > 0) {
      promoCodeDocs.forEach((doc) => {
        this.redeemGenerator = doc.data();
      })

      return true;
    }

    return false;
  }

  acceptAllTerms = async (onlyPendingTerms = false) => {
    let user = await this.getUser();

    if (!user) {
      return false;
    }

    let terms = [];

    terms = onlyPendingTerms ? await this.getUserPendingTerms(user) : await this.getUserTermVersions(user)

    if (!terms?.length) return false;

    for (let i in terms) {
      await this.acceptAgreementV2(terms[i].Id);
    }

    return true;
  }

  editUserData = async (userData, userCredential, callback) => {
    delete userData.referralCodeSender;

    let userUID;
    if (userCredential?.uid || userCredential?.UID) {
      userUID = userCredential.uid || userCredential?.UID;
    }

    userData.MilestoneCategory__c = 'Parceiro';
    userData.UID = userUID;

    await this.setUserData(userData)

    // Criando registros de loyalty
    try {
      await this.updateLoyalty(callback);
    } catch (e) {
      console.log('updateLoyalty fail', e);
    }

    if (this.redeemGenerator) {
      // Contact Action para o usuário que compartilhou o código
      await GameficationHelper.addRow(this, POINT_ACTIONS.REFER_SENDER, null, null, null, null, null, null, null, null, null, null, this.redeemGenerator, null, null, null);

      // Contact Action para o usuário que está se cadastrando
      try {
        const response = await this.dataHelper.getRecordTypes();
        if (response.success) {
          await GameficationHelper.addRow(this, POINT_ACTIONS.WELCOME_ACTION, null, null, null, null, null, null, null, null, null, null, null, null, response.recordTypes[0].Id, null);
        }
      } catch (error) {
        return {
          success: false,
          code: 'error-recordType',
        }
      }

    }

    return {
      success: true,
      user: userData,
    }
  }

  saveUserWithConsultantProfile = async (userData) => {
    // Criando código de acesso
    console.log('aooba')
    userData.AccessCodeRequireValidation = true;
    userData.HasAgreedToTerms = false;

    if (this.redeemGenerator) {
      // Contact Action para o usuário que compartilhou o código
      await GameficationHelper.addRow(this, POINT_ACTIONS.REFER_SENDER, null, null, null, null, null, null, null, null, null, null, this.redeemGenerator, null, null, null);
    }
    // Salvando termos
    await this.acceptAllTerms();
    try {
      const response = await this.dataHelper.getRecordTypes();
      if (response.success) {
        await GameficationHelper.addRow(this, POINT_ACTIONS.WELCOME_ACTION, null, null, null, null, null, null, null, null, null, null, null, response.recordTypes[0].Id, null, null);
      }
    } catch (error) {
      return {
        success: false,
        code: 'error-recordType',
      }
    }
    return {
      success: true,
      user: userData,
    }

  }

  insupdateUsernames = async (firebaseId, originalUserData, userData) => {
    let usernameCreateTypes = [];
    if (this.customCfg?.getUsernameCreateTypes) {
      usernameCreateTypes = this.customCfg.getUsernameCreateTypes();
    }

    let cpfcnpjType = FormHelper.detectStringContent(userData.CNPJ_CPF__c)

    if (
      userData.CNPJ_CPF__c &&
      (
        !usernameCreateTypes ||
        (cpfcnpjType === 'cpf' && usernameCreateTypes.includes('cpf')) ||
        (cpfcnpjType === 'cnpj' && usernameCreateTypes.includes('cnpj'))
      )
    ) {
      await this.setUsernameDoc(
        firebaseId,
        ToolHelper.getDigits(originalUserData?.CNPJ_CPF__c),
        ToolHelper.getDigits(userData.CNPJ_CPF__c),
        FormHelper.detectStringContent(userData.CNPJ_CPF__c)
      )
    }

  }

  setUsernameDoc = async (firebaseId, originalUsername, username) => {
    const batch = writeBatch(this.db);

    batch.set(doc(this.db, 'Usernames', username), {
      UserFirebaseId: firebaseId
    })

    if (originalUsername?.length && originalUsername !== username) {
      batch.delete(doc(this.db, 'Usernames', originalUsername))
    }

    await batch.commit();
  }

  // Cria e registra um código de acesso para o usuário atual.
  sendAccessCode = async (UserFirebaseId, cpfCnpj) => {
    if (!UserFirebaseId && !cpfCnpj) return false;

    const herokuData = {
      UserFirebaseId,
      locale: 'acessaAgro',
      cpfCnpj,
    }

    try {
      return await this.dataHelper?.api.post('/generateAccessCode', herokuData)
    }
    catch (e) {
      console.log('sendAccessCodeUrl', e);
    }

    return { error: 'unknown-error' };
  }

  sendOktaAccessCode = async (OktaData) => {
    if (!OktaData) return false;

    const { email, id } = OktaData;
    const herokuData = {
      email: email,
      oktaId: id,
    }

    try {
      return await this.dataHelper?.api.post('/sendCodeOkta', herokuData)
    }
    catch (e) {
      console.log('sendOktaAccessCode', e);
    }

    return { error: 'unknown-error' };
  }
  verifyOktaAccessCode = async (OktaData, passCode) => {
    if (!OktaData) return false;

    const { _links } = OktaData;

    const herokuData = {
      linkFactorIdVerify: _links?.verify?.href,
      passCode: passCode,
    }

    try {
      return await this.dataHelper?.api.post('/verifyPassCodeOkta', herokuData)
    }
    catch (e) {
      console.log('verifyPassCodeOkta', e);
    }

    return { error: 'unknown-error' };
  }

  sendPasswordDefinitionRequire = async (UserFirebaseId) => {
    if (!UserFirebaseId) return false;

    const herokuData = {
      UserFirebaseId,
    }

    try {
      return await this.dataHelper?.api.post('/setPasswordDefinitionRequire', herokuData)
    } catch (error) {
      console.log('setPasswordDefinitionRequire', error)
    }

    return { error: 'unknown-error' }
  }

  async getUserBCDs(accountId) {
    let businessCycleQ = query(
      collection(this.db, 'BusinessCycleDistributors'),
      where('acg_Distributor__c', '==', accountId),
    )

    let businessCycleDoc = await getDocs(businessCycleQ);

    return this.dataHelper.docsToArray(businessCycleDoc);
  }

  // Verifica se algum BCD bate com o termo, retorna boolean
  matchBCDAndATV(bcds, term) {
    let matches = false;

    for (let cur of bcds) {
      if (cur === term) {
        matches = true;
      }
    }

    return matches;
  }

  // Busca todos os termos que se encaixam no perfil do usuário
  async getUserTermVersions(user) {
    let termsQ = query(
      collection(this.db, 'AcceptanceTermVersion')
      , where('acg_IsActive__c', '==', true)
    );
    let termsDoc = await getDocs(termsQ);

    if (!user?.LoyaltyCategory__c)
      user = {
        ...user,
        LoyaltyCategory__c: 'Agricultor'
      }

    let data = [];

    if (termsDoc.docs.length) {
      for (let i in termsDoc.docs) {
        let docData = termsDoc.docs[i].data();
        let isValid = false;

        const today = moment().format('YYYY/MM/DD');

        let termStart = docData?.acg_AcceptanceTerm__r?.acg_StartDate__c;
        let termEnd = docData?.acg_AcceptanceTerm__r?.acg_EndDate__c;

        const startTerms = moment(termStart).format('YYYY/MM/DD');
        const endTerms = moment(termEnd).format('YYYY/MM/DD');

        if (docData?.acg_IsPrivate__c) {
          isValid = true;
        }
        else if (docData?.LoyaltyCategory__c?.includes(user.LoyaltyCategory__c)) {

          if (today >= startTerms && today <= endTerms && user.LoyaltyCategory__c !== 'Time de vendas do canal') {
            isValid = true;
          }

          if (user.LoyaltyCategory__c === 'Time de vendas do canal' && user.AccountId) {
            // Busca BCDs do user
            let bcds = await this.getUserBCDs(user.AccountId)

            const termsId = docData.acg_AcceptanceTerm__r.acg_BusinessCycle__c;
            const bussinesCycleIds = bcds.map(({ acg_BusinessCycle__c }) => acg_BusinessCycle__c);

            if (this.matchBCDAndATV(bussinesCycleIds, termsId)) {
              isValid = true;
            }
          }
        }

        if (isValid) {
          data.push(docData);
        }
      }
    }

    return data;
  }

  // Retorna um array com os Ids dos termos que o usuário já aceitou
  getUserAcceptedTerms = async () => {
    let userRef = await this.getUserRef();
    let userTermsQ = query(
      collection(userRef, 'MemberTerm'),
      where('IsDeleted', '==', false),
    );

    let userTermsDoc = await getDocs(userTermsQ);

    let data = [];

    if (userTermsDoc.docs.length) {
      data = userTermsDoc.docs.map((doc) => {
        const docData = doc.data();

        if (!docData.acg_ProgramDateOptOut__c) {
          return docData.acg_AcceptanceTermVersion__c;
        }
      })
    }

    return data;
  }

  getUserPendingTerms = async (user) => {
    if (!user) {
      user = await this.getUser();

      if (!user) {
        return false;
      }
    }

    // busca AcceptanceTermVersions para o usuário
    let allTerms = await this.getUserTermVersions(user);

    // busca termos que usuário aceitou
    let userAcceptedTerms = await this.getUserAcceptedTerms();

    // filtra todos os termos que ainda não foram aceitos
    let filteredTerms = allTerms.filter(obj => !userAcceptedTerms.includes(obj.Id))
    return filteredTerms;
  }

  // Busca último termo de CROP.
  // Se está logado, busca da categoria do usuário.
  getLatestCropTerm = async (user) => {
    if (!user) {
      user = await this.getUser();
    }

    let loyaltyCategory = user?.LoyaltyCategory__c || 'Agricultor';

    let termsQ = query(
      collection(this.db, 'AcceptanceTermVersion')
      , where('acg_IsActive__c', '==', true)
      , where('acg_Business__c', '==', 'CROP')
      , where('acg_AcceptanceTerm__r.acg_IsPrivate__c', '!=', true)
      , orderBy('acg_AcceptanceTerm__r.acg_IsPrivate__c', 'desc')
      , orderBy('acg_ActivationDate__c', 'desc')
    );

    let termsDoc = await getDocs(termsQ);
    let data = [];

    if (termsDoc.docs.length) {
      data = termsDoc.docs.map((doc) => {
        let docData = doc.data();

        if (
          docData?.LoyaltyCategory__c?.includes(loyaltyCategory)
        )
          return docData;
      })
    }

    data = data.filter(Boolean);

    return this.dataHelper.getTermCompiledText(data[0]);
  }

  // Verifica se o usuário atual precisa validar o código de acesso.
  isAccessCodeValid = async (user) => {
    if (!user) {
      return false;
    }

    return !user.AccessCodeRequireValidation;
  }

  // Verifica se o usuário precisa definir sua senha
  isRequiredPasswordDefinition = async (UserFirebaseId) => {
    const result = await this.getHerokuUserfirebaseDoc({ userFirebaseId: UserFirebaseId });
    return !result?.success || result.userfbid.PasswordDefinitionIsRequired;
  }

  isRequiredTermsAgreement = async (user) => {
    let pendingTerms = await this.getUserPendingTerms(user);
    return !!pendingTerms.length;
  }

  acceptAgreement = async () => {
    const userRef = await this.getUserRef();
    const user = await this.getUser();

    if (!user?.LoyaltyEmail__c) {
      return {
        success: false
      }
    }

    // TODO - Trocar HasAgreedToTerms para acg_AcceptanceDate__c quando o envio estiver OK
    //        (no caso esse setDoc poderá ser deletado)
    await setDoc(userRef, {
      HasAgreedToTerms: true,
    }, { merge: true });

    const sfUserData = {
      cpf: user.CNPJ_CPF__c,
      email: user.LoyaltyEmail__c.toLowerCase(),
      lastName: user.LastName,
      acceptanceDevice: 'Site',
    }

    try {
      await this.dataHelper?.api.post(HEROKU_LOYALTY_URL, sfUserData)

      return {
        success: true
      };
    } catch (error) {
      console.log('acceptAgreement', error)
    }

    return {
      success: false
    };
  }

  acceptAgreementV2 = async (termId) => {
    const user = await this.getUser();

    if (!termId) {
      return {
        success: false,
      }
    }

    const clientIP = await this.getClientIP();
    const acceptanceDevice = 'Site';

    const sfUserData = {
      contactTaxId: user.CNPJ_CPF__c,
      termId,
      clientIP,
      acceptanceDevice,
    }

    if (user.Id) {
      sfUserData.contactId = user.Id;
    }

    try {
      const response = await this.dataHelper?.api.post(this.dataHelper.getHerokuUrl(HEROKU_ACCEPT_AGREEMENT), sfUserData)

      if (response.id) {
        let firebaseId = response.id.slice(0, 15);
        let userDocRef = await this.getUserRef();
        let memberTermDoc = doc(userDocRef, 'MemberTerm', firebaseId);

        let firebaseData = {
          Id: response.id,
          IsDeleted: false,
          acg_MemberCPFCNPJ__c: user.CNPJ_CPF__c,
          acg_AcceptanceTermVersion__c: termId,
          acg_IPDevice__c: clientIP,
          acg_AcceptanceDevice__c: acceptanceDevice,
        }

        if (user.Id) {
          firebaseData.acg_Member__c = user.Id;
        }

        await setDoc(memberTermDoc, firebaseData)

        return {
          success: true
        };
      }
    } catch (error) {
      console.log('acceptAgreementV2', error)
    }

    return {
      success: false,
    }
  }

  acceptPendingTerms = async (data) => {
    let originalUserData = await this.getUser();
    data = { ...originalUserData, ...data };
    // Verifica se todos os termos pendentes foram aceitos
    let termsAreOk = await this.areTermsOk(data, true);

    if (!termsAreOk) {
      return {
        success: false,
        error: {
          code: 'missing-terms',
        }
      }
    }

    // Aceita todos os termos pendentes
    if (await this.acceptAllTerms(true)) {
      return {
        success: true,
      }
    }

    return {
      success: false,
    }
  }

  getClientIP = async () => {
    if (global.ipAddress) {
      return global.ipAddress;
    }

    try {
      const ipFetch = await fetch('https://api.ipify.org?format=json');

      if (ipFetch.ok) {
        const ipRes = await ipFetch.json();
        global.ipAddress = ipRes.ip;
        return global.ipAddress;
      }
    }
    catch (e) {
      return null;
    }

    return null;
  }

  // Gera uma string de 6 dígitos onde os 2 últimos são números para conferência
  generateRandomAccessCode = (length = 6) => {
    let result = '';
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let charactersLength = characters.length;
    let charSum = 0;
    for (let i = 0; i < (length - 2); i++) {
      let chosenChar = characters.charAt(Math.floor(Math.random() * charactersLength));
      result += chosenChar;
      charSum += chosenChar.charCodeAt(0);
    }

    return result + (charSum + '').slice(-2);
  }

  // Valida o código do usuário no firebase
  validateAccessCode = async (UserFirebaseId, AccessCode, cpfCnpj) => {
    if ((!UserFirebaseId && !cpfCnpj) || !AccessCode) return false;

    const herokuData = {
      UserFirebaseId,
      AccessCode,
      locale: 'acessaAgro',
      cpfCnpj,
    }

    try {
      return await this.dataHelper?.api.post('/validateAccessCode', herokuData)
    }
    catch (e) {
      console.log('validateAccessCode', e)
    }

    return { error: 'unknown-error' };
  }

  // Busca em Email, CPF_CNPJ__c e Username por usuário em uso. Só retorna false caso todos estejam livres.
  usernamesExists = async (userData, userFirebaseId) => {
    if (userData.CNPJ_CPF__c) {
      let cleanCpf = ToolHelper.getDigits(userData.CNPJ_CPF__c)

      let result = await this.getHerokuUsernameDoc(cleanCpf);

      if (!result?.success && result?.error !== 'not-found') {
        return {
          success: false,
          error: {
            code: 'unknown-error',
          }
        }
      }
      else if (result?.username?.UserFirebaseId && result.username.UserFirebaseId !== userFirebaseId) {
        return {
          success: false,
          error: {
            code: 'auth/cpfcnpj-already-in-use',
          }
        }
      }
    }

    return false;
  }

  areTermsOk = async (userData, onlyPendingTerms = false) => {
    let terms = [];

    if (onlyPendingTerms) {
      terms = await this.getUserPendingTerms(userData);
    }
    else {
      terms = await this.getUserTermVersions(userData);
    }

    if (!terms?.length) {
      return true;
    }

    let termsNotOk = terms.map(term => userData.checkedTerms[term.Id] ? null : term.Id).filter(Boolean)

    return !termsNotOk.length;
  }

  createUserFromPayload = async (payload) => {
    if (!payload) return { success: false };

    try {
      const result = await this.dataHelper?.api.post('/createUserFromSFContact', payload)
      if (result?.success) {
        return result;
      }
      else {
        return {
          success: false,
          error: {
            code: result.error,
          }
        };
      }
    }
    catch (e) {
      console.log('createUserFromSFContact', e)
      return { success: false }
    }
  }

  createAuthUser = async (email) => {
    if (!email) return { success: false };

    const data = { email }

    try {
      const result = await this.dataHelper?.api.post('/createAuthUser', data)
      if (result?.success) {
        // Já realiza o login da conta recém criada
        if (result.token) {
          const auth = getAuth();
          await signInWithCustomToken(auth, result.token);
        }

        return result;
      }
      else {
        return {
          success: false,
          error: {
            code: result.error,
          }
        };
      }
    }
    catch (e) {
      console.log('createAuthUser', e)
    }

    return { success: false };
  }

  translateFieldsCreateUser = async (userData) => {
    let profile_translated = {
      "Password": userData?.OriginalPassword,
      "birthdate": userData?.LoyaltyBirthdate__c,
      "city": userData?.LoyaltyCity__c,
      "country": null,
      "cpfCnpj": userData?.CNPJ_CPF__c,
      "culturas": userData?.acg_Culturas__c,
      "email": (userData?.Email || userData?.AuthRegisterEmail) || userData?.CNPJ_CPF__c?.replace(/\D/g, "") + FAKE_DOMAIN,
      "login": userData?.AuthRegisterEmail,
      "firstName": userData?.FirstName,
      "gender": null,
      "isSecondaryProfile": true,
      "lastName": userData?.LastName,
      "phone": userData?.LoyaltyPhone__c,
      "postalCode": userData?.LoyaltyPostalCode__c,
      "state": userData?.LoyaltyState__c,
      "street": userData?.LoyaltyStreet__c,
      "userCategory": "Agricultor",
      "userGroupId": null,
      "noPasswordDefinitionIsRequired": userData?.noPasswordDefinitionIsRequired || false,
      "previousAccountOkta": userData?.previousAccountOkta || false
    };
    return profile_translated;
  }
  /*Novo Método de criação de usuário mais simplificado e executado pelo MID
  Incialmente uso exclusivo de produtores que já possuam perfil de consultor*/
  saveUserMID = async (userData, callback) => {
    const checkFirstName = FormHelper.fieldLength(userData?.FirstName, 3, 'firstname-length')
    if (!checkFirstName.success) return checkFirstName;

    const checkLastName = FormHelper.fieldLength(userData?.LastName, 3, 'lastname-length')
    if (!checkLastName.success) return checkLastName;

    if (userData.CompanyName) {
      const checkCompanyName = FormHelper.fieldLength(userData?.CompanyName, 3, 'companyname-length')
      if (!checkCompanyName.success) return checkCompanyName;

      userData.FirstName = userData.LastName = userData.CompanyName;
    }

    let originalUserData = await this.getUser();

    // Verifica se o código de indicação é válido
    if (userData?.acg_ReferralCodeUsed__c && userData?.acg_ReferralCodeUsed__c !== null && userData?.acg_ReferralCodeUsed__c !== '-') {
      const result = await this.dataHelper.getUserByReferralCode(userData?.acg_ReferralCodeUsed__c)
      if (result?.error) {
        return {
          success: false,
          error: {
            code: 'invalid-referral-code',
          }
        }
      }
    }
    // Verifica se algum username está em uso && Verifica se todos os termos foram aceitos
    if (!originalUserData) {
      const termsAreOk = await this.areTermsOk(userData);
      if (!termsAreOk) {
        return {
          success: false,
          error: {
            code: 'missing-terms',
          }
        }
      }
    }
    userData.EmailIsReal = !!(userData.Email?.length);
    userData.Password = userData.OriginalPassword = userData.Password || Math.random().toString();
    if (originalUserData?.UID) {
      userData.UID = originalUserData.UID;
    }
    userData.noPasswordDefinitionIsRequired = true;
    if (userData?.oktaId)
      userData.previousAccountOkta = true;




    if (!userData.UID) {
      let username_type = FormHelper.detectStringContent(userData.Username);
      userData.UsernameType = username_type;
      switch (username_type) {
        case 'cpf':
          userData.AuthRegisterEmail = ToolHelper.getDigits(userData.Username) + FAKE_DOMAIN;
          break;
        case 'cnpj':
          userData.AuthRegisterEmail = ToolHelper.getDigits(userData.Username) + FAKE_DOMAIN;
          break;
        case 'text':
          userData.AuthRegisterEmail = userData.Username + FAKE_DOMAIN;
          break;
        default:
          userData.AuthRegisterEmail = ToolHelper.getDigits(userData.Username) + FAKE_DOMAIN;
          break;
      }

      if (userData.AuthRegisterEmail)
        userData.AuthRegisterEmail = userData?.AuthRegisterEmail.toLowerCase();

      if (!userData.EmailIsReal) userData.Email = userData?.AuthRegisterEmail;


      if (userData?.LoyaltyBirthdate__c)
        userData.LoyaltyBirthdate__c = moment(userData?.LoyaltyBirthdate__c, 'DD/MM/YYYY').format('YYYY-MM-DD');

      if (!userData?.LoyaltyEmail__c)
        userData.LoyaltyEmail__c = userData?.Email;
      if (userData.CNPJ_CPF__c)
        userData.CNPJ_CPF__c = ToolHelper.getDigits(userData.CNPJ_CPF__c);


      let profile_translated = await this.translateFieldsCreateUser(userData);
      let create_user_result = await this.createUserFromPayload(profile_translated)
      userData.FirebaseId__c = create_user_result.data?.firebaseId;
      global.userFirebaseId = create_user_result.data?.firebaseId;
      global.user = create_user_result.data;

      if (create_user_result?.success) {
        const auth = getAuth();
        await signInWithCustomToken(auth, create_user_result.data?.customToken);
      }
      userData.FirebaseId__c = create_user_result.data?.firebaseId;

      userData = {
        ...userData,
        UID: create_user_result.data?.uID,
        UserFirebaseId: create_user_result.data?.firebaseId,
      }

      if (userData?.FirebaseId__c) global.userFirebaseId = userData?.FirebaseId__c;

      let resultSaveUser = await this.saveUserWithConsultantProfile(userData);

      return resultSaveUser;
    } else {
      return {
        success: false,
        error: {
          code: 'save-user-usual',
        }
      }
    }
  }

  saveUser = async (userData, callback) => {
    const checkFirstName = FormHelper.fieldLength(userData?.FirstName, 3, 'firstname-length')
    if (!checkFirstName.success) return checkFirstName;

    const checkLastName = FormHelper.fieldLength(userData?.LastName, 3, 'lastname-length')
    if (!checkLastName.success) return checkLastName;

    if (userData.CompanyName) {
      const checkCompanyName = FormHelper.fieldLength(userData?.CompanyName, 3, 'companyname-length')
      if (!checkCompanyName.success) return checkCompanyName;

      userData.FirstName = userData.LastName = userData.CompanyName;
    }

    let originalUserData = await this.getUser();

    if (!userData.UID) {
      let username_type = FormHelper.detectStringContent(userData.Username);
      userData.UsernameType = username_type;

      try {
        const responseEdit = await this.editUserData(userData, originalUserData, callback);
        return responseEdit;
      }
      catch (error) {
        return {
          success: false,
          error,
        };
      }
    }
    else {
      return this.editUserData(userData, callback)
    }
  }

  // Parâmetros de entrada:
  // - username OU userFirebaseId (em userData)
  // Parâmetros de saída:
  // - success: define se processo de verificação foi feito com sucesso
  // - code: [user-exists,user-needs-password-definition,user-does-not-exist]
  //
  // > Se user-needs-password-definition, o usuário será logado com uma senha fixa.
  //   Neste caso, é necessário registrar que usuário precisa autenticar seu acesso e definir sua senha.
  checkUserExists = async (userData, screen) => {
    let { username, userFirebaseId } = userData;

    if (!username && !userFirebaseId) {
      return {
        success: false,
      }
    }
    const forgotPasswordUrl = '/auth/forgot-password';
    let data = {
      cpf: username,
      locale: 'acessaAgro',
    }
    try {
      const result = await this.dataHelper?.api.post(forgotPasswordUrl, data, false)
      return JSON.parse(result);

    } catch (e) {
      console.log('checkUserExists', e)
    }
  }

  checkUserStatusCode = async (userData, screen) => {
    let { username, userFirebaseId } = userData;

    if (!username && !userFirebaseId) {
      return {
        success: false,
      }
    }
    const userStatusCodeUrl = '/auth/register';
    let data = {
      cpf: username,
      locale: 'acessaAgro',
    }
    try {
      const result = await this.dataHelper?.api.post(userStatusCodeUrl, data, false)
      return JSON.parse(result);

    } catch (e) {
      console.log('checkUserStatusCode', e)
    }
  }

  // Por enquanto a única forma de definir a senha, sem saber a senha antiga, é através de funções customizadas
  definePassword = async (UserFirebaseId, AccessCode, password, username) => {
    // custom definePassword
    if (this.customCfg?.definePassword) {
      let result = await this.customCfg.definePassword(UserFirebaseId, AccessCode, password, username, this);

      if (result.doLogin && result.username) {
        await this.userLogin(result.username, password);
      }

      if (result === false || (typeof result === 'object' && (!result.success || result.haltProcess))) {
        return result;
      }
    }

    return {
      success: true,
    }
  }

  setUserData = async (userData) => {
    let userDoc = { ...userData };

    delete userDoc.Password;
    delete userDoc.PasswordConfirmation;
    delete userDoc.OriginalPassword;
    delete userDoc.userFirebaseIdData;

    if (userDoc.LoyaltyBirthdate__c)
      userDoc.LoyaltyBirthdate__c = moment(userDoc.LoyaltyBirthdate__c, 'DD/MM/YYYY').format('YYYY-MM-DD');

    if (userDoc.CNPJ_CPF__c)
      userDoc.CNPJ_CPF__c = ToolHelper.getDigits(userDoc.CNPJ_CPF__c);

    if (userData.FirebaseId__c)
      global.userFirebaseId = userData.FirebaseId__c;

    const ref = await this.getUserRef();

    const docResponse = setDoc(ref, {
      ...userDoc,
      lastSignInTime: serverTimestamp()
    }, { merge: true });

    return docResponse;
  }

  getTopics = async () => {
    let topicsQ = query(collection(this.db, 'NotificationTopic'), orderBy('Order__c'));
    let topicsDocs = await getDocs(topicsQ);

    let topics = [];
    if (topicsDocs.size > 0) {
      topicsDocs.forEach((doc) => {
        let docData = doc.data();

        if (docData.FirebaseId__c !== null && docData.FirebaseId__c !== '')
          topics.push(docData)
      })
    }

    return topics;
  }

  snapUserPoints = (callback) => {
    let pointsQ = query(collection(this.db, 'Users', global.userFirebaseId, 'PointStatement'), orderBy('PointDate__c', 'desc'));

    let unsub = onSnapshot(pointsQ, (snapshot) => {
      let points = this.dataHelper.getList(snapshot);
      callback(points);
    })

    return unsub
  }

  getUserPoints = async (qty = 10, startAfterDoc = null) => {
    let userDocRef = await this.getUserRef();
    let pointsQ = query(collection(userDocRef, 'PointStatement'), orderBy('PointDate__c', 'desc'), limit(qty));
    if (startAfterDoc) {
      pointsQ = query(pointsQ, startAfter(startAfterDoc));
    }
    let pointsDoc = await getDocs(pointsQ);

    let data = [];
    let lastDoc = null;

    if (pointsDoc.docs.length) {
      data = pointsDoc.docs.map((doc) => {
        lastDoc = doc;
        return doc.data();
      })
    }

    return { data, lastDoc };
  }

  googleLogin = (callback) => {
    const provider = new GoogleAuthProvider();
    const auth = getAuth();

    provider.addScope('email');

    signInWithPopup(auth, provider)
      .then(async (result) => {

        const credential = GoogleAuthProvider.credentialFromResult(result);

        const token = credential.accessToken;

        const user = result.user;

        // Verifica se user já tem registro em Users (se não tiver, assume-se novo usuário)
        let email = user?.providerData[0]?.email;

        const isNewUser = (!email || !(await this.firestoreEmailExists(email)));

        callback({
          success: true,
          isNewUser,
          user,
        })
      }).catch((error) => {
        const credential = GoogleAuthProvider.credentialFromError(error);

        callback({
          success: false,
          error,
        })
      })
  }

  facebookLogin = (callback) => {
    const provider = new FacebookAuthProvider();

    provider.addScope('email');
    provider.setCustomParameters({
      display: 'popup'
    })

    const auth = getAuth();
    signInWithPopup(auth, provider)
      .then(async (result) => {

        const user = result.user;

        const credential = FacebookAuthProvider.credentialFromResult(result);
        const accessToken = credential.accessToken;

        // Verifica se user já tem registro em Users (se não tiver, assume-se novo usuário)
        let email = user?.providerData[0]?.email;

        const isNewUser = (!email || !(await this.firestoreEmailExists(email)));

        callback({
          success: true,
          isNewUser,
          user,
        })
      })
      .catch((error) => {
        const credential = FacebookAuthProvider.credentialFromError(error);

        callback({
          success: false,
          error,
        })
      });
  }



  recoverPassword = async (username) => {
    let userFirebaseDoc = await this.findUserFirebaseDoc({ username });

    if (!userFirebaseDoc) {
      return {
        success: false,
        error: { code: 'auth/user-not-found' },
      }
    }

    let email = userFirebaseDoc.Email;

    const auth = getAuth();

    // custom beforeRecoverPassword
    if (this.customCfg?.beforeRecoverPassword) {
      let result = await this.customCfg.beforeRecoverPassword(username, userFirebaseDoc, 'recoverpassword', this)
      if (result === false || (typeof result === 'object' && (!result.success || result.haltProcess))) {
        if (result?.code === 'user-needs-password-definition' || result?.code === 'user-exists') {
          await this.sendAccessCode(userFirebaseDoc.UserFirebaseId);
        }

        if (typeof result === 'object' && userFirebaseDoc?.UserFirebaseId) {
          result.UserFirebaseId = userFirebaseDoc.UserFirebaseId;
        }

        return result;
      }
    }


    try {
      await sendPasswordResetEmail(auth, email)
    }
    catch (error) {
      return {
        success: false,
        error
      }
    }


    return {
      success: true,
      UserFirebaseId: userFirebaseDoc.UserFirebaseId,
    }
  }

  // userData: currentPassword / newPassword
  changePassword = async (userData) => {
    let user = this.getAuthUser();

    // custom beforeChangePassword
    if (this.customCfg?.beforeChangePassword) {
      let customData = { ...userData, Email: user.email }
      let result = await this.customCfg.beforeChangePassword(customData)

      if (result.haltProcess || !result.success) {
        // atualiza a collection Users para o processo de deslogar o mesmo de todos os dispositivos e browsers
        let userDocRef = await this.getUserRef();
        if (userDocRef)
          await setDoc(userDocRef, { lastTimePasswordChanged: serverTimestamp() }, { merge: true });

        return result;
      }
    }

    const credential = EmailAuthProvider.credential(user.email, userData.CurrentPassword);

    try {
      let reauthResult = await reauthenticateWithCredential(user, credential);

      await updatePassword(user, userData.NewPassword);

      // Garante que não há mais flag de obrigar troca de senha
      const userFirebaseIdRef = await this.getUserFirebaseIdRef();

      await setDoc(userFirebaseIdRef, {
        PasswordDefinitionIsRequired: false,
      }, { merge: true });

      return {
        success: true
      };
    }
    catch (error) {
      return {
        success: false,
        error
      }
    }
  }

  logout = async (successCallback, errorCallback) => {
    const auth = getAuth();

    await this.dataHelper.inativeAllSession(global?.user?.acg_PremmiarId__c);

    if (auth?.currentUser) {
      signOut(auth).then(() => {
        global.userFirebaseId = null;
        global.userType = null;
        global.userTypes = [];

        successCallback();
      }).catch((error) => {
        errorCallback(error);
      });
    }
    else {
      successCallback();
    }
  }

  logoutAsync = async () => {
    const auth = getAuth();

    try {
      await signOut(auth);
      global.userFirebaseId = null;
      global.userType = null;
      global.userTypes = [];

      return {
        success: true
      }
    }
    catch (error) {
      return {
        success: false,
        error
      }
    }
  }

  updateLoyalty = async () => {
    let userDocRef = await this.getUserRef();
    let userDoc = await getDoc(userDocRef);

    if (userDoc.exists()) {
      let userData = userDoc.data();
      return await this.createLoyalty(userData);
    }

    return false;
  }

  createLoyalty = async (userData) => {
    if (!userData.LoyaltyEmail__c || !userData.LastName) {
      return;
    }
    let sfUserData = {
      firstName: userData.FirstName,
      lastName: userData.LastName,
      birthdate: userData.LoyaltyBirthdate__c,
      street: userData.LoyaltyStreet__c,
      district: userData.acg_AddressDistrict__c,
      city: userData.LoyaltyCity__c,
      state: userData.LoyaltyState__c,
      country: userData.LoyaltyCountry__c,
      postalCode: userData.LoyaltyPostalCode__c,
      addressNumber: userData.acg_AddressNumber__c,
      addressComplement: userData.acg_AddressComplement__c,
      facebookId: userData.FacebookId__c,
      googleId: userData.GoogleId__c,
      twitterId: userData.TwitterId__c,
      firebaseId: userData.FirebaseId__c,
      messagingToken: userData.MessagingToken__c,
      topics: userData.Topics__c,
      cpf: userData.CNPJ_CPF__c,
      profissao: userData.Profissao__c,
      areaAtuacao: userData.AreaAtuacao__c,
      lojaRelacionamento: userData.LojaRelacionamento__c,
      userCategory: userData.LoyaltyCategory__c,
      photoURL: userData.photoURL,
      culturas: userData.acg_Culturas__c,
      referralCodeUsed: userData.acg_ReferralCodeUsed__c,
      pis: userData.acg_Pis__c,
      rg: userData.acg_Rg__c
    }

    const loyaltyUrl = await this.dataHelper.getHerokuUrl(HEROKU_LOYALTY_URL);

    let success = false;
    let saveLog = true;
    let err;

    try {
      const result = await this.dataHelper?.api.put(loyaltyUrl, sfUserData, false);

      if (result === 'Message received' || result === 'Logged error') {
        saveLog = false;
      }
      else {
        err = result;
      }

      if (result === 'Message received') {
        success = true;
      }
    } catch (error) {
      err = error;
      console.warn('createLoyaltyException', error);
    }

    if (saveLog) {
      this.dataHelper.saveLog({
        method: loyaltyUrl,
        data: sfUserData,
        result: err,
      })
    }

    return success;
  }

  setLastTimeOnline = async () => {
    const userData = await this.getUser();

    if (!userData)
      return null;

    const dateRef = moment().format('YYYY-MM-DD');
    const lastOnline = userData.lastTimeOnline ? moment(userData.lastTimeOnline.toDate()).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');

    const lastTimeNull = userData.lastTimeOnline == null;
    const isNew = dateRef > lastOnline;

    if (global.lastDailyLogin !== dateRef && (lastTimeNull || isNew)) {
      global.lastDailyLogin = dateRef;
      await GameficationHelper.addRow(this, POINT_ACTIONS.DAILY_LOGIN);
    }

    let userDocRef = await this.getUserRef();

    if (userDocRef)
      await setDoc(userDocRef, { lastTimeOnline: serverTimestamp() }, { merge: true });
  }

  setProgramOptOut = async () => {
    const userData = await this.getUser();

    if (!userData)
      return null;

    const sfUserData = {
      cpf: userData.CNPJ_CPF__c?.replace(/\D/g, ''),
      email: userData.LoyaltyEmail__c.toLowerCase(),
      lastName: userData.LastName,
      programOptOut: true,
      userCategory: userData?.LoyaltyCategory__c
    }

    try {
      await this.dataHelper?.api.post(this.dataHelper.getHerokuUrl(HEROKU_LOYALTY_URL), sfUserData)

      const userDocRef = await this.getUserRef();
      await setDoc(userDocRef, { acg_ProgramOptOut__c: true }, { merge: true })

      return {
        success: true
      };
    } catch (error) {
      console.log('setProgramOptOut', error)
    }
  }

  uploadAvatar = async (img) => {
    const userFirebaseId = await this.getUserFirebaseId();
    const storage = getStorage();
    const storageRef = ref(storage, `ProfilePictures/${userFirebaseId}.jpeg`);
    const metadata = { contentType: 'image/jpeg' };

    uploadBytes(storageRef, img, metadata).then((snapshot) => {
      getDownloadURL(storageRef).then(async (url) => {
        const ref = await this.getUserRef();

        const docResponse = setDoc(ref, {
          photoURL: url,
        }, { merge: true });

        await this.updateLoyalty();

        return url;
      })
    });
  }

  setDocumentViewed = async (documentId) => {
    let userId = await this.getUserFirebaseId();

    if (documentId && userId) {
      let viewRef = doc(this.db, 'Users', userId, 'View', documentId);
      let viewDoc = await getDoc(viewRef);

      if (!viewDoc.exists()) {
        await GameficationHelper.addRow(this, POINT_ACTIONS.EVENT_CLICK_NEWS, documentId);
      }

      let viewData = {
        userId,
        lastViewDate: serverTimestamp(),
        documentId
      }

      setDoc(viewRef, viewData, { merge: true });
    }
  }

  setBannerViewed = async (bannerId) => {
    await GameficationHelper.addRow(this, POINT_ACTIONS.EVENT_CLICK_ADS, bannerId);

    return true;
  }

  getFinishedSurveyIds = async (isQuiz) => {
    const finishedQ = collection(this.db, 'Users', await this.getUserFirebaseId(), isQuiz ? 'QuizAnswers' : 'SurveyAnswers');
    const finishedDoc = await getDocs(finishedQ);
    const finishedSurveys = this.dataHelper.docsToArray(finishedDoc);

    let out = finishedSurveys.map((finishedSurvey) => finishedSurvey.Id);
    return out;
  }

  getSurveyQuestionAnswers = async (isQuiz) => {
    const answersQ = collection(this.db, 'Users', await this.getUserFirebaseId(), isQuiz ? 'QuizAnswersOption' : 'SurveyAnswersOption');
    const answersDoc = await getDocs(answersQ);
    const answers = this.dataHelper.docsToArray(answersDoc);

    return answers;
  }

  surveySendAnswer = async (
    survey,
    question,
    selectedOptionIds, // array de strings
    textAnswer,
  ) => {

    let cgny2__Action__c = POINT_ACTIONS.ACTION_VOTE_SURVEY_ANSWER + question?.RecordType?.DeveloperName?.toUpperCase();
    let rightAnswer = false;
    const isQuiz = survey.FirebasePath__c === 'Quiz';

    if (typeof selectedOptionIds === 'object' && selectedOptionIds.length > 0) {
      const optId = selectedOptionIds[0];


      if (isQuiz) {
        if (optId === question.RightAnswer__c) {
          rightAnswer = true;
          cgny2__Action__c = POINT_ACTIONS.ACTION_QUIZ_RIGHT_ANSWER;
        }
        else {
          cgny2__Action__c = POINT_ACTIONS.ACTION_QUIZ_WRONG_ANSWER;
        }
      }

      await GameficationHelper.addRow(
        this,
        cgny2__Action__c,
        question.Id,        //cgny2__GenericId__c,
        undefined,          //cgny2__Event__c,
        textAnswer,         //cgny2__Value__c,
        undefined,          //cgny2__AdvertisingItem__c,
        undefined,          //cgny2__Gallery__c,
        undefined,          //cgny2__News__c,
        question.Survey__c, //cgny2__Survey__c,
        optId,              //cgny2__SurveyQuestionOption__c,
        question.Id,        //cgny2__SurveyQuestion__c,
        undefined,          //cgny2__Voucher__c
        undefined,          //appUser
        undefined,          //recordType
        undefined,          //cgny2__NumberValue__c
        undefined,          //cgny2__StartUpMessage__c
      );
      // }
    }
    else {
      // Uma resposta pode ter apenas Answer e não ter opções selecionadas
      await GameficationHelper.addRow(
        this,
        cgny2__Action__c,
        question.Id,        //cgny2__GenericId__c,
        undefined,          //cgny2__Event__c,
        textAnswer,         //cgny2__Value__c,
        undefined,          //cgny2__AdvertisingItem__c,
        undefined,          //cgny2__Gallery__c,
        undefined,          //cgny2__News__c,
        question.Survey__c, //cgny2__Survey__c,
        undefined,          //cgny2__SurveyQuestionOption__c,
        question.Id,        //cgny2__SurveyQuestion__c,
        undefined,          //cgny2__Voucher__c
        undefined,          //appUser
        undefined,          //recordType
        undefined,          //cgny2__NumberValue__c
        undefined,          //cgny2__StartUpMessage__c
      );
    }

    let tmpQuestionAnswer = {
      Id: question.Id,
      question,
      rightAnswer,
      actionDate: serverTimestamp(),
    };

    if (survey.Status__c !== 'Test') {
      let questionDoc = doc(this.db, 'Users', await this.getUserFirebaseId(), isQuiz ? 'QuizAnswersOption' : 'SurveyAnswersOption', question.Id);
      setDoc(questionDoc, tmpQuestionAnswer);

    }
  }

  surveySendClosure = async (survey) => {
    const isQuiz = survey.FirebasePath__c === 'Quiz';
    let surveyDoc = doc(this.db, 'Users', await this.getUserFirebaseId(), isQuiz ? 'QuizAnswers' : 'SurveyAnswers', survey.Id);

    setDoc(surveyDoc, {
      Id: survey.Id,
      actionDate: serverTimestamp()
    })

    const cgny2__Action__c = isQuiz ? POINT_ACTIONS.ACTION_QUIZ_COMPLETE : POINT_ACTIONS.ACTION_VOTE_SURVEY_COMPLETE;
    const cgny2__GenericId__c = survey.Id;

    GameficationHelper.addRow(
      this,
      cgny2__Action__c,
      cgny2__GenericId__c,
      undefined, //cgny2__Event__c,
      undefined, //cgny2__Value__c,
      undefined, //cgny2__AdvertisingItem__c,
      undefined, //cgny2__Gallery__c,
      undefined, //cgny2__News__c,
      survey.Id, //cgny2__Survey__c,
      undefined, //cgny2__SurveyQuestionOption__c,
      undefined, //cgny2__SurveyQuestion__c,
      undefined, //cgny2__Voucher__c
      undefined, //appUser
      undefined, //recordType
      undefined, //cgny2__NumberValue__c
      undefined  //cgny2__StartUpMessage__c
    );
  }

  snapCases = async (callback) => {
    let userFirebaseId = await this.getUserFirebaseId();

    if (userFirebaseId) {
      let casesQ = query(collection(this.db, 'Users', userFirebaseId, 'Case'), orderBy('CreatedDate', 'desc'));

      let unsub = onSnapshot(casesQ, (snapshot) => {
        let cases = this.dataHelper.getList(snapshot);
        callback(cases);
      })

      return { cases: unsub }
    }

    return null;
  }

  getCaseReasonsToAskUserDocument = () => {
    return ['Correção de e-mail e/ou telefone', 'Alteração Cadastral'];
  }

  saveNewCase = async (caseData, inputs) => {
    let aInputs = Object.entries(inputs);
    let requiredFields = [];
    aInputs.every(aInput => {
      if (aInput[1].isRequired)
        requiredFields.push(aInput[0]);
      return true;
    })

    if (!FormHelper.validateRequiredFields(inputs, caseData)) {
      return {
        success: false,
      }
    }

    let images = [];

    let reasonsToAskUserDocument = this.getCaseReasonsToAskUserDocument();

    if (reasonsToAskUserDocument.includes(caseData.Reason)) {
      if (caseData.DocumentPhoto1) {
        images = [...images, ...JSON.parse(caseData.DocumentPhoto1)]
      }
      if (caseData.DocumentPhoto2) {
        images = [...images, ...JSON.parse(caseData.DocumentPhoto2)]
      }
    }
    else if (caseData.Images__c) {
      images = JSON.parse(caseData.Images__c);
    }

    caseData.Images__c = images.map((image) => ({
      attachUrl: image.imageUrl,
      fileName: image.imageId + ".jpeg",
    }))

    const userData = await this.getUser();

    const requestBody = {
      Type: caseData.Type,
      Reason: caseData.Reason,
      Description: caseData.Description,
      RecordTypeId: await this.dataHelper.getCaseRecordTypeId(),
      Images: JSON.stringify(caseData.Images__c),
      Name: caseData.Name,
      SuppliedEmail: caseData.Email,
      Phone: caseData.LoyaltyPhone__c,
    };

    if (userData?.Id) {
      requestBody.ContactId = userData.Id;
      requestBody.Name = userData.FirstName + ' ' + userData.LastName;
      requestBody.SuppliedEmail = userData.LoyaltyEmail__c;
      requestBody.Phone = userData.LoyaltyPhone__c;
    }

    try {
      await this.dataHelper?.api.post(this.dataHelper.getHerokuUrl(HEROKU_CASE_URL), requestBody)

      return {
        success: true,
      }
    }
    catch (e) {
      console.log('saveNewCase', e)
      return {
        success: false,
        error: e,
      }
    }
  }

  snapRaffleWinners = async (callback, raffleId) => {
    let raffleWinnersQ = query(collection(this.db, 'DrawnReward'));

    return onSnapshot(raffleWinnersQ,
      (snapshot) => {
        let raffleWinners = this.dataHelper.getList(snapshot);

        raffleWinners = raffleWinners.filter((raffleWinner) =>
          raffleWinner?.Raffle__c && raffleWinner.Raffle__c === raffleId
        )

        callback(raffleWinners);
      }
    )
  }

  snapRafflesLuckyNumbers = async (callback, raffleId, userId) => {
    let luckyNumberAmountQ = query(
      collection(
        this.db,
        'LuckyNumbers'
      ),
      where('Contact__c', '==', userId),
      where('Raffle__c', '==', raffleId),
    );

    return onSnapshot(luckyNumberAmountQ,
      (snapshot) => {
        let luckyNumberAmount = this.dataHelper.getList(snapshot);

        callback(luckyNumberAmount);
      }
    )
  }

  getRafflesLuckyNumbers = async (raffleId, userId) => {
    if (!userId) return [];

    let luckyNumberAmountQ = query(
      collection(
        this.db,
        'LuckyNumbers',
      ),
      where('Contact__c', '==', userId),
      where('Raffle__c', '==', raffleId),
    );
    let luckyNumberAmountDocs = await getDocs(luckyNumberAmountQ)

    const luckyNumbers = [];

    if (luckyNumberAmountDocs.size > 0) {
      luckyNumberAmountDocs.forEach((doc) => {
        let data = doc.data();
        luckyNumbers.push(data)
      })
    }

    return luckyNumbers;
  }

  getRafflesDetails = async (raffleId, userId) => {
    if (!userId) return [];

    let RafflesDetails = query(
      collection(
        this.db,
        'Raffles',
      ),

      // where('Contact__c', '==', userId),
      where('Id', '==', raffleId),

    );
    let RafflesDetailsDocs = await getDocs(RafflesDetails)

    const raffles_data = [];

    if (RafflesDetailsDocs.size > 0) {
      RafflesDetailsDocs.forEach((doc) => {
        let data = doc.data();
        raffles_data.push(data)
      })
    }

    return raffles_data.length > 0 ? raffles_data[0] : {};
  }

  snapRafflesSalesAmount = async (callback, raffleId, userId) => {
    if (!userId) return [];
    let rafflesSalesAmountQ = query(
      collection(
        this.db,
        'RafflePerformances'
      ),
      where('acg_Contact__c', '==', userId),
      where('Raffle__c', '==', raffleId),
    );

    return onSnapshot(rafflesSalesAmountQ,
      (snapshot) => {
        let rafflesSalesAmount = this.dataHelper.getList(snapshot);

        callback(rafflesSalesAmount);
      }
    )
  }

  snapRaffles = (callback, userId, loyaltyCategory = '') => {
    if (!userId) return [];

    let rafflesQ = query(collection(this.db, 'Raffles'));

    return onSnapshot(rafflesQ, (snapshot) => {
      const toDay = moment();
      let raffles = this.dataHelper.getList(snapshot);

      raffles = raffles.filter((raffle) => {
        const startDate = moment(raffle.acg_StartDate__c, 'YYYY/MM/DD').startOf('day');
        const endDate = moment(raffle.acg_EndDate__c, 'YYYY/MM/DD').endOf('days');
        return (!raffle.acg_StartDate__c || !raffle.acg_EndDate__c) ? false : startDate.isSameOrBefore(toDay) && endDate.isSameOrAfter(toDay);
      });

      callback(raffles);
    })
  }

  snapGroupParticipants = (callback, userId, id) => {
    if (!userId) return [];
    let rafflesQ = query(
      collection(this.db, 'RaffleParticipants'),
      where('acg_Contact__c', '==', userId),
      where('acg_Raffle__c', '==', id)
    );
    return onSnapshot(rafflesQ,
      (snapshot) => {
        let raffles = this.dataHelper.getList(snapshot);
        callback(raffles);
      });

  };

  snapRaffleRankingPerformanceByUser = (callback, userId, rankingId) => {
    if (!userId) return [];
    let rafflesQ = query(
      collection(this.db, 'RaffleRanking'),
      where('acg_Contact__c', '==', userId),
      // where('acg_RaffleVersion__c', '==',  "a585E000000UbgpQAC")
    );
    return onSnapshot(rafflesQ,
      (snapshot) => {
        // console.log('snapshot', snapshot)
        let raffles = this.dataHelper.getList(snapshot);
        callback(raffles);
      });

  };

  snapRaffleRankingsData = (callback, raffleId) => {

    let rafflesQ = query(
      collection(this.db, 'RaffleRanking'),

    );
    return onSnapshot(rafflesQ,
      (snapshot) => {
        // console.log('snapshot', snapshot)
        let raffles = this.dataHelper.getList(snapshot);
        callback(raffles);
      });

  };

  snapRafflesParticipants = (callback, userId) => {
    if (!userId) return [];

    let rafflesQ = query(
      collection(this.db, 'RaffleParticipants'),
      where('acg_Contact__c', '==', userId)
    );

    return onSnapshot(rafflesQ,
      (snapshot) => {
        let raffles = this.dataHelper.getList(snapshot);
        callback(raffles);
      }
    )
  }

  snapDistributors = (callback, filters = {}, lmt = 1000) => {
    let distributorsQ;

    if (filters.uf) {
      distributorsQ = query(collection(this.db, 'Account'),
        where('BillingAddress.stateCode', '==', filters.uf),
        where('acg_ElegivelNF__c', 'in', [true, false]),
        orderBy('Id', 'desc'),
        limit(lmt)
      );
    } else {
      distributorsQ = query(collection(this.db, 'Account'),
        where('acg_ElegivelNF__c', 'in', [true, false]),
        limit(lmt)
      );
    }

    return onSnapshot(distributorsQ,
      (snapshot) => {

        let distributors = this.dataHelper.getList(snapshot);

        distributors = distributors.filter((distributor) => {
          return (
            distributor.acg_UploadNF__c || distributor.acg_ElegivelNF__c || distributor.acg_FluxoCooperativa__c
          )
        });

        if (filters.punctuation) {
          if (filters.punctuation === 'Canal pontua por cadastro manual de NF') {
            distributors = distributors.filter((distributor) => {
              return (
                (distributor.acg_UploadNF__c || distributor.acg_FluxoCooperativa__c)
              )
            })
          }

          if (filters.punctuation === 'Canal com pontuação automática') {
            distributors = distributors.filter((distributor) => {
              return (
                (!distributor.acg_UploadNF__c && !distributor.acg_FluxoCooperativa__c) && distributor.acg_ElegivelNF__c
              )
            })
          }
        }
        if (filters.search) {
          const search = filters.search.toLowerCase() || '';
          const searchCNPJ = search.replace(/[./-]+/g, "");

          // Como firestore não faz OR entre mais de um campo, melhor filtrar aqui mesmo
          distributors = distributors.filter((distributor) => {
            return (
              distributor.acg_RazaoSocial__c?.toLowerCase()?.includes(search) ||
              distributor.Name?.toLowerCase()?.includes(search) ||
              distributor.BillingAddress?.city?.toLowerCase()?.includes(search) ||
              distributor.acg_TaxId__c?.toLowerCase()?.includes(searchCNPJ)
            )
          })
        }

        callback(distributors);
      }, (error) => {
        console.log('error', error)
      })
  }

  snapInvoices = (callback, filters = {}) => {
    /* * * * * PEGANDO A PARTIR DA COLLECTION */
    let invoicesQ = query(collection(this.db, 'Users', global.userFirebaseId, 'Invoices'));
    let hasSetDateOrder = false;
    let field;

    if (filters.period) {
      field = filters.period.includes('issue') ? 'acg_IssueDate__c' : 'CreatedDate';

      let startDate;
      let endDate;

      if (filters.period.includes('-last')) {
        startDate = new Date();
        let daysQty = parseInt(filters.period.split('-')[2])
        startDate.setDate(startDate.getDate() - daysQty);
      }
      else if (filters.period.includes('-period')) {
        if (filters.startDate) {
          startDate = new Date(filters.startDate.split('/').reverse().join('-'))
        }

        if (filters.endDate) {
          endDate = new Date(filters.endDate.split('/').reverse().join('-'))
        }
      }

      if (startDate instanceof Date && !isNaN(startDate)) {
        if (field === 'CreatedDate') {
          let startOfTheDay = startDate.toISOString().split('T')[0]

          if (field === 'CreatedDate') {
            startOfTheDay += 'T00:00:00';
          }

          invoicesQ = query(invoicesQ, where(field, '>=', startOfTheDay))
          invoicesQ = query(invoicesQ, orderBy(field, 'desc'))
          hasSetDateOrder = true;
        }
        else {
          let yymmA = filters.startDate.split('/');
          let yymm = yymmA[2][2] + yymmA[2][3] + yymmA[1];

          invoicesQ = query(invoicesQ, where('acg_IssueDateYYMM__c', '>=', yymm))
          invoicesQ = query(invoicesQ, orderBy('acg_IssueDateYYMM__c', 'desc'))
        }
      }

      if (endDate instanceof Date && !isNaN(endDate)) {
        if (field === 'CreatedDate') {
          let endOfTheDay = endDate.toISOString().split('T')[0]

          if (field === 'CreatedDate') {
            endOfTheDay += 'T23:59:59';
          }

          invoicesQ = query(invoicesQ, where(field, '<=', endOfTheDay))

          if (!startDate) {
            invoicesQ = query(invoicesQ, orderBy(field, 'desc'))
            hasSetDateOrder = true;
          }
        }
        else {
          let yymmA = filters.endDate.split('/');
          let yymm = yymmA[2][2] + yymmA[2][3] + yymmA[1];

          invoicesQ = query(invoicesQ, where('acg_IssueDateYYMM__c', '<=', yymm))

          if (!startDate) {
            invoicesQ = query(invoicesQ, orderBy('acg_IssueDateYYMM__c', 'desc'))
          }
        }
      }
    }

    if (filters.uf) {
      invoicesQ = query(invoicesQ, where('acg_UF__c', '==', filters.uf))
    }

    if (filters.status) {
      invoicesQ = query(invoicesQ, where('acg_Status__c', '==', filters.status))
    }

    if (!hasSetDateOrder || field !== 'CreatedDate')
      invoicesQ = query(invoicesQ, orderBy('CreatedDate', 'desc'))

    return onSnapshot(invoicesQ,
      async (snapshot) => {
        let invoices = this.dataHelper.getList(snapshot);

        await Promise.all(invoices.map(async (invoiceDoc, i) => {
          const salesRecordsDraftsQ = query(collection(invoiceDoc.ref, 'SalesRecordDraft'));
          const salesRecordsDraftsSnapshot = await getDocs(salesRecordsDraftsQ);
          const salesRecordsDrafts = salesRecordsDraftsSnapshot.docs.map(doc => doc.data())

          invoices[i] = { ...invoiceDoc, salesRecordsDrafts }

          return invoices[i]
        }));

        if (filters.search) {
          // Como firestore não faz OR entre mais de um campo, melhor filtrar aqui mesmo
          let searchDigits = ToolHelper.getDigits(filters.search);

          if (searchDigits.length) {
            invoices = invoices.filter((invoice) => {
              return invoice.Name?.includes(searchDigits) || invoice.acg_Distributor__r?.acg_TaxId__c?.includes(searchDigits)
            })
          }
          else {
            // Se não há digitos, não há o que ser buscado pois 
            // invoice.Name e CNPJ são só digitos.
            invoices = [];
          }
        }

        // Se filtrou por data de emissão, na query só filtrou mês e ano. 
        // É necessário verificar aqui se algum dos registros encontrados possuem IssueDate__c
        // para fazer uma filtragem precisa da data escolhida pelo usuário
        if (filters?.period?.includes('issue')) {
          let filterStartDate = filters?.startDate?.split('/').reverse().join('-') || '0000-00-00'
          let filterEndDate = filters?.endDate?.split('/').reverse().join('-') || '9999-12-31'

          invoices = invoices.filter(invoice => {
            let issueDate = invoice.acg_IssueDate__c;
            return !issueDate || (filterStartDate <= issueDate && issueDate <= filterEndDate)
          })
        }

        callback(invoices);
      }, (error) => {
        console.log('error', error)
      })
  }

  snapInvoiceHistory = async (callback, invoiceId) => {
    if (invoiceId?.length === 18) {
      invoiceId = invoiceId.slice(0, 15);
    }
    let invoiceHistoryQ = query(collection(this.db, 'Users', await this.getUserFirebaseId(), 'Invoices', invoiceId, 'InvoicesHistories'), orderBy('CreatedDate', 'desc'));

    let unsub = onSnapshot(invoiceHistoryQ,
      (snapshot) => {
        let invoiceHistories = this.dataHelper.getList(snapshot);
        callback(invoiceHistories);
      }, (error) => {
        console.log('error', error)
      })

    return unsub;
  }

  getInvoicePoints = async (invoice) => {
    let userRef = await this.getUserRef();
    let invoicePointsQ = query(collection(userRef, 'PointStatement'), where('acg_InvoiceId__c', '==', invoice.Id));
    let invoicePointsDocs = await getDocs(invoicePointsQ)

    let points = 0;

    if (invoicePointsDocs.size > 0) {
      invoicePointsDocs.forEach((doc) => {
        let pointData = doc.data();

        if (pointData.Points__c) {
          points += pointData.Points__c;
        }
      })
    }

    return points;
  }

  createInvoice = async (data) => {
    const userData = await this.getUser();
    if (!userData.Id) {
      return false;
    }

    let result = {
      status: 'Error',
    }

    data.growerId = userData.Id;

    try {
      return await this.dataHelper?.api.post(this.dataHelper.getHerokuUrl(HEROKU_INVOICE_URL), data)
    }
    catch (e) {
      console.log('createInvoice', e)
    }

    return result;
  }

  reanalysisInvoiceRequest = async (data) => {
    try {
      const response = await this.dataHelper?.api.post(this.dataHelper.getHerokuUrl(HEROKU_INVOICE_REANALYSIS_REQUEST_URL), data, false);

      return {
        success: !!response,
      }
    }
    catch (e) {
      console.log('reanalysisInvoice', e)
      return {
        success: false,
        error: e,
      }
    }
  }

  deleteInvoice = async (data) => {
    try {
      const response = await this.dataHelper?.api.post(this.dataHelper.getHerokuUrl(HEROKU_INVOICE_DELETE_URL), data)
      return {
        success: response?.success,
      }
    }
    catch (e) {
      console.log('deleteInvoice', e)
      return {
        success: false,
        error: e,
      }
    }
  }

  snapExpiredPoints = async (callback) => {
    if (!global.userFirebaseId) {
      callback(null)
      return false
    }
    let expiredPointsQ = query(collection(this.db, 'Users', global.userFirebaseId, 'PointStatement'));
    expiredPointsQ = query(expiredPointsQ, where('Expired__c', '==', false))

    return onSnapshot(expiredPointsQ, (snapshot) => {
      let expiredPoints = this.dataHelper.getList(snapshot);

      expiredPoints = expiredPoints.map(point => {
        if (!point?.ExpirationDate__c) return point;
        const dateRef = new Date(point?.ExpirationDate__c)
        dateRef.setUTCHours(dateRef.getUTCHours() - (dateRef.getTimezoneOffset() / 60));

        const localISOString = dateRef.toISOString();

        return {
          ...point,
          ExpirationDate__c: localISOString,
        }
      });




      callback(expiredPoints);
    }), (error) => {
      console.log('error', error)
    }

  }


  // Novo "snapUserPoints"
  snapPoints = (callback, listType = 'transactions', filters = {}) => {
    if (!global.userFirebaseId) {
      callback(null)
      return false
    }
    let pointsQ = query(collection(this.db, 'Users', global.userFirebaseId, 'PointStatement'));
    let hasSetDateOrder = false;
    let field;

    if (filters.period) {
      if (filters.period.includes('expiration')) {
        field = 'ExpirationDate__c';
      }
      else {
        field = 'PointDate__c';
      }

      let startDate;
      let endDate;

      if (filters.period.includes('-last')) {
        startDate = new Date();
        let daysQty = parseInt(filters.period.split('-')[2])
        startDate.setDate(startDate.getDate() - daysQty);
      }
      else if (filters.period.includes('-period')) {
        if (filters.startDate) {
          startDate = new Date(filters.startDate.split('/').reverse().join('-'))
        }

        if (filters.endDate) {
          endDate = new Date(filters.endDate.split('/').reverse().join('-'))
        }
      }

      if (startDate) {
        let startOfTheDay = startDate.toISOString().split('T')[0] + 'T00:00:00';
        pointsQ = query(pointsQ, where(field, '>=', startOfTheDay))
        pointsQ = query(pointsQ, orderBy(field, 'desc'))
        hasSetDateOrder = true;
      }

      if (endDate) {
        let endOfTheDay = endDate.toISOString().split('T')[0] + 'T23:59:59';
        pointsQ = query(pointsQ, where(field, '<=', endOfTheDay))

        if (!startDate) {
          pointsQ = query(pointsQ, orderBy(field, 'desc'))
          hasSetDateOrder = true;
        }
      }
    }

    if (filters.uf) {
      pointsQ = query(pointsQ, where('acg_GamifiedSalesRecord__r.acg_SalesRecord__r.acg_Invoice__r.acg_UF__c', '==', filters.uf))
    }

    if (filters.action) {
      pointsQ = query(pointsQ, where('Action__c', '==', filters.action))
    }

    if (!hasSetDateOrder || field !== 'PointDate__c')
      pointsQ = query(pointsQ, orderBy('PointDate__c', 'desc'))

    return onSnapshot(pointsQ,
      (snapshot) => {
        let points = this.dataHelper.getList(snapshot);


        // removendo linhas que possuem acg_StatusConsultant__c
        points = points.filter(point => !point?.acg_StatusConsultant__c?.length).sort((a, b) => {
          const menorValue = a.PointDate__c || a.PointDate__c;
          const maiorValue = b.PointDate__c || b.PointDate__c;
          if (menorValue > maiorValue) {
            return -1;
          } else if (menorValue < maiorValue) {
            return 1;
          } else {
            return 0;
          }
        });


        points = points.filter(point =>
          (!point?.Fielo_PointStatementId__c?.length) === (listType === 'transactions'))

        if (filters.search) {
          let searchLower = filters.search.toLowerCase();
          points = points.filter((point) => {
            return point.Description__c?.toLowerCase().includes(searchLower)
          })
        }



        points = points.map(point => {
          if (!point?.ExpirationDate__c) return point;
          const dateRef = new Date(point?.ExpirationDate__c)
          dateRef.setUTCHours(dateRef.getUTCHours() - (dateRef.getTimezoneOffset() / 60));
          const localISOString = dateRef.toISOString();
          return {
            ...point,
            ExpirationDate__c: localISOString,
          }
        });


        callback(points);
      }, (error) => {
        console.log('error', error)
      })
  }

  isConsultant = (userData) => {
    return userData?.acg_ProfileCodes__c?.includes('consultor');
  }

  snapFavorite = (callback) => {
    let favoriteQ = query(collection(this.db, 'Users', global.userFirebaseId, 'FavoriteAccount'));

    let unsub = onSnapshot(favoriteQ, (snapshot) => {
      let favorites = this.dataHelper.getList(snapshot);
      callback(favorites);
    })

    return unsub;
  }

  updateFavorite = async (taxId) => {
    const formatoISO8601 = moment().utc().format('YYYY-MM-DDTHH:mm:ss.SSSZ');

    const userRef = doc(this.db, 'Users', global.userFirebaseId);
    const subcollectionRef = collection(userRef, 'FavoriteAccount');

    const subCollectionSnapshot = await getDocs(subcollectionRef);

    subCollectionSnapshot.forEach(async (subDocSnapshot) => {
      if (subDocSnapshot.exists()) {
        const dataToUpdate = subDocSnapshot.data();

        if (dataToUpdate.acg_FavoriteAccount__r.acg_TaxId__c === taxId) {

          dataToUpdate.acg_InactivationDate__c = formatoISO8601;

          try {
            await updateDoc(subDocSnapshot.ref, dataToUpdate);
            return true;
          } catch (error) {
            return false;
          }
        }
      } else {
        console.log('Documento não encontrado na subcoleção.');
      }
    });
  };

  //pesquisa

  getUserSurveyAnswer = async function (callback) {
    let surveyQ = query(collection(this.db, 'Users', global.userFirebaseId, 'SurveyAnswersOption'));
    let unsub = onSnapshot(surveyQ, (snapshot) => {
      let surveys = this.dataHelper.getList(snapshot);
      callback(surveys);
    })
    return unsub;
  };

  getUserSurvey = async function (callback) {
    let surveyQ = query(collection(this.db, 'Survey'), where('Active__c', '==', true));
    let unsub = onSnapshot(surveyQ, (snapshot) => {
      let surveys = this.dataHelper.getList(snapshot);
      callback(surveys);
    })
    return unsub;
  };

  getCTGUser = async (callback) => {
    let campaignQ = query(collection(this.db, 'Users', global.userFirebaseId, 'CampaignMember'));

    let campaignQDocs = await getDocs(campaignQ)
    let foundUser = [];
    campaignQDocs.forEach((doc) => {
      foundUser.push(doc.data());
    })

    return foundUser;
  }

  snapPointsByTransaction = (callback, idTransaction) => {
    let PointsTransactionQ = query(collection(this.db, 'Users', global.userFirebaseId, 'PointStatement'), where('acg_TransferStatement__r.Id', '==', idTransaction), orderBy('ExpirationDate__c', 'asc'));

    let unsub = onSnapshot(PointsTransactionQ, (snapshot) => {
      let points = this.dataHelper.getList(snapshot);
      callback(points);
    })

    return unsub;
  }

  async getPointGroupRulesHtml() {
    try {
      let html;

      let query = doc(this.db, 'AppSetting', 'RegraGrupoDePontos');
      let queryDoc = await getDoc(query);

      if (queryDoc.exists()) {
        html = queryDoc.data().HtmlValue__c
      }

      return html
    } catch (error) {
      return false
    }
  }

  async getCampaignsMiddlewareMapping() {
    try {
      let obj;

      let query = doc(this.db, 'AppSetting', 'CampaignsMiddlewareMapping');
      let queryDoc = await getDoc(query);

      if (queryDoc.exists()) {
        obj = queryDoc.data().TextValue__c
      }

      return obj
    } catch (error) {
      return false
    }
  }

  npsSendAnswer = async (
    survey,
    question,
    numberAnswer,
    textAnswer
  ) => {

    let question_ = question[0];
    let cgny2__Action__c = POINT_ACTIONS.ACTION_VOTE_SURVEY_ANSWER + 'NUMBER';
    await GameficationHelper.addRow(
      this,
      cgny2__Action__c,
      question_.Id,        //cgny2__GenericId__c,
      undefined,           //cgny2__Event__c,
      textAnswer,          //cgny2__Value__c,
      undefined,           //cgny2__AdvertisingItem__c,
      undefined,           //cgny2__Gallery__c,
      undefined,           //cgny2__News__c,
      question_.Survey__c, //cgny2__Survey__c,
      undefined,           //cgny2__SurveyQuestionOption__c,
      question_.Id,        //cgny2__SurveyQuestion__c,
      undefined,           //cgny2__Voucher__c
      undefined,           //appUser
      undefined,           //recordType
      numberAnswer,        //cgny2__NumberValue__c
      undefined,           //cgny2__StartUpMessage__c
    );
  }

  npsSendClosure = async (survey) => {
    let surveyDoc = doc(this.db, 'Users', await this.getUserFirebaseId(), 'SurveyAnswers', survey.Id);

    setDoc(surveyDoc, {
      Id: survey.Id,
      actionDate: serverTimestamp()
    })

    const cgny2__Action__c = POINT_ACTIONS.ACTION_VOTE_SURVEY_COMPLETE;
    const cgny2__GenericId__c = survey.Id;

    GameficationHelper.addRow(
      this,
      cgny2__Action__c,
      cgny2__GenericId__c,
      undefined, //cgny2__Event__c,
      undefined, //cgny2__Value__c,
      undefined, //cgny2__AdvertisingItem__c,
      undefined, //cgny2__Gallery__c,
      undefined, //cgny2__News__c,
      survey.Id, //cgny2__Survey__c,
      undefined, //cgny2__SurveyQuestionOption__c,
      undefined, //cgny2__SurveyQuestion__c,
      undefined, //cgny2__Voucher__c
      undefined, //appUser
      undefined, //recordType
      undefined, //cgny2__NumberValue__c
      undefined, //cgny2__StartUpMessage__c
    );
  }

  startUpMessageHandle = async (startUpMessage, type) => {
    try {
      const response = await this.dataHelper.getRecordTypes('StartUpMessage');
      if (response.success) {
        let startUpMsgDoc = doc(this.db, 'Users', await this.getUserFirebaseId(), 'StartUpMessage', startUpMessage.Id);

        setDoc(startUpMsgDoc, {
          Id: startUpMessage.Id,
          actionDate: serverTimestamp()
        })

        const cgny2__Action__c = POINT_ACTIONS.ACTION_STARTUP_MESSAGE + type;
        const cgny2__GenericId__c = startUpMessage.Id;
        const cgny2__Value__c = type === 'CLICK' ? 'OPEN' : 'CLOSE';
        const recordType = response.recordTypes[0].Id;
        const cgny2__StartUpMessage__c = startUpMessage.Id;

        await GameficationHelper.addRow(
          this,
          cgny2__Action__c,
          cgny2__GenericId__c,
          undefined, //cgny2__Event__c,
          cgny2__Value__c,
          undefined, //cgny2__AdvertisingItem__c,
          undefined, //cgny2__Gallery__c,
          undefined, //cgny2__News__c,
          undefined, //cgny2__Survey__c,
          undefined, //cgny2__SurveyQuestionOption__c,
          undefined, //cgny2__SurveyQuestion__c,
          undefined, //cgny2__Voucher__c
          undefined, //appUser
          recordType,
          undefined, //cgny2__NumberValue__c
          cgny2__StartUpMessage__c
        );
      }
    } catch (error) {
      return {
        success: false,
        code: 'error-recordType',
      }
    }
  }

  getFinishedStartUpMessageIds = async () => {
    try {
      let finishedQ = collection(this.db, 'Users', await this.getUserFirebaseId(), 'StartUpMessage');
      let finishedDoc = await getDocs(finishedQ);
      let finishedIds = this.dataHelper.docsToArray(finishedDoc);

      if (finishedIds?.length <= 0) {
        finishedQ = collection(this.db, 'Users', await this.getUserFirebaseId(), 'StartUpMessages');
        finishedDoc = await getDocs(finishedQ);
        finishedIds = this.dataHelper.docsToArray(finishedDoc);
      }

      return finishedIds ? finishedIds.map((f) => f.Id) : []
    } catch (error) {

    }
  }

  getPushNotifications = async () => {
    if (!global.userFirebaseId) return

    const notificationsDocs = await getDocs(
      query(
        collection(this.db, 'Users', global.userFirebaseId, 'PushNotification'),
        orderBy('CreatedDate', 'desc'),
      )
    );
    const notifications = [];

    notificationsDocs?.forEach((doc) => {
      if (doc.exists() && !doc.data().Read__c && ["Group", "Transfer"].includes(doc.data()?.acg_Type__c)) {
        notifications.push({ ...doc.data(), ref: doc.ref });
      }
    });

    return notifications;
  }

  markNotificationsAsRead = async (refs = []) => {
    await Promise.all(refs.map((ref) => {
      return updateDoc(ref, {
        Read__c: true
      });
    }));
  }

  verifyContactsByAccount = async (accountId) => {
    let result = {
      status: 'Error',
    }

    try {
      const response = await this.dataHelper?.api.get(this.dataHelper.getHerokuUrl(`${HEROKU_GET_VERIFY_CONTACTS_BY_ACCOUNT}/${accountId}`))
      if (response.success) {
        result = response;
      }
    } catch (e) {
      console.log("verifyContactsByAccount error", e);
    }

    return result;
  }

  async getMGMPageTitle() {
    try {
      let obj;

      let query = doc(this.db, 'AppSetting', 'MemberGetMember');
      let queryDoc = await getDoc(query);

      if (queryDoc.exists()) {
        obj = queryDoc.data().TextValue__c
      }

      return obj
    } catch (error) {
      return false
    }
  }

  async getMemberCodeCampaigns() {
    let doc = query(collection(this.db, 'MemberCodeCampaigns'));
    let newsDoc = await getDocs(doc);

    let data = [];

    if (newsDoc.docs.length) {
      data = newsDoc.docs.map((doc) => {
        return doc.data();
      })
    }

    return { data };
  }

  async setMigrateDeuCash() {
    const ref = await this.getUserRef();

    const docResponse = setDoc(ref, {
      isMigrateDeuCash: true
    }, { merge: true });

    return docResponse;
  };



  async revokeTokenUser({ uid, userFirebaseId }) {
    const data = {
      uid,
      userFirebaseId
    }

    try {
      const response = await this.dataHelper?.api.post('/revokeRefreshToken', data)
      if (response.success) {
        return response;
      }
    }
    catch (e) {
      console.log('revokeTokenUser', e)
    }

    return { status: 'Error' }
  }

  async validateRefreshToken({ token }) {
    const data = { token }

    try {
      const response = await this.dataHelper?.api.post('/validateRefreshToken', data)
      if (response.success) {
        return response;
      }
    }
    catch (e) {
      console.log('validateRefreshToken', e)
    }

    return { status: 'Error' }
  }

  shortenRebrandly = async (url) => {
    const raw = JSON.stringify({
      "destination": url,
      "domain": {
        "fullName": "rebrand.ly"
      }
    });

    const requestOptions = {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'apikey': '21c1ede8a40041388c91dff554fa01ac',
      },
      body: raw,
      redirect: "follow"
    };

    try {
      return fetch("https://api.rebrandly.com/v1/links", requestOptions)
        .then((response) => response.text())
        .then((result) => result)
        .catch((error) => console.error(error));
    }
    catch (e) {
      return null;
    }
  }

  async getCampaignKnowMore() {
    try {
      let obj;

      let query = doc(this.db, 'AppSetting', 'Campaign_KnowMore');
      let queryDoc = await getDoc(query);

      if (queryDoc.exists()) {
        obj = queryDoc.data().TextValue__c
      }

      return obj
    } catch (error) {
      return false
    }
  }

  async revokeRefreshToken(userFirebaseId) {
    try {
      let result = await this.getHerokuUserfirebaseDoc({ userFirebaseId: userFirebaseId })
      if (result?.success) {
        await this.revokeTokenUser({ uid: result?.userfbid?.UID, userFirebaseId: result?.userfbid?.UserFirebaseId })
        return true;
      }
      return false;
    }
    catch (e) {
      return await this.deniedFirestoreRequest(e);
    }
  }

  async getCampaignKnowMore() {
    try {
      let obj;

      let query = doc(this.db, 'AppSetting', 'Campaign_KnowMore');
      let queryDoc = await getDoc(query);

      if (queryDoc.exists()) {
        obj = queryDoc.data().TextValue__c
      }

      return obj
    } catch (error) {
      return false
    }
  }

  snapUserFirebaseId = async () => {
    try {
      let obj;

      const query = doc(this.db, 'UserFirebaseId', await this.getUserFirebaseId());
      const queryDoc = await getDoc(query);

      if (queryDoc.exists()) {
        obj = queryDoc.data()
      }

      return obj
    } catch (error) {
      return false
    }
  }

  setAccesCodeRequireTrue = async () => {
    try {
      const ref = await this.getUserRef();
      const docResponse = await setDoc(ref, {
        AccessCodeRequireValidation: true
      }, { merge: true });
      return docResponse
    } catch (error) {
      return false
    }
  }

  setAccesCodeRequireFalse = async () => {
    try {
      const ref = await this.getUserRef();
      const docResponse = await setDoc(ref, {
        AccessCodeRequireValidation: false
      }, { merge: true });
      return docResponse
    } catch (error) {
      return false
    }
  }

};