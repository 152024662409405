import styled from "@emotion/styled"
import { useMemo } from "react";
import { BREAKPOINTS } from "../configs/consts";
import ToolHelper from "../utils/ToolHelper";
import SectionContainer from "./SectionContainer";
import StyledLink from "./StyledLink"

const footerLinks = [
  {
    text: 'Como Funciona',
    url: 'como-funciona',
  },
  {
    text: 'Política de Privacidade',
    url: 'politica-de-privacidade',
  },
  {
    text: 'Política de Cookies',
    url: 'politica-de-cookies',
  },
  {
    text: 'Regulamentos',
    url: 'regulamentos',
  },
  {
    text: 'Perguntas Frequentes',
    url: 'perguntas-frequentes',
  },
  {
    text: 'Fale Conosco',
    url: 'fale-conosco',
  },
]

const FooterLinks = (props) => {

  const config = useMemo(() => ToolHelper.getThemedConfig(props, 'FooterLinks.preset'), [props.config]);

  const { history } = props;

  return <SectionContainer config={config} verticalMargin={0}>
    <StyledFooterLinks config={config}>
      {footerLinks.map((link, index) => {
        const toUrl =
          history.location.pathname === '/conferencia-termos-de-uso'
            ? 'logout'
            : link.url
        return (
          <StyledLink key={index} config={config} to={toUrl} {...link.linkProps}>
            {link.text}
          </StyledLink>
        )
      })}
    </StyledFooterLinks>
  </SectionContainer>
}

export default FooterLinks;

/* style */

const StyledFooterLinks = styled.div`
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 20px;

  @media (max-width: ${BREAKPOINTS.PHONE}px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    text-align: center;
  }

`