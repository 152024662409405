import React from "react";

import {StyledResultItem} from '../styled';

const ResultItem = (item) => {
  return (
    <StyledResultItem>
      <span className="title">{item.award.name}</span>
      <div className="mainRow">
        <img src={item.award.photoUrl} alt={item.award.name} className="img"/>
        <div className="secondColumn">
          <div className="firstRow">
            <span className="infoTitle">Nome</span>
            <span className="infoName">{item.name}</span>
          </div>
          <div className="lastRow">
            <div>
              <span className="infoTitle">Número</span>
              <span className="infoOtherData">{item.luckyNumber}</span>
            </div>
            {
              item.user.acg_ProfileCodes__c?.includes("timedevendas") &&
                <div>
                  <span className="infoTitle">Canal</span>
                  <span className="infoOtherData">{item.channel}</span>
                </div>
            }
            {/* <div>
              <span className="infoTitle">Grupo</span>
              <span className="infoOtherData">{item.gp}</span>
            </div> */}
          </div>
        </div>
      </div>
    </StyledResultItem>
  )
}

export default ResultItem;
