import SwitchAccount from "./SwitchAccount";

const GlobalPopup = (props,popupName) => {
  let popups = {
    SwitchAccount,
  }

  console.log('globalpopup!',popupName)

  if(popups[popupName]){
    return popups[popupName](props);
  }

  return false;
}

export default GlobalPopup;