import React from "react";

const MyNumbers = (item) => {
  return (
    <div className="myNumberBox">
      <span className="myNumber">{item?.number}</span>
    </div>
  )
}

export default MyNumbers;
