import { useMemo } from "react";
import { X } from "react-feather";
import ToolHelper from "../../utils/ToolHelper";

import { StyledWebPopup } from './styled';

const CampaignsMyNumbersDetailsModal = (props) => {
  const { setOpenModalDetail, itemModalDetail } = props

  const config = useMemo(() => ToolHelper.getThemedConfig(props,'ProductPopup.preset'),[props.config]);

  const handleClose = () => {
    setOpenModalDetail(false)
  }

  const replaceTag = (text, tag) => {
    return !text ? "" : text.replace(/###(.*?)###/g, (_, text) => `<${tag}>${text}</${tag}>`);
  }

  return <StyledWebPopup config={config}>
    <div className={'white-box'}>
      <div className={'internal-container'}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 12,
            justifyContent: 'flex-start',
            lineHeight: '16px'
          }}
        >
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
            <span style={{fontSize: '18px', fontWeight: 600, lineHeight: '18px', color: '#222222'}}>{itemModalDetail.name}</span>
            <div className="close-btn-container">
              <div className="close-btn" onClick={handleClose}>
                <X strokeWidth={3} height={20} width={20} />
              </div>
            </div>
          </div>
          <div className="text-content" dangerouslySetInnerHTML={{ __html: `<p>${replaceTag(itemModalDetail.description, 'span')}</p>` }}>
            {/* <p style={{fontWeight: 500, fontSize: '14px', lineHeight: '24px', color: '#222222'}} >{replaceTag(itemModalDetail.description, 'span')}</p> */}
          </div>
        </div>
      </div>
    </div>
  </StyledWebPopup>
}

export default CampaignsMyNumbersDetailsModal;
